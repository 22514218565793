import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Store } from '@ngrx/store';
import { setInitPayload } from '../store/actions';

@Injectable({
  providedIn: 'root',
})
export class ActivateTransactions implements CanActivate {
  constructor(private store: Store<any>) {}

  canActivate() {
    this.store.dispatch(
      setInitPayload({
        data: {
          initPayload: { last_thirty: true, curPage: 1, term: '', params: null },
          last_thirty: true,
          onlyMe: false,
        },
      })
    );
    return true;
  }
}
