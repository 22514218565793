import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, combineLatest } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LOAD_GRAPH_DATA } from 'src/app/shared/config';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';

const api = environment.mongoUrl;
@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(private db: AngularFirestore, private http: HttpClient, private auth: AuthService) {}

  loadUserCommunication(uid: string): Observable<any> {
    return this.db.collection<any>('users').doc(uid).valueChanges();
  }

  getOpenTasks(uid: string) {
    const date = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).toISOString();
    const tasks1 = this.db
      .collection<any>('tasks', (ref) =>
        ref
          .where('assignedto.uid', '==', uid)
          .where('dueTime', '>=', date)
          .where('taskStatus', '==', 'postponed')
          .limit(10)
      )
      .valueChanges();
    const tasks2 = this.db
      .collection<any>('tasks', (ref) =>
        ref.where('assignedto.uid', '==', uid).where('dueTime', '>=', date).where('taskStatus', '==', 'open').limit(10)
      )
      .valueChanges();

    const tasks3 = this.db
      .collection<any>('tasks', (ref) =>
        ref
          .where('assignedto.uid', '==', uid)
          .where('dueTime', '>=', date)
          .where('taskStatus', '==', 'onhold')
          .limit(10)
      )
      .valueChanges();

    const tasks = combineLatest([tasks1, tasks2, tasks3]);
    return tasks;
  }

  getDueTasks(uid: string) {
    const date = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).toISOString();

    const tasks1 = this.db
      .collection<any>('tasks', (ref) =>
        ref
          .where('assignedto.uid', '==', uid)
          .where('dueTime', '<', date)
          .where('taskStatus', '==', 'postponed')
          .limit(10)
      )
      .valueChanges();
    const tasks2 = this.db
      .collection<any>('tasks', (ref) =>
        ref.where('assignedto.uid', '==', uid).where('dueTime', '<', date).where('taskStatus', '==', 'open').limit(10)
      )
      .valueChanges();

    const tasks3 = this.db
      .collection<any>('tasks', (ref) =>
        ref.where('assignedto.uid', '==', uid).where('dueTime', '<', date).where('taskStatus', '==', 'onhold').limit(10)
      )
      .valueChanges();

    const tasks = combineLatest([tasks1, tasks2, tasks3]);
    return tasks;
  }

  getAllTasks(uid: string) {
    const tasks = this.db
      .collection<any>('tasks', (ref) =>
        ref.where('assignedto.uid', '==', uid).where('taskStatus', '==', 'open').orderBy('dueTime').limit(10)
      )
      .valueChanges();
    const onHoldData = this.db
      .collection<any>('tasks', (ref) =>
        ref.where('assignedto.uid', '==', uid).where('taskStatus', '==', 'on hold').orderBy('dueTime').limit(10)
      )
      .valueChanges();
    const postponed = this.db
      .collection<any>('tasks', (ref) =>
        ref.where('assignedto.uid', '==', uid).where('taskStatus', '==', 'postponed').orderBy('dueTime').limit(10)
      )
      .valueChanges();
    const spread = combineLatest([tasks, onHoldData, postponed]);
    return spread;
  }

  /**
   * function to get recent 20 tasks of the user
   * @param uid userID
   */

  getAllContactActivity(uid: string) {
    try {
      const tasks1 = this.db
        .collection<any>('tasks', (ref) =>
          ref
            .where('assignedto.uid', '==', uid)
            .where('taskStatus', 'in', ['completed', 'Voicemail', 'Contacted', 'No Answer', 'Bad Number'])
            .orderBy('updated_at', 'desc')
            .limit(30)
        )
        .valueChanges();
      const tasks2 = this.db
        .collection<any>('tasks', (ref) =>
          ref
            .where('assignedby.uid', '==', uid)
            .where('taskStatus', 'in', ['completed', 'Voicemail', 'Contacted', 'No Answer', 'Bad Number'])
            .orderBy('updated_at', 'desc')
            .limit(30)
        )
        .valueChanges();
      const tasks = combineLatest([tasks1, tasks2]);
      return tasks;
    } catch (error) {
      console.error(error);
    }
  }
  /**
   * function to get recent 20 call tasks of the user
   * @param uid userID
   */

  getCallContactActivity(uid: string) {
    try {
      const tasks1 = this.db
        .collection<any>('tasks', (ref) =>
          ref
            .where('assignedto.uid', '==', uid)
            .where('task', '==', 'call')
            .where('taskStatus', 'in', ['completed', 'Voicemail', 'Contacted', 'No Answer', 'Bad Number'])
            .orderBy('updated_at', 'desc')
            .limit(30)
        )
        .valueChanges();
      const tasks2 = this.db
        .collection<any>('tasks', (ref) =>
          ref
            .where('assignedby.uid', '==', uid)
            .where('task', '==', 'call')
            .where('taskStatus', 'in', ['completed', 'Voicemail', 'Contacted', 'No Answer', 'Bad Number'])
            .orderBy('updated_at', 'desc')
            .limit(30)
        )
        .valueChanges();
      const tasks3 = this.db
        .collection<any>('tasks', (ref) =>
          ref
            .where('assignedto.uid', '==', uid)
            .where('task', '==', 'phone_callback')
            .where('taskStatus', '==', 'completed')
            .orderBy('updated_at', 'desc')
            .limit(30)
        )
        .valueChanges();
      const tasks4 = this.db
        .collection<any>('tasks', (ref) =>
          ref
            .where('assignedby.uid', '==', uid)
            .where('task', '==', 'phone_callback')
            .where('taskStatus', '==', 'completed')
            .orderBy('updated_at', 'desc')
            .limit(30)
        )
        .valueChanges();
      const tasks = combineLatest([tasks1, tasks2, tasks3, tasks4]);
      return tasks;
    } catch (error) {
      console.error(error);
    }
  }
  /**
   * function to get recent 20 msg tasks of the user
   * @param uid userID
   */

  getMsgContactActivity(uid: string) {
    try {
      const tasks1 = this.db
        .collection<any>('tasks', (ref) =>
          ref
            .where('assignedto.uid', '==', uid)
            .where('task', 'in', [
              'message',
              'chat',
              'textsms',
              'contact_mail',
              'mms_milestone',
              'sms_milestone',
              'mms_ai',
              'sms_ai',
            ])
            .where('taskStatus', '==', 'completed')
            .orderBy('updated_at', 'desc')
            .limit(30)
        )
        .valueChanges();
      const tasks2 = this.db
        .collection<any>('tasks', (ref) =>
          ref
            .where('assignedby.uid', '==', uid)
            .where('task', 'in', [
              'message',
              'chat',
              'textsms',
              'contact_mail',
              'mms_milestone',
              'sms_milestone',
              'mms_ai',
              'sms_ai',
            ])
            .where('taskStatus', '==', 'completed')
            .orderBy('updated_at', 'desc')
            .limit(30)
        )
        .valueChanges();
      const tasks = combineLatest([tasks1, tasks2]);
      return tasks;
    } catch (error) {
      console.error(error);
    }
  }
  /**
   * function to get recent 20 mail tasks of the user
   * @param uid userID
   */

  getMailContactActivity(uid: string) {
    try {
      const tasks1 = this.db
        .collection<any>('tasks', (ref) =>
          ref
            .where('assignedto.uid', '==', uid)
            .where('task', 'in', ['mail', 'outbound_mail', 'inbound_mail', 'mail_milestone'])
            .where('taskStatus', '==', 'completed')
            .orderBy('updated_at', 'desc')
            .limit(30)
        )
        .valueChanges();
      const tasks2 = this.db
        .collection<any>('tasks', (ref) =>
          ref
            .where('assignedby.uid', '==', uid)
            .where('task', 'in', ['mail', 'outbound_mail', 'inbound_mail', 'mail_milestone'])
            .where('taskStatus', '==', 'completed')
            .orderBy('updated_at', 'desc')
            .limit(30)
        )
        .valueChanges();
      const tasks = combineLatest([tasks1, tasks2]);
      return tasks;
    } catch (error) {
      console.error(error);
    }
  }

  async loadGraphData(): Promise<Observable<any>> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    return this.http.post(LOAD_GRAPH_DATA, {}, httpOptions);
  }

  async getContactWithTodaysBirthday(uid: string, currentUser: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };

    const url = `${environment.cloud_base_url}/getContactsBirthday`;
    return this.http.post(url, { userID: uid, user: currentUser }, httpOptions).toPromise();
  }

  async getContactWithTodaysAnniversary(uid: string, currentUser: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };

    const url = `${environment.cloud_base_url}/getContactsAnniversary`;
    return this.http.post(url, { userID: uid, user: currentUser }, httpOptions).toPromise();
  }
}
