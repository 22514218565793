import {
  Component,
  OnInit,
  ViewChild,
  AfterViewChecked,
  EventEmitter,
  Output,
  OnDestroy,
  Input
} from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { selectCurrentUser } from 'src/app/modules/login/store/selectors';
import { take, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ConfirmationComponent } from 'src/app/shared/components/confirmation/confirmation.component';
import { DataTransactionService } from '../../services/data-transaction.service';
import { AudioRecordModelComponent } from '../audio-record-model/audio-record-model.component';

@Component({
  selector: 'dailyai-audio-list',
  templateUrl: './audio-list.component.html',
  styleUrls: ['./audio-list.component.scss']
})
export class AudioListComponent implements OnInit, AfterViewChecked, OnDestroy {
  @ViewChild('audio') audio;

  @Output() private selectedMedia = new EventEmitter();

  @Output() private selectAudioFiles = new EventEmitter();

  ngUnsubscribe: Subject<any> = new Subject<any>();

  icons = [];

  duration: any = '00:00';

  displayNone = [];

  percentage = [];

  currentUser$: Observable<any>;

  userDetails;

  audioList$: Observable<any>;

  paused = false;

  audioList = [];

  selectedList = [];

  searchText = '';

  uploading = false;

  modelRef: NgbModalRef;

  url = '';

  constructor(
    private ngbModal: NgbModal,
    private audioSvc: DataTransactionService,
    private store: Store<any>,
    private router: Router
  ) { }

  ngOnInit() {
    this.currentUser$ = this.store.pipe(select(selectCurrentUser));
    this.currentUser$.pipe(take(2)).subscribe(data => {
      this.userDetails = data;
      this.audioList$ = this.audioSvc.getAudioList(this.userDetails.uid);
    });

    this.audioList$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(val => {
      this.audioList = val;
      this.audioList.sort((a, b) => a.file_name.localeCompare(b.file_name));

      this.uploading = false;
      for (const [index, elem] of this.audioList.entries()) {
        this.icons[index] = 'play_arrow';
      }
    });
    this.url = this.router.url.split('/')[1];
  }

  ngAfterViewChecked() {
  }

  audioModel() {
    this.modelRef = this.ngbModal.open(AudioRecordModelComponent, {
      centered: true,
      windowClass: 'model-campaign-filter',
      backdrop: 'static',
      keyboard: false
    });
    this.modelRef.componentInstance.saved.subscribe(val => {
      this.uploading = true;
    });
  }

  updateTime(index) {
    const audio: HTMLAudioElement | any = document.getElementById(`audio${index}`);
    let {duration} = audio;
    const seconds = this.hmsToSecondsOnly(this.audioList[index]['duration']);
    if (audio.duration === Number.POSITIVE_INFINITY) {
      duration = seconds;
    }
    const per = Number(((Number(audio.currentTime) / Number(duration)) * 100).toFixed(2));
    this.percentage[index] ? (this.percentage[index] = per) : (this.percentage[index] = []);
    this.percentage[index] = per;
  }

  hmsToSecondsOnly(str) {
    const p = str.split(':');
    let s = 0,
      m = 1;

    while (p.length > 0) {
      s += m * Number.parseInt(p.pop(), 10);
      m *= 60;
    }

    return s;
  }

  audEnded(index) {
    this.icons[index] = 'play_arrow';
    this.percentage[index] = 0;
  }

  startAudio(index) {
    this.paused = !this.paused;
    let audio: HTMLAudioElement | any;
    for (let i = 0; i < this.audioList.length; i++) {
      if (i !== index) {
        audio = document.getElementById(`audio${i}`);
        this.duration = audio.duration;
        audio.controls = false;
        audio.play ? audio.pause() : audio.pause();
      }
    }
    audio = document.getElementById(`audio${index}`);
    this.duration = audio.duration;
    audio.controls = false;
    audio.paused ? audio.play() : audio.pause();
    if (audio.paused) {
      audio.pause();
      this.icons[index] = 'play_arrow';
    } else {
      audio.play();
      const arr = new Array(this.icons.length).fill('play_arrow');
      this.icons = arr;
      this.icons[index] = 'pause';
    }
  }

  selectAudio(index, item) {
    if (this.url === 'drip-campaign' || this.url === 'contacts') {
      const arr = new Array(this.displayNone.length).fill(0);
      this.displayNone = arr;
      this.displayNone[index] = this.displayNone[index] === 1 ? 0 : 1;
      this.selectedMedia.emit(item);
      return;
    }
    this.displayNone[index] = this.displayNone[index] === 1 ? 0 : 1;
    const findIndex = this.selectedList.findIndex(x => x.doc_id === item.doc_id);
    if (findIndex === -1) {
      this.selectedList.push(item);
    } else {
      this.selectedList.splice(findIndex, 1);
    }
    this.selectAudioFiles.emit({ list: this.selectedList, type: 'audio' });
  }

  confirmationTemp() {
    const confirmRef = this.ngbModal.open(ConfirmationComponent, {
      centered: true,
      size: 'sm',
      backdrop: 'static',
      keyboard: false
    });
    confirmRef.result.then(result => (result === 'submit' ? this.deleteAudio() : this.ngbModal.dismissAll()));
  }

  deleteAudio() {
    this.audioSvc.deleteFile(this.selectedList);
    this.selectedList = [];
    this.displayNone = [];
    this.selectAudioFiles.emit({ list: this.selectedList, type: 'audio' });
  }

  @Input()
  set search(pSearch) {
    this.searchText = pSearch;
  }


  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
