import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { finalize, delay, switchMap } from 'rxjs/operators';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
import * as firebase from 'firebase/app';
import { of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { COLLECTIONS } from 'src/app/shared/config';
import { Transaction } from '../store/state';

const api = environment.mongoUrl;

@Injectable({
  providedIn: 'root',
})
export class TransactionService {
  task: AngularFireUploadTask;

  constructor(
    private db: AngularFirestore,
    private storage: AngularFireStorage,
    private http: HttpClient,
    private auth: AuthService,
    private toastr: ToastrService
  ) {}

  getTransactions(id: string) {
    return this.db.collection<any>(COLLECTIONS.TRANSACTIONS, (ref) =>
        ref.where('viewersList', 'array-contains', id).orderBy('created_new_date', 'desc').limit(10)
      )
      .valueChanges();
  }

  getTransactionByID(id: string) {
    return this.db.collection<any>(COLLECTIONS.TRANSACTIONS).doc(id).valueChanges();
  }

  async addTransaction(data: Transaction, uid: string) {
    let loan_amount = null;
    if (data.transaction.loan_amount) {
      loan_amount = +data.transaction.loan_amount;
    }
    const currDate = new Date();
    const created_new_date = currDate.toISOString();
    let created_date = currDate.toLocaleString('en-US').replace(/:\d{2}\s/, ' ');
    created_date = created_date.replace(/,/g, '');
    const currentUser = this.auth.getUserData();
    let viewersList = [uid], parentId, SLO;

    if (!currentUser?.isEnterprise) {
      parentId = currentUser.parentId ? currentUser.parentId : currentUser.uid;
      SLO = (
        await this.db.collection<any>(COLLECTIONS.USERS).doc(parentId).ref.get()
      ).data();

      if (uid !== parentId) {
        viewersList.push(parentId);
        if (SLO.transaction_shared_to && SLO.transaction_shared_to.length > 0) {
          viewersList = [...viewersList, ...SLO.transaction_shared_to];
        }
      } else if (SLO.transaction_shared_to && SLO.transaction_shared_to.length > 0) {
        viewersList = [...viewersList, ...SLO.transaction_shared_to];
      }

      if (currentUser.userType !== 'MO') {
        const mo: any = await this.auth.getMOData(parentId);
        if (mo && mo.uid) {
          viewersList.push(mo.uid);
        }
      }
    }

    if (data.transaction.buying_agent && data.transaction.associated_contact) {
      const contactDetails = (
        await this.db.collection<any>(COLLECTIONS.CONTACTS).doc(data.transaction.associated_contact.contact_id).ref.get()
      ).data();

      if (!contactDetails.partnerId) {
        const newPartner = data.transaction.buying_agent;
        await this.db.collection<any>(COLLECTIONS.CONTACTS)
          .doc(data.transaction.associated_contact.contact_id)
          .update({
            partnerId: newPartner.uid,
            partnerName: `${newPartner.firstName  } ${  newPartner.lastName}`,
          });

        const newContactData = {
          contact_id: data.transaction.associated_contact.contact_id,
          contact_name: data.transaction.associated_contact.contact_name,
        };
        await this.db.collection<any>(COLLECTIONS.PARTNERS)
          .doc(newPartner.uid)
          .update({
            referralContacts: firebase.default.firestore.FieldValue.arrayUnion(newContactData),
          });

      }
    }

    const adding = this.db.collection<any>(COLLECTIONS.TRANSACTIONS)
      .doc(data.transaction.id)
      .set({
        borrower_name: data.borrower_name,
        uid,
        ...data.transaction,
        loan_amount,
        created_new_date,
        created_date,
        id: data.transaction.id,
        last_updated: new Date().toISOString(),
        viewersList,
        status_trigger: true,
        coming_from: 'manual',
      });
    return adding;
  }

  async addSharedUsersToviewersList(uid, data) {
    const shared_users_list = await this.db.collection<any>(COLLECTIONS.USERS).doc(uid).ref.get();
    if (shared_users_list.data().transaction_shared_to) {
      let transactioSshareToList = [];
      transactioSshareToList = [...shared_users_list.data().transaction_shared_to];

      await this.db.collection<any>(COLLECTIONS.TRANSACTIONS)
        .doc(data.transaction.id)
        .update({ viewersList: firebase.default.firestore.FieldValue.arrayUnion(...transactioSshareToList) });
    }
  }

  async set_contact_status(status: string, contact_id: string) {
    await this.db.collection<any>(COLLECTIONS.CONTACTS).doc(contact_id).update({ status });
  }

  async editTransaction(data: Transaction, uid: string) {
    let loan_amount: any = data?.transaction?.loan_amount ?? null;
    if (data?.transaction?.loan_amount && !Number.isFinite(data?.transaction?.loan_amount)) {
      loan_amount = +data?.transaction?.loan_amount;
    }

    if (!data.transaction.id) throw { error: 'id not available' };

    const editing = this.db.collection<any>(COLLECTIONS.TRANSACTIONS)
      .doc(data.transaction.id)
      .update({
        borrower_name: data.borrower_name,
        status_trigger: true,
        ...data.transaction,
        loan_amount,
        last_updated: new Date().toISOString(),
      });

    if (data.transaction.buying_agent && data.transaction.associated_contact) {
      const newPartner = data.transaction.buying_agent;
      const newContactData = {
        contact_id: data.transaction.associated_contact.contact_id,
        contact_name: data.transaction.associated_contact.contact_name,
      };

      const contactDetails = (
        await this.db.collection<any>(COLLECTIONS.CONTACTS)
          .doc(data.transaction.associated_contact.contact_id).ref.get()
      ).data();

      if (!contactDetails.partnerId) {
        await this.db.collection<any>(COLLECTIONS.CONTACTS)
          .doc(data.transaction.associated_contact.contact_id)
          .update({
            partnerId: newPartner.uid,
            partnerName: `${newPartner.firstName  } ${  newPartner.lastName}`,
          });

        await this.db.collection<any>(COLLECTIONS.PARTNERS)
          .doc(newPartner.uid)
          .update({
            referralContacts: firebase.default.firestore.FieldValue.arrayUnion(newContactData),
          });

      }
    }

    if (!data.transaction.associated_contact) {
      return editing;
    }
      editing.then().catch();

      const editContact = await this.db.collection<any>(COLLECTIONS.CONTACTS)
        .doc(data.transaction.associated_contact.contact_id)
        .update({ associated_loan_ids: firebase.default.firestore.FieldValue.arrayUnion(data.transaction.id) });

      return editContact;

  }

  async uploadCsv(id, file, groupId, optout, selectedDripIds, parentId) {

    const date = new Date();
    const time = date.getTime();
    let csvUrl;
    const path = `csv/transactions_${id}_${time}`;
    await this.db.collection<any>(COLLECTIONS.CSV_EXTRA_DATA)
      .doc(`transactions_${id}_${time}`)
      .set({
        groups: groupId ?? [],
        campaigns: selectedDripIds ?? [],
        optedOut: optout,
        doc_id: `transactions_${id}_${time}`,
      });
    const ref = this.storage.ref(path);
    this.task = this.storage.upload(path, file);
    const uploadObs = this.task.snapshotChanges();
    uploadObs
      .pipe(
        finalize(() => {
          ref.getDownloadURL().subscribe((url) => {
            csvUrl = url;
          });
        })
      )
      .subscribe();
    return uploadObs;
  }

  async downloadCSVTransaction(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };

    return this.http.post(`${api}/v0.1/transactions/downloadCSV`, data, { ...httpOptions, responseType: 'blob' }).pipe(
      switchMap((resp) => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(resp);
        link.download = `transactions_${new Date().toLocaleString()}.csv`;
        link.click();
        return of();
      })
    );
  }

  async existingTransaction(id: string, user_id: string) {
    const check = await this.db.collection<any>(COLLECTIONS.TRANSACTIONS)
      .ref.where('loan_number', '==', id)
      .where('viewersList', 'array-contains', user_id)
      .get();
    if (check.size > 0) {
      return false;
    }
      return true;

  }

  async fetchCount(data, onlyMe: boolean) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    return this.http.post(`${api}/v0.1/transactions/transaction-count`, { id: data, onlyMe }, httpOptions).toPromise();
  }

  async fetchTransactions(data, uid, onlyMe: boolean) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    return this.http
      .post(`${api}/v0.1/transactions/transaction-list`, { value: data, id: uid, onlyMe }, httpOptions)
      .toPromise();
  }

  async fetchTransactionsCard(data, uid, milestones: any[], onlyMe: boolean) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    return this.http
      .post(
        `${api}/v0.1/transactions/transaction-card-fetch`,
        { value: { ...data, milestones }, id: uid, onlyMe },
        httpOptions
      )
      .toPromise();
  }

  async transactionsCardScroll(data, uid, onlyMe: boolean) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    return this.http
      .post(`${api}/v0.1/transactions/transaction-card-demand`, { value: data, id: uid, onlyMe }, httpOptions)
      .toPromise();
  }

  async searchTransactions(data, uid, onlyMe) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    return this.http
      .post(`${api}/v0.1/transactions/search-transaction`, { value: data, id: uid, onlyMe }, httpOptions)
      .toPromise();
  }

  async filterTransactions(data, uid, onlyMe: boolean) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    return this.http
      .post(`${api}/v0.1/transactions/filter-transaction`, { value: data, id: uid, onlyMe }, httpOptions)
      .toPromise();
  }

  async sortTransaction(data, uid, onlyMe: boolean) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    return this.http
      .post(`${api}/v0.1/transactions/sort-transaction`, { value: data, id: uid, onlyMe }, httpOptions)
      .toPromise();
  }

  AddBussinessContact(contactsData, transaction_id) {
    return this.db.collection<any>(COLLECTIONS.TRANSACTIONS)
      .doc(transaction_id)
      .update({ business_contacts: firebase.default.firestore.FieldValue.arrayUnion(contactsData) });
  }

  async userCustomTable(customTable: any, id: string) {
    return this.db.collection<any>(COLLECTIONS.USERS).doc(id).update({ custom_table: customTable });
  }

  async shareTransaction(payload, currentUser) {
    const data = payload;
    const sharedto = data.filter((val) => val !== currentUser);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };

    await this.db.collection<any>(COLLECTIONS.USERS)
      .doc(currentUser)
      .update({ transaction_shared_to: firebase.default.firestore.FieldValue.arrayUnion(...sharedto) });

    return this.http
      .post(
        `${environment.cloud_base_url}/shareTransactiontoJuniorOfficers`,
        {
          transactionData: {
            SloUid: currentUser,
            jloIds: sharedto,
          },
        },
        httpOptions
      )
      .toPromise();
  }

  async userNames(id) {
    const ref = await this.db.collection<any>(COLLECTIONS.USERS).doc(id).ref.get();
    return ref.data();
  }

  updateTransactionStatus(id: string, status: string) {
    return this.db.collection<any>(COLLECTIONS.TRANSACTIONS)
      .doc(id)
      .update({ milestone: status, last_updated: new Date().toISOString() });
  }

  async toggletransactionLayout(uid: string, layout: string) {
    await this.db.collection<any>(COLLECTIONS.USERS).doc(uid).update({ transactionLayout: layout });
  }

  async deleteTransaction(data: any, uId: any) {
    const transactionId = [];
    if (data) {
      data.map((item) => {
        transactionId.push(item.id);
      });
    }
    const body = {
      transaction_Ids: transactionId,
      parentId: uId,
    };
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    this.toastr.info('Transaction Delete will be done shortly', 'Batch Operations', {
      closeButton: true,
      disableTimeOut: true
    });
    return this.http.post(`${environment.cloud_base_url}/transactionBatchDelete`, body, httpOptions).toPromise();
  }

  async getDistinctTransactionStatus() {
    const user = this.auth.getUserData();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    const body = { user_id: user.uid };
    return this.http.post(`${api}/v0.1/transactions/getDistinctTransactionMilestone`, body, httpOptions).toPromise();
  }

  async getTransactionModified(data, uid, onlyMe: boolean) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    return this.http
      .post(`${api}/v0.1/transactions/get-transaction-list`, { value: data, id: uid, onlyMe }, httpOptions)
      .toPromise();
  }

  async getTransactionModifiedDelay(data, uid, onlyMe: boolean) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    await delay(5000);
    return this.http
      .post(`${api}/v0.1/transactions/get-transaction-list`, { value: data, id: uid, onlyMe }, httpOptions)
      .toPromise();
  }

  async sortTransactionSave(currentUserId: string, obj: any) {
    await this.db.collection<any>(COLLECTIONS.USERS).doc(currentUserId).set(obj, { merge: true });
  }

  async getTransactionUI(uid: string) {
    const ref = await this.db.collection<any>(COLLECTIONS.TRANSACTION_UI).doc(uid).ref.get();
    return ref.data();
  }

  setTransactionUI(uid: string, uiObject: any) {
    return this.db.collection<any>(COLLECTIONS.TRANSACTION_UI).doc(uid).set(uiObject, { merge: true });
  }
}
