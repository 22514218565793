import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from './auth.service';
import { SharedService } from '../shared/services/shared.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  constructor(
    private auth: AuthService,
    private router: Router,
    private toastr: ToastrService,
    private shared: SharedService,
  ) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const user = await this.auth.isLoggedIn();

    if (user) {
      this.auth.setUser();
      const userData = await this.auth.getDB();
      this.shared.setMilestonesSetting(userData.milestone_communication_blocked);
      if (userData.userType === 'superAdmin') {
        this.auth.signout();
        return false;
      }
      if (userData.status && userData.status === 'Deactive') {
        this.auth.signout();
        this.toastr.warning('Your Account Has Been Deactivated By Your SLO', 'Authentication Error');
        this.router.navigate(['/login']);
        return false;
      }
      if (userData.test) {
        return true;
      }
      if (userData.onboarding?.status === 'incomplete') {
        this.router.navigate(['/onboarding']);
        return false;
      }

      if (state.url.split('/')[1] === 'dashboard') {
        if (new Set(['Super Admin']).has(userData.userType)) {
          this.router.navigate(['/enterprise']);
          return false;
        }
        if (!userData?.legacyDashboard) {
          this.router.navigate(['/dashboard-beta']);
          return false;
        }

        return true;
      }

      if (state.url.includes('/contacts/beta')) {
        if (userData?.legacyContacts) {
          this.router.navigate(['/contacts']);
          return false;
        }

        return true;
      }

      if (state.url.split('/')[1] === 'contacts' && state.url.split('/').length <= 2) {
        if (!userData?.legacyContacts) {
          this.router.navigate(['/contacts/beta']);
          return false;
        }

        return true;
      }

      if (state.url.includes('settings/enterprise-settings')) {
        if (userData.userType === 'Super Admin' ||
          userData.userType === 'admin' ||
          userData.userType === 'SLO' ||
          userData.userType === 'manager' ||
          userData.userType === 'TeamLeader') {
          return true;
        }
          this.toastr.warning('You have no permission', 'Error');
          this.router.navigate(['/settings/general']);

      }


      if (state.url.split('/')[1] === 'website' || state.url.split('/')[1] === 'transactions') {
        if (userData.userType === 'MO') {
          this.router.navigate(['/']);
          return false;
        }
          return true;

      }
      if (state.url.split('/')[1] === 'enterprise') {
        if (new Set(['Super Admin', 'admin', 'manager', 'TeamLeader']).has(userData.userType)) {
          return true;
        }
          this.router.navigate(['/dashboard']);
          return false;

      }
      if (state.url.split('/')[1] === 'drip-campaign') {
        if (new Set(['admin', 'manager', 'TeamLeader', 'SLO', 'JLO', 'MO']).has(userData.userType)) {
          return true;
        }
          this.router.navigate(['/dashboard']);
          return false;

      }

      return true;
    }
      this.router.navigate(['/login']);
      localStorage.setItem('gotoAfterLogin', state.url);
      return false;

  }
}
