import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, from, merge } from 'rxjs';
import { map, catchError, switchMap, withLatestFrom, mergeMap, takeUntil, debounceTime } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import * as LoginStateActions from './modules/login/store/actions';
import * as AddPartnerActions from './modules/partners/store/actions';
import * as SignupActions from './modules/signup/store/action';
import { AuthService } from './services/auth.service';

import { AddPartnerService } from './modules/partners/services/add-partner.service';
import { PartnerService } from './modules/partners/services/partner.service';
import { State } from './reducers';
import { AppService } from './shared/app.service';
import { SubscriptionService } from './services/subscriptions.service';
import { sleep } from './shared/utils';

@Injectable()
export class AppEffects {
  Id;

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private addpartnerService: AddPartnerService,
    private partnerService: PartnerService,
    private toast: ToastrService,
    private modal: NgbModal,
    private store$: Store<State>,
    private appService: AppService,
    private subService: SubscriptionService,
    private router: Router
  ) {}

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoginStateActions.LogingIn),
      switchMap((payload) =>
        from(this.authService.loginUser(payload)).pipe(
          map((res) => {

            if (localStorage.getItem('gotoAfterLogin')?.length) {
              const route = localStorage.getItem('gotoAfterLogin');
              localStorage.removeItem('gotoAfterLogin');
              this.router.navigateByUrl(route);
            } else {
              this.router.navigate(['/onboarding']);
            }
            return LoginStateActions.LoginSuccess(null);
          }),
          catchError((error) => of(LoginStateActions.LoginError({ msg: error.message })))
        )
      )
    )
  );

  signup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SignupActions.SigningUp),
      switchMap((payload) =>
        from(this.authService.createUser(payload)).pipe(
          map((user) => SignupActions.SignupSuccess()),
          catchError((error) => of(SignupActions.SignupError({ msg: error.message })))
        )
      )
    )
  );

  resetMail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoginStateActions.ResetMailSending),
      switchMap((payload) => {
        return from(this.authService.resetPassword(payload.email)).pipe(
          map((_) => {
            this.toast.info('An Email has been sent to the provided mail ID');
            return LoginStateActions.ResetMailSent();
          }),
          catchError((error) => {
            this.toast.error('Sorry this mail id is not registered with us');
            return of(LoginStateActions.ResetMailError({ msg: error }));
          })
        );
      })
    )
  );

  resetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoginStateActions.Resetting),
      switchMap((payload) =>
        from(this.authService.confirmPasswordReset(payload.code, payload.newPassword)).pipe(
          map((_) => {
            this.authService.signout();
            this.toast.success('Password updated successfully');
            return LoginStateActions.ResetPasswordSuccess();
          }),
          catchError((err) => of(LoginStateActions.ResetPasswordError({ msg: err.message })))
        )
      )
    )
  );

  setPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoginStateActions.Setting),
      switchMap((payload) =>
        from(this.authService.setPassword(payload.uid, payload.newPassword, payload.email, payload.timezone)).pipe(
          map((res) => {

            this.toast.success('Password updated successfully');
            this.store$.dispatch(LoginStateActions.SetPasswordSuccess());
            return LoginStateActions.LogingIn({ userEmail: payload.email, userPassword: payload.newPassword });
          }),
          catchError((err) => {
            return of(LoginStateActions.SetPasswordError({ msg: err.message }));
          })
        )
      )
    )
  );

  addPartner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddPartnerActions.AddPartner),

      withLatestFrom(this.store$, (action, state) => ({ state, action })),
      switchMap((payload) =>
        from(
          this.addpartnerService.addPartner(payload.action.partner, payload.action.imageUrl, payload.action.id)
        ).pipe(
          switchMap((res) => {
            this.toast.success('Partner Added Successfully');
            return merge(
              of(AddPartnerActions.AddPartnerSuccess()),

              of(
                AddPartnerActions.addedPatener({
                  data: {
                    addedPartner: res,
                  },
                })
              )
            );
          }),
          catchError((err) => of(AddPartnerActions.AddPartnerError({ msg: err.message })))
        )
      )
    )
  );

  partnersList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddPartnerActions.PartnersList),
      switchMap(({ userId }) => {

        return this.partnerService.getPartnersList(userId).pipe(
          takeUntil(this.subService.unsubscribe$),
          map((partners) => {

            return AddPartnerActions.PartnersListSuccess({ data: partners });
          }),
          catchError((error) => {

            return of(AddPartnerActions.PartnersListError({ msg: error.message }));
          })
        );
      })
    )
  );

  getpartnersList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddPartnerActions.GetPartnersList),
      withLatestFrom(this.store$, (action, state) => ({ state, action })),
      switchMap((payload) => {
        if (payload.state.login.currentUser.isEnterprise) {
          this.Id = payload.state.login.currentUser.enterpriseId;
        } else if (payload.state.login.currentUser.userType !== 'SLO') {
            this.Id = payload.state.login.currentUser.parentId;
          } else {
            this.Id = payload.state.login.currentUser.uid;
          }
        return this.partnerService.getPartnersList(this.Id).pipe(
          takeUntil(this.subService.unsubscribe$),
          switchMap((partners) => {
            return [AddPartnerActions.PartnersListSuccess({ data: partners })];
          }),
          catchError((error) => {
            return of(AddPartnerActions.PartnersListError({ msg: error.message }));
          })
        );
      })
    )
  );


  listPartnerPaginate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddPartnerActions.ListPartnersWithDemandScroll),
      withLatestFrom(this.store$, (action, state) => ({ state, action })),
      switchMap((payload) => {
        return this.partnerService.listPartnersPaginate(payload.action.user_id, payload.state.partner.last_doc).pipe(
          takeUntil(this.subService.unsubscribe$),
          switchMap((partner: any[]) => {
            return of(AddPartnerActions.ListPartnersWithDemandScrollSuccess({ data: partner }));
          }),
          catchError((error) => {
            return of(AddPartnerActions.ListPartnersWithDemandScrollError({ data: error.message }));
          })
        );
      })
    )
  );

  updatePartner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddPartnerActions.UpdatePartner),
      withLatestFrom(this.store$, (action, state) => ({ state, action })),
      switchMap((payload) =>
        from(
          this.addpartnerService.updatePartner(
            payload.action.id,
            payload.action.partner,
            payload.action.imageUrl,
            payload.action.updatedContactsList
          )
        ).pipe(
          switchMap((_) => {
            this.toast.success('Partner Updated Successfully');
            this.modal.dismissAll();
            return merge(
              of(AddPartnerActions.UpdatePartnerSuccess()),
              of(
                AddPartnerActions.FetchPartners({
                  data: {
                    curPage: payload.state.partner.curPage,
                    id: payload.state.login.currentUser.parentId
                      ? payload.state.login.currentUser.parentId
                      : payload.state.login.currentUser.uid,
                  },
                })
              )
            );
          }),
          catchError((err) => of(AddPartnerActions.AddPartnerError({ msg: err.message })))
        )
      )
    )
  );

  FetchPartnerCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddPartnerActions.GetPartnerCount),
      switchMap((payload) =>
        from(this.partnerService.fetchCount(payload.data)).pipe(
          map((data) => {
            return AddPartnerActions.PartnerCount({ count: data['count'] });
          })
        )
      )
    )
  );

  FetchPartners$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddPartnerActions.FetchPartners),
      switchMap((payload) => {
        this.appService.setPartnerLoading(true);
        return from(this.partnerService.fetchPartners(payload.data)).pipe(
          map((result) => {
            this.appService.setPartnerLoading(false);
            return AddPartnerActions.PartnersListSuccess({ data: result });
          })
        );
      })
    )
  );

  SearchPartners$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddPartnerActions.SearchPartners),
      switchMap((payload) => {
        return from(this.partnerService.searchPartners(payload.data)).pipe(
          map((result) => {
            return AddPartnerActions.PartnersListSuccess({
              data: result,
              scroll: payload.scroll ? payload.scroll : null,
            });
          })
        );
      })
    )
  );

  SortPartners$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddPartnerActions.SortPartners),
      switchMap((payload) => {
        this.appService.setPartnerLoading(true);
        return from(this.partnerService.sortPartners(payload.data)).pipe(
          map((result) => {
            this.appService.setPartnerLoading(false);
            return AddPartnerActions.PartnersListSuccess({ data: result });
          })
        );
      })
    )
  );

  DeletePartners$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddPartnerActions.DeletePartner),
      withLatestFrom(this.store$, (action, state) => ({ state, action })),
      mergeMap((payload) =>
        from(this.partnerService.deletePartners(payload.action.data)).pipe(
          switchMap((data) => {
            this.toast.clear();
            this.toast.success('Partner(s) Deleted Successfully');
            sleep(1000);
            return merge(
              of(AddPartnerActions.DeletePartnerSuccess())
            );
          }),
          catchError((err) => {
            this.toast.clear();
            this.toast.error('Error Removing Partner(s)');
            return of(AddPartnerActions.DeletePartnerError({ msg: err.message }));
          })
        )
      )
    )
  );

  UploadCSV$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddPartnerActions.UploadPartnersCSV),
      switchMap((payload) => from(this.partnerService.uploadCsv(payload.id, payload.file))),
      map((_) => {
        this.toast.success('CSV Uploading in progress', 'File Upload', { positionClass: 'toast-top-right' });
        this.modal.dismissAll();
        return AddPartnerActions.UploadPartnersCSVSuccess();
      }),
      catchError((err) => {
        this.toast.error('CSV Upload Failed', 'File Upload', { positionClass: 'toast-top-right' });
        return of(AddPartnerActions.UploadPartnersCSVError({ msg: err.message }));
      })
    )
  );

  GetEnterprisePermission$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoginStateActions.GetEnterprisePermissions),
      debounceTime(1000),
      withLatestFrom(this.store$, (action, state) => ({ state, action })),
      switchMap((payload) =>
        this.authService.getEnterprisePermissionObject(payload.state.login.currentUser.enterpriseId).pipe(
          takeUntil(this.subService.unsubscribe$),
          switchMap((val) => of(LoginStateActions.PermissionSuccess({ data: val }))),
          catchError((error) => {
            return of(null);
          })
        )
      )
    )
  );

}
