import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/services/auth.service';
import { Observable, of, Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { selectCurrentUser } from 'src/app/modules/login/store/selectors';
import { LoginState } from 'src/app/modules/login/store/state';
import { map, take } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  constructor(
    private auth: AuthService,
    private http: HttpClient,
    private store: Store<LoginState>,
    private db: AngularFirestore,
    private toast: ToastrService
  ) {
    if (window.location.hostname === 'localhost') {
      this.prefix = 'test_';
    }
    this.currentUser$ = this.store.pipe(select(selectCurrentUser));
    this.currentUser$.pipe(take(1)).subscribe((data) => {
      this.currentUser = data;
    });
  }

  currentUser: any;

  currentUser$: Observable<any>;

  prefix = '';

  private callContact = new Subject<any>();

  callEvent$ = this.callContact.asObservable();

  private disconnectCall = new Subject<any>();

  disconnectEvent$ = this.disconnectCall.asObservable();

  getUserProcess(id: string) {
    return this.db
      .collection<any>('user_upload_process', (ref) =>
        ref.where('user_id', '==', id).where('dismissed', '==', false).orderBy('start_date', 'asc')
      )
      .valueChanges();
  }

  async dismissProcess(id: string) {
    await this.db.collection<any>('user_upload_process').doc(id).update({ dismissed: true });
  }

  async dismissAll(id: string) {
    const process = await this.db
      .collection<any>('user_upload_process', (ref) => ref.where('user_id', '==', id).where('dismissed', '==', false))
      .ref.get();
    process.forEach(async (p) => {
      await p.ref.update({ dismissed: true });
    });
  }

  async getTaskData(task_id: string) {
    return (await this.db.collection<any>('tasks').doc(task_id).ref.get()).data();
  }

  async getProcessLog(pid: string) {
    const logData = (await this.db.collection<any>('user_process_logs').doc(pid).ref.get()).data();
    const failed_records: any[] = logData.failed_records_obj ? logData.failed_records_obj : [];
    return failed_records;
  }

  async refreshOAuthTokens() {
    const cUser: any = (
      await this.db
        .collection<any>('users')
        .doc(await this.auth.getCurrentUserUid())
        .ref.get()
    ).data();
    if (Object.keys(cUser).includes('oAuthPlatform') && !cUser.oAuthPlatform) {
        this.toast.info('Your Email Integration is Expired, Please Integrate Again', '', {
          disableTimeOut: true,
          closeButton: true,
        });
        return;
      }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };

  }

  callContactOperation(obj: any) {
    this.callContact.next(obj);
  }

  disconnectCallOperation(obj: any) {
    this.disconnectCall.next(obj);
  }

  addTimelineTask(contactData: any, userData: any) {
    const ref = this.db.collection<any>('tasks');
    const date = new Date().toISOString();
    const payload = {
      assignedby: userData,
      assignedto: userData,
      contactId: contactData.doc_id,
      contactName: `${contactData.firstName} ${contactData.lastName}`,
      created_at: date,
      dueTime: date,
      note: '',
      task: 'call',
      taskStatus: 'completed',
      updated_at: date,
      visibility: false,
    };
    ref.add(payload).then((doc) => doc.update({ task_id: doc.id }));
  }

  async contactLastContacted(doc_id: string) {
    await this.db
      .collection<any>('contacts')
      .doc(doc_id)
      .update({
        last_msg: { body: `Outbound Call`, time: new Date(), type: 'outbound' },
        communicated: true,
      });
  }

  async calculateRemainigAmount(uid: string) {
    const payment_history_array = (
      await this.db
        .collection<any>('payment_history')
        .ref.where('uid', '==', uid)
        .where('userType', '==', 'Super Admin')
        .where('status', '==', 'failed')
        .limit(1)
        .get()
    ).docs.map((h) => h.data());
    return payment_history_array[0];
  }

  async getEnterpriseLogo() {
    const id = this.auth.currentUser.uid;
    const user = (await this.db.collection<any>('users').doc(id).ref.get()).data();
    let docId = null;
    if (user.isEnterprise) {
      const { enterpriseId, managerId, teamLeaderId } = user;
      const uid = ['manager', 'TeamLeader'].includes(user.userType) ? user.uid : null;

      const idArrays = [uid, enterpriseId, managerId, teamLeaderId].filter((id) => !!id);
      return this.db
        .collection<any>('enterpriseLogos', (ref) => ref.where('docId', 'in', idArrays))
        .valueChanges()
        .pipe(
          map((docs: any) => {
            docId = null;
            const tempObj = {};
            docs.forEach((d) => (tempObj[d.docId] = d));
            if (!docId && uid) {
              const logoDataEnterprise = tempObj[uid];
              if (logoDataEnterprise) {
                docId = uid;
              }
            }
            if (!docId && teamLeaderId) {
              const logoDataEnterprise = tempObj[teamLeaderId];
              if (logoDataEnterprise) {
                docId = teamLeaderId;
              }
            }
            if (!docId && managerId) {
              const logoDataEnterprise = tempObj[managerId];
              if (logoDataEnterprise) {
                docId = managerId;
              }
            }
            if (!docId && enterpriseId) {
              const logoDataEnterprise = tempObj[enterpriseId];
              if (logoDataEnterprise) {
                docId = enterpriseId;
              }
            }
            if (docId) {
              return tempObj[docId];
            }
            return undefined;
          })
        );
    }
      if (user.userType === 'JLO' || user.userType === 'MO') {
        docId = user.parentId;
      }
      if (user.userType === 'SLO') {
        docId = user.uid;
      }
      if (!docId) {
        return of(undefined);
      }
      const logoData = this.db.collection<any>('enterpriseLogos').doc(docId).valueChanges();
      return logoData;

  }
}
