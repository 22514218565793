import { BehaviorSubject, Observable, of } from 'rxjs';
import { finalize, switchMap, map } from 'rxjs/operators';
import * as _ from 'underscore';
import * as moment from 'moment-timezone';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import * as firebase from 'firebase/app';
import { ToastrService } from 'ngx-toastr';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';

import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
import { sleep, timeZonesMap } from 'src/app/shared/utils';
import { COLLECTIONS } from 'src/app/shared/config';

import { UserType } from 'src/app/shared/enums/commonEnums';
import { ContactDetail } from '../store/models';
import { AddTaskService } from './create-task.service';

import * as actions from '../store/action';

const api = environment.mongoUrl;
const cCode = environment.countryCode;

@Injectable({
  providedIn: 'root',
})
export class ContactService {
  task: AngularFireUploadTask;

  contactsRef = this.db.collection<any>(COLLECTIONS.CONTACTS);

  constructor(
    private db: AngularFirestore,
    private storage: AngularFireStorage,
    private auth: AuthService,
    private http: HttpClient,
    private modalService: NgbModal,
    private store: Store<any>,
    private toastr: ToastrService,
    private taskService: AddTaskService
  ) {}

  private DetailsScreenSearchVal = new BehaviorSubject<any>({
    searchVal: '',
  });

  private ContactUI = new BehaviorSubject<any>({
    ui: null,
  });

  setSearchVal(val: any) {
    this.DetailsScreenSearchVal.next(val);
  }

  getSearchVal() {
    return this.DetailsScreenSearchVal.asObservable();
  }

  async getContactDetailsUI(uid: any) {
    return (await this.db.collection<any>(COLLECTIONS.CONTACTS_DETAILS_UI).doc(uid).ref.get()).data();
  }

  async setContactDetailsUI(uid: any, uiData) {
    return this.db.collection<any>(COLLECTIONS.CONTACTS_DETAILS_UI).doc(uid).set({ ui: uiData });
  }

  async setContactUI(uid: string) {
    this.getContactDetailsUI(uid).then((uiData) => {
      if (uiData) {
        this.ContactUI.next(uiData);
      }
    });
  }

  getContactUI() {
    return this.ContactUI.asObservable();
  }

  async formatDate(date: string, addedBy: string) {
    let date_formatted = null;
    const userQuery = await this.db.collection<any>(COLLECTIONS.USERS).doc(addedBy).ref.get();
    const user = userQuery.data();
    const brithdayorani = date;
    const thisYear = moment().tz(timeZonesMap[user.timezone]).year();
    const birthdayorani_thisyear = moment(brithdayorani)
      .tz(timeZonesMap[user.timezone])
      .year(thisYear)
      .hour(9)
      .minute(30)
      .toISOString();

    const today = moment().tz(timeZonesMap[user.timezone]).toISOString();

    date_formatted = moment(today).isBefore(birthdayorani_thisyear, 'day')
      ? birthdayorani_thisyear
      : moment(birthdayorani_thisyear).add(1, 'year').toISOString();

    return date_formatted;
  }

  getOPtoutDrip(id: string) {
    return this.db
      .collection<any>(COLLECTIONS.DRIP_CAMPAIGN)
      .ref.where('parentId', '==', id)
      .where('optout', '==', true)
      .limit(1)
      .get();
  }

  async createContact(data, task): Promise<any> {
    let doc_id = '';
    const currentUser = this.auth.getUserData();
    if (currentUser.isEnterprise) {
      data = { ...data, isEnterprise: true };
    }
    this.db
      .collection<any>(COLLECTIONS.CONTACTS)
      .add(data)
      .then(async (value) => {
        this.db.collection<any>(COLLECTIONS.CONTACTS).doc(value.id).update({ doc_id: value.id });
        doc_id = value.id;

        if (data.partnerId) {
          const refContactData = {
            contact_id: value.id,
            contact_name: `${data.firstName} ${data.lastName}`,
          };
          this.db
            .collection<any>(COLLECTIONS.PARTNERS)
            .doc(data.partnerId)
            .update({
              referralContacts: firebase.default.firestore.FieldValue.arrayUnion(refContactData),
            });
        }

        if (data.dripCampaigns && data.dripCampaigns.length > 0) {
          const adding = await this.addContactToDrips(value.id, data.dripCampaigns);
        }
        await this.taskService.createContactTask(task, value.id);
      });

    return new Promise((resolve) => {
      setTimeout(() => resolve({ doc_id }), 4000);
    });
  }

  deleteContact(id: string): Promise<any> {
    return this.db.collection<any>(COLLECTIONS.CONTACTS).doc(id).delete();
  }

  async updateContact(data, oldContact): Promise<any> {
    if (
      oldContact &&
      data.associated_loan_ids &&
      (data.property_addressLine1 !== oldContact.property_addressLine1 ||
        data.property_addressLine2 !== oldContact.property_addressLine2 ||
        data.property_city !== oldContact.property_city ||
        data.property_state !== oldContact.property_state ||
        data.property_zip !== oldContact.property_zip)
    ) {
      const primary_transaction =
        data.primary_transaction ?? data.associated_loan_ids ? data.associated_loan_ids[0] : null;
      if (primary_transaction) {
        this.db.collection<any>(COLLECTIONS.TRANSACTIONS).doc(primary_transaction).update({
          property_addressLine1: data.property_addressLine1,
          property_addressLine2: data.property_addressLine2,
          property_city: data.property_city,
          property_state: data.property_state,
          property_zip: data.property_zip,
        });
      }
    }

    if (oldContact && oldContact.partnerId && oldContact.partnerId !== data.partnerId) {
      if (data.partnerId) {
        const refContactData = {
          contact_id: data.doc_id,
          contact_name: `${data.firstName} ${data.lastName}`,
        };
        await this.db
          .collection<any>(COLLECTIONS.PARTNERS)
          .doc(data.partnerId)
          .update({ referralContacts: firebase.default.firestore.FieldValue.arrayUnion(refContactData) });
      }
      const oldPartner = (
        await this.db.collection<any>(COLLECTIONS.PARTNERS).doc(oldContact.partnerId).ref.get()
      ).data();
      let referralContacts = oldPartner.referralContacts as any[];
      if (referralContacts && referralContacts.length > 0) {
        referralContacts = referralContacts.filter((r) => r.contact_id !== data.doc_id);
        await this.db.collection<any>(COLLECTIONS.PARTNERS).doc(oldContact.partnerId).update({ referralContacts });
      }
    }
    if ((!oldContact || (oldContact.partnerId && oldContact.partnerId !== data.partnerId)) && data.partnerId) {
      const refContactData = {
        contact_id: data.doc_id,
        contact_name: `${data.firstName} ${data.lastName}`,
      };
      await this.db
        .collection<any>(COLLECTIONS.PARTNERS)
        .doc(data.partnerId)
        .update({ referralContacts: firebase.default.firestore.FieldValue.arrayUnion(refContactData) });
    }

    if (data.previousPartner) {
      const preData = {
        contact_id: data.doc_id,
        contact_name: `${data.firstName} ${data.lastName}`,
      };
      await this.db
        .collection<any>(COLLECTIONS.PARTNERS)
        .doc(data.previousPartner)
        .update({ referralContacts: firebase.default.firestore.FieldValue.arrayRemove(preData) });
    }
    const { tags, ...tempData } = data;
    await sleep(3000);
    return this.contactsRef.doc(data.doc_id).update(tempData);
  }

  async updateContactStatus(data): Promise<any> {
    try {
      let uID = this.auth.currentUser.uid;
      const userQuery = await this.db.collection<any>(COLLECTIONS.USERS).doc(uID).ref.get();
      const user = userQuery.data();
      if (user.isEnterprise) {
        if (user.userType === UserType.JLO || user.userType === UserType.MO) {
          uID = user.teamLeaderId ?? user.managerId ?? user.enterpriseId;
        }
        if (user.userType === UserType.SUPER_ADMIN || user.userType === UserType.ADMIN) {
          uID = user.enterpriseId;
        }
      } else if (user.userType === UserType.JLO || user.userType === UserType.MO) {
        uID = user.parentId;
      }

      if (data.presentStatus?.length && data.presentStatus !== 'Lead') {
        const contactsPresentStatusRef = await this.db
          .collection<any>(COLLECTIONS.CONTACTS)
          .ref.where('status', '==', data.presentStatus)
          .where('viewersList', 'array-contains', uID)
          .limit(1)
          .get();

        if (contactsPresentStatusRef.size > 0) {
          const presentStatusArray = contactsPresentStatusRef.docs.map((doc) => doc.data().status);
          const statusReq = await this.db
            .collection(COLLECTIONS.MILESTONE_STATUS)
            .ref.where('status_name', '==', data.status)
            .limit(1)
            .get();
          if (statusReq.size > 0) {
            const statusId = statusReq.docs[0].id;
            await this.db.collection(COLLECTIONS.MILESTONE_STATUS).doc(statusId).update({ deletable: 'false' });
          }

          const req = await this.db
            .collection(COLLECTIONS.MILESTONE_STATUS)
            .ref.where('status_name', '==', data.presentStatus)
            .where('viewersList', 'array-contains', uID)
            .limit(1)
            .get();
          if (req.size > 0) {
            const { id } = req.docs[0];
            presentStatusArray.length > 1
              ? await this.db.collection(COLLECTIONS.MILESTONE_STATUS).doc(id).update({ deletable: 'false' })
              : await this.db.collection(COLLECTIONS.MILESTONE_STATUS).doc(id).update({ deletable: 'true' });
          }
        }
      }

      return await this.contactsRef.doc(data.doc_id).set({ status: data.status }, { merge: true });
    } catch (error) {
      console.log('error ==>', error);
      return error;
    }
  }

  getDetailOfContact(contactid: string): any {
    return this.contactsRef.doc(contactid).valueChanges() as Observable<ContactDetail>;
  }

  contactFileAdd(contactId, title, comment, imageUrl, upload_by, fileName) {
    const data = {
      contact_id: contactId,
      title,
      comment,
      createdAt: new Date().toISOString(),
      upload_by,
      fileName,
    };
    return new Promise((resolve, reject) => {
      this.db
        .collection<any>(COLLECTIONS.FILES)
        .add(data)
        .then((element) => {
          this.db
            .collection<any>(COLLECTIONS.FILES)
            .doc(element.id)
            .update({ doc_id: element.id })
            .then(() => {
              this.uploadFile(element.id, contactId, imageUrl)
                .then((r) => {
                  resolve(r);
                })
                .catch((error) => {
                  reject(error);
                });
            })
            .catch((error) => {
              reject(error);
            });
        });
    });
  }

  uploadFile(id, contactId, imageUrl) {
    const currentTime = Date.now();
    const path = `contact_details_files/${contactId}/${id}_${currentTime}`;
    const ref = this.storage.ref(path);
    this.task = this.storage.upload(path, imageUrl);
    this.task.percentageChanges().subscribe((num) => {
      this.store.dispatch(actions.FileUploadProgress({ progress: num }));
    });
    return new Promise((resolve, reject) => {
      this.task
        .snapshotChanges()
        .pipe(
          finalize(() => {
            ref.getMetadata().subscribe((metaData) => {
              this.db
                .collection<any>(COLLECTIONS.FILES)
                .doc(id)
                .update({ type: metaData.contentType })
                .then((r) => {
                  resolve('success');
                })
                .catch((error) => {
                  reject(error);
                });
            });
            ref.getDownloadURL().subscribe((url) => {
              this.db
                .collection<any>(COLLECTIONS.FILES)
                .doc(id)
                .update({ image: url })
                .then((r) => {
                  resolve('success');
                })
                .catch((error) => {
                  reject(error);
                });
            });
          })
        )
        .subscribe();
    });
  }

  getContacts(data) {
    if (data && data.curPage) {
      return this.db
        .collection<any>(COLLECTIONS.CONTACTS, (ref) =>
          ref.where('viewersList', 'array-contains', data.id).orderBy('firstName', 'asc').limit(50)
        )
        .valueChanges();
    }
    return this.db
      .collection<any>(COLLECTIONS.CONTACTS, (ref) =>
        ref.where('viewersList', 'array-contains', data.id).orderBy('firstName', 'asc')
      )
      .valueChanges();
  }

  async processCampaign(body: { ids: { [index: string]: string[] }; parentId: string }) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    return this.http.post(`${api}/v0.1/drips/getCampaignNames`, body, httpOptions).toPromise();
  }

  async getContactsPaginate(obj: any, last_doc?: any) {
    if (obj.search) {
      return this.searchContact(obj.data, obj.user_id);
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    return this.http
      .post(
        `${api}/v0.1/contacts/fetchContactWithDemand`,
        { uid: obj.user_id, curPage: obj.curPage, partnerId: obj.partnerId ? obj.partnerId : null },
        httpOptions
      )
      .toPromise();
  }

  async getContactSnapshot(doc_id) {
    return this.db.collection<any>(COLLECTIONS.CONTACTS).doc(doc_id).ref.get();
  }

  getContactSnapshotAndDispatch(doc_id) {
    return this.db.collection<any>(COLLECTIONS.CONTACTS).doc(doc_id).get();
  }

  async uploadCsv(id, file, assigned_to, parentId, optout, groupId, selectedDripIds) {
    const date = new Date();
    const time = date.getTime();

    const path = `csv/contacts_${id}_${assigned_to}_${parentId}_${time}`;
    await this.db
      .collection<any>(COLLECTIONS.CSV_EXTRA_DATA)
      .doc(`contacts_${id}_${assigned_to}_${parentId}_${time}`)
      .set({
        groups: groupId ?? [],
        campaigns: selectedDripIds ?? [],
        optedOut: optout,
        doc_id: `contacts_${id}_${assigned_to}_${parentId}_${time}`,
      });
    const ref = this.storage.ref(path);
    this.task = this.storage.upload(path, file);
    const uploadObs = this.task.snapshotChanges();
    uploadObs
      .pipe(
        finalize(() => {
          ref.getDownloadURL().subscribe((url) => {});
        })
      )
      .subscribe();
    return uploadObs;
  }

  getContactFiles(id) {
    const getContactFiles = this.db
      .collection<any>(COLLECTIONS.FILES, (ref) => ref.where('contact_id', '==', id).orderBy('createdAt', 'asc'))
      .valueChanges();

    return getContactFiles;
  }

  deleteContactFile(id, imageUrl) {
    const image = imageUrl;
    return this.db
      .collection<any>(COLLECTIONS.FILES)
      .doc(id)
      .delete()
      .then(() => this.storage.storage.refFromURL(image).delete());
  }

  updateContactFile(id, note) {
    return this.db.collection<any>(COLLECTIONS.FILES).doc(id).update({ comment: note });
  }

  updateContactFile1(id, note, title) {
    return this.db.collection<any>(COLLECTIONS.FILES).doc(id).update({ comment: note, title });
  }

  downloadFile(id, image, fileName) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', image, true);
      xhr.responseType = 'blob';
      xhr.addEventListener('load', function () {
        const urlCreator = window.URL;
        const imageUrl = urlCreator.createObjectURL(this.response);
        const tag = document.createElement('a');
        tag.href = imageUrl;
        tag.download = fileName || id;
        document.body.append(tag);
        tag.click();
        tag.remove();
        resolve('success');
      });
      xhr.addEventListener('error', () => {
        reject('error');
      });
      xhr.send();
    });
  }

  async communicationMail(payload, url, taskId) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    this.http.post(url, payload, httpOptions).subscribe(
      async (res) => {
        await this.db
          .collection<any>(COLLECTIONS.TASKS)
          .doc(taskId)
          .update({
            updated_at: new Date().toISOString(),
            taskStatus: 'completed',
            email_attachments_array: payload.message.email_attachments_array
              ? payload.message.email_attachments_array
              : [],
            mggInfo: {
              title: payload.message.subject,
              message: payload.message.body,
            },
          });
        this.modalService.dismissAll();
        this.toastr.clear();
        this.toastr.success('Mail Sent Successfully');
      },
      (err) => {
        this.toastr.clear();
        this.toastr.error('Error Sending Mail');
      }
    );
  }

  async communicationSms(payload, url, taskId) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    this.http.post(url, payload, httpOptions).subscribe(
      async (res) => {
        this.modalService.dismissAll();
        this.toastr.clear();
        this.toastr.success('SMS Sent And Task Completed', 'Message Task');
      },
      () => {
        this.toastr.error('Unable To Send SMS,Something went wrong', 'Message Task');
      }
    );
  }

  async communicationCall(url, payload) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    return this.http.post(url, payload, httpOptions);
  }

  async updateCallStatus(payload, taskId) {
    await this.db
      .collection<any>(COLLECTIONS.TASKS)
      .doc(taskId)
      .update({
        ...payload,
      });
  }

  async getAllUsers(parentId: string) {
    const parentData = (await this.db.collection<any>(COLLECTIONS.USERS).doc(parentId).ref.get()).data();
    const children = (await this.db.collection<any>(COLLECTIONS.USERS).ref.where('parentId', '==', parentId).get())
      .docs;
    if (children.length > 0) {
      const childrenData = children.filter((c) => c.data().userType !== 'MO');
      return [parentData, ...childrenData.map((c) => c.data())];
    }
    return [parentData];
  }

  async taskPostpone(payload, taskId) {
    try {
      await this.db
        .collection<any>(COLLECTIONS.TASKS)
        .doc(taskId)
        .update({
          ...payload,
        });
      this.modalService.dismissAll();
    } catch (error) {
      this.modalService.dismissAll();
    }
  }

  ConvertToCSV(objArray, headerList) {
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = 'S.No, ';
    for (const header of headerList) {
      row += `${header}, `;
    }
    row = row.slice(0, -1);
    str += `${row}\r\n`;
    for (let i = 0; i < array.length; i += 1) {
      let line = `${i + 1}`;
      for (const index in headerList) {
        const head = headerList[index];
        line += typeof array[i][head] === 'object' ? `,${array[i][head].year}-${array[i][head].month}-${array[i][head].day}` : `,${array[i][head]}`;
      }
      str += `${line}\r\n`;
    }
    return str;
  }

  ConvertToCSV2(objArray, headerList) {
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = '';
    for (const header of headerList) {
      row += `${header},`;
    }
    row = row.slice(0, -1);
    str += `${row}\r\n`;
    for (let i = 0; i < array.length; i += 1) {
      let line = '';
      for (const index in headerList) {
        const head = headerList[index];
        line += typeof array[i][head] === 'object' ? `${array[i][head].year}-${array[i][head].month}-${array[i][head].day},` : `${array[i][head]},`;
      }
      str += `${line}\r\n`;
    }
    return str;
  }

  downloadContactsCSV(data, fileName) {
    const csvData = this.ConvertToCSV(data, ['firstName', 'lastName', 'email', 'phoneNumber', 'dateOfBirth']);
    const blob = new Blob([`\ufeff${csvData}`], {
      type: 'text/csv;charset=utf-8;',
    });
    const dwldLink = document.createElement('a');
    const url = URL.createObjectURL(blob);
    const isSafariBrowser =
      navigator.userAgent.includes('Safari') && !navigator.userAgent.includes('Chrome');
    if (isSafariBrowser) {
      dwldLink.setAttribute('target', '_blank');
    }
    dwldLink.setAttribute('href', url);
    dwldLink.setAttribute('download', `${fileName}.csv`);
    dwldLink.style.visibility = 'hidden';
    document.body.append(dwldLink);
    dwldLink.click();
    dwldLink.remove();
  }

  downloadTemplateCSV(data, fileName) {
    const csvData = this.ConvertToCSV2(data, [
      'First Name',
      'Last Name',
      'Email',
      'Phone Number',
      'Address Line 1',
      'Address Line 2',
      'City',
      'State',
      'Gender',
    ]);
    const blob = new Blob([`\ufeff${csvData}`], {
      type: 'text/csv;charset=utf-8;',
    });
    const dwldLink = document.createElement('a');
    const url = URL.createObjectURL(blob);
    const isSafariBrowser = navigator.userAgent.includes('Safari') && !navigator.userAgent.includes('Chrome');
    if (isSafariBrowser) {
      dwldLink.setAttribute('target', '_blank');
    }
    dwldLink.setAttribute('href', url);
    dwldLink.setAttribute('download', `${fileName}.csv`);
    dwldLink.style.visibility = 'hidden';
    document.body.append(dwldLink);
    dwldLink.click();
    dwldLink.remove();
  }

  downloadTemplateCSVTransaction(data, fileName) {
    const csvData = this.ConvertToCSV2(data, [
      'Full Name',
      'Data',
      'Stage',
      'Owner',
      'Account Name',
      'First Name',
      'Last Name',
      'First Name (Secondary or Co Borrower)',
      'Last Name (Secondary or Co Borrower)',
      'Phone',
      'Mobile',
      'Marital Status',
      'Street Address',
      'City',
      'State',
      'Zip',
      'Stage',
      'Birthday',
      'Birthday (Secondary or Co Borrower)',
      'Category (or Group)',
      'Email',
      'Email (Secondary or Co Borrower)',
      'Employer',
      'Employer Street Address',
      'Employer City',
      'Employer State',
      'Employer Zip',
      'Appraised Value',
      'Closing Date',
      'Prequalification Date',
      'Prequalification Expiration Date',
      'Credit Score',
      'Credit Score (Secondary or Co Borrower)',
      'Down Payment',
      'Income',
      'Income (Secondary or Co Borrower)',
      'Lender',
      'LOA',
      'Loan #',
      'Loan Amount',
      'Loan Officer',
      'Loan Program',
      'Loan Purpose',
      'Loan to Value (LTV) Percentage',
      'Loan Type',
      'Lock Date',
      'Lock Expiration Date',
      'Status',
      'Occupancy',
      'Property Address',
      'Property City',
      'Property State',
      'Property Zip',
      'Property Type',
      'Purchase Price',
      'Rate',
      'Related Contact',
      'Term',
    ]);
    const blob = new Blob([`\ufeff${csvData}`], {
      type: 'text/csv;charset=utf-8;',
    });
    const dwldLink = document.createElement('a');
    const url = URL.createObjectURL(blob);
    const isSafariBrowser = navigator.userAgent.includes('Safari') && !navigator.userAgent.includes('Chrome');
    if (isSafariBrowser) {
      dwldLink.setAttribute('target', '_blank');
    }
    dwldLink.setAttribute('href', url);
    dwldLink.setAttribute('download', `${fileName}.csv`);
    dwldLink.style.visibility = 'hidden';
    document.body.append(dwldLink);
    dwldLink.click();
    dwldLink.remove();
  }

  async getTeamLeaderId(user) {
    if(user?.teamLeaderId) {
      return user.teamLeaderId;
    }
    const url = `${environment.cloud_base_url}/getTeamLeaderId`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    const uid = user?.uid ?? user;

    const teamLeaderResponse: any = this.http.post(url, { uid }, httpOptions).toPromise();
    const teamLeaderId = await teamLeaderResponse;

    return teamLeaderId.teamLeaderId;
  }

  async isEmailValid(email, teamLeaderId) {
    const url = `${environment.cloud_base_url}/checkExistingContact`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };

    const contact: any = await this.http.post(url, { email, teamLeaderId }, httpOptions).toPromise();

    return !contact?.data?.doc_id;
  }

  async isEmailValidOnUpdate(inputMail, user_id, teamLeaderId, contact) {
    const existing = await this.db
      .collection<any>(COLLECTIONS.CONTACTS, (ref) =>
        ref.where('email', '==', inputMail.toLowerCase()).where('viewersList', 'array-contains', user_id)
      )
      .get()
      .toPromise();

    const matchedTeamLeader = existing.docs.some((f) => {
      const data = f.data();
      return (!teamLeaderId && data.teamLeaderId) || data.teamLeaderId === teamLeaderId;
    });

    if (!matchedTeamLeader || existing.size === 0) {
      return true;
    }

    let returnValue = false;
    if (existing.size === 1) {
      for (const doc of existing.docs) {
        if (doc.data().doc_id === contact.doc_id) {
          returnValue = true;
        }
      }
    }
    return returnValue;
  }

  async isPhoneValid(inputPhone, teamLeaderId, user_id) {
    const existing = await this.db
      .collection<any>(COLLECTIONS.CONTACTS, (ref) =>
        ref.where('phoneNumber', '==', cCode + inputPhone).where('viewersList', 'array-contains', user_id)
      )
      .get()
      .toPromise();

    const matchedTeamLeader = existing.docs.some((f) => {
      const data = f.data();
      return (!teamLeaderId && data.teamLeaderId) || data.teamLeaderId === teamLeaderId;
    });

    if (!matchedTeamLeader || existing.size === 0) {
      return true;
    }
    return false;
  }

  async isPhoneValidOnUpdate(inputPhone, user_id, teamLeaderId, doc_id) {
    const existing = await this.db
      .collection<any>(COLLECTIONS.CONTACTS, (ref) =>
        ref.where('phoneNumber', '==', cCode + inputPhone).where('viewersList', 'array-contains', user_id)
      )
      .get()
      .toPromise();

    const matchedTeamLeader = existing.docs.some((f) => {
      const data = f.data();
      return (!teamLeaderId && data.teamLeaderId) || data.teamLeaderId === teamLeaderId;
    });

    if (!matchedTeamLeader || existing.size === 0) {
      return true;
    }
    if (existing.size === 1) {
      for (const doc of existing.docs) {
        if (doc.data().doc_id === doc_id) {
          return true;
        }
      }
    }
    return false;
  }

  getSmsList(id) {
    const smsRef = this.db
      .collection<any>(COLLECTIONS.SMS_LOGS, (ref) =>
        ref
          .where('contact_id', '==', id)
          .where('type', 'in', [
            'drip_viaMessage_media',
            'drip_viaMessage_text',
            'drip_viaMessage_voiceDrop',
            'normal',
            'inbound',
            'contact_mail',
          ])
          .orderBy('sent_date', 'asc')
      )
      .valueChanges();
    return smsRef;
  }

  getMyUser(uid) {
    const users = this.db
      .collection<any>(COLLECTIONS.USERS, (ref) => ref.where('parentId', '==', uid).where('status', '==', 'Active'))
      .valueChanges();
    return users;
  }

  getMySLO(uid) {
    const user = this.db.collection<any>(COLLECTIONS.USERS, (ref) => ref.where('uid', '==', uid)).valueChanges();
    return user;
  }

  async downloadCSVContacts(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };

    return this.http.post(`${api}/v0.1/contacts/downloadCSV`, data, { ...httpOptions, responseType: 'blob' }).pipe(
      switchMap((resp) => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(resp);
        link.download = `contacts_${new Date().toLocaleString()}.csv`;
        link.click();
        return of();
      })
    );
  }

  getDistinctContactStatus(): Observable<any> {
    const user_id = this.auth.currentUser.uid;
    const body = { user_id };
    return this.http.post(`${api}/v0.1/contacts/getDistinctContactStatus`, body);
  }

  async addContactToDrips(contact_id: string, drip_campaigns: string[]) {
    this.toastr.info('Contact will be added to drip(s) in background', 'Adding to Drip(s)', {
      progressBar: true,
      progressAnimation: 'decreasing',
    });
    const url = `${environment.cloud_base_url}/addTaskForContact`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    return this.http.post(url, { contact_id, drip_campaigns }, httpOptions).toPromise();
  }

  async batchAddContactToDrips(
    contact_ids: string[],
    drip_campaign_Ids: string[],
    areAllSelected: boolean,
    mongoParams: any,
    onlyMe: boolean,
    id: string
  ) {
    let contact_Ids: any = contact_ids;
    const url = `${environment.cloud_base_url}/batchAddContactsToDrip`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    if (areAllSelected) {
      contact_Ids = await this.http
        .post(`${api}/v0.1/contacts/fetchContactIdsForMassAction`, { value: mongoParams, onlyMe, id }, httpOptions)
        .toPromise();
      contact_Ids = contact_Ids.contactIds.map((ids) => ids.doc_id);
    }
    return this.http.post(url, { contact_Ids, drip_campaign_Ids }, httpOptions).toPromise();
  }

  async removeContactFromDrips(contact_id: string, drip_campaigns: string[]) {
    const url = `${environment.cloud_base_url}/removeContactsFromDrips`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    return this.http.post(url, { contact_id, drip_campaigns }, httpOptions).toPromise();
  }

  async fetchCount(data, onlyMe: boolean) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    return this.http.post(`${api}/v0.1/contacts/fetchContactCount`, { id: data, onlyMe }, httpOptions).toPromise();
  }

  async fetchContactFromMongo(data, onlyMe: boolean, doc_Id?: boolean) {
    let doc_id = false;
    if (doc_Id) {
      await sleep(2000);
      doc_id = true;
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    return this.http
      .post(`${api}/v0.1/contacts/fetchContact`, { details: data, onlyMe, doc_id }, httpOptions)
      .toPromise();
  }

  async fetchContactFromMongoModified(data, uid, onlyMe?: boolean, doc_Id?: boolean, limit?: number) {
    let doc_id = false;
    if (doc_Id) {
      await sleep(2000);
      doc_id = true;
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    const _limit = limit || 50;
    return this.http
      .post(
        `${api}/v0.1/contacts/getContact`,
        { value: data, id: uid, onlyMe: !!onlyMe, doc_id, limit: _limit },
        httpOptions
      )
      .toPromise();
  }

  async searchContact(data, uid, onlyMe?: boolean, doc_Id?: boolean) {
    let doc_id = false;
    if (doc_Id) {
      await sleep(2000);
      doc_id = true;
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    return this.http
      .post(`${api}/v0.1/contacts/getContact`, { value: data, id: uid, onlyMe: !!onlyMe, doc_id }, httpOptions)
      .toPromise();
  }

  async filterContact(data, uid, onlyMe: boolean, doc_Id?: boolean) {
    let doc_id = false;
    if (doc_Id) {
      await sleep(2000);
      doc_id = true;
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    return this.http
      .post(`${api}/v0.1/contacts/filterContact`, { value: data, id: uid, onlyMe, doc_id }, httpOptions)
      .toPromise();
  }

  async getTwilioToken() {
    const resp = await fetch(`${environment.cloud_base_url}/getCapabilityTokenTwilio`);
    const { token } = await resp.json();
    return token;
  }

  async getMyDripData(parentId) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    return this.http.post(`${environment.cloud_base_url}/getAllUserDrips`, { parentId }, httpOptions).toPromise();
  }

  async sortContact(data, uid, onlyMe: boolean, doc_Id?: boolean) {
    let doc_id = false;
    if (doc_Id) {
      await sleep(2000);
      doc_id = true;
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    return this.http
      .post(`${api}/v0.1/contacts/sortContact`, { value: data, id: uid, onlyMe, doc_id }, httpOptions)
      .toPromise();
  }

  async getDripDataByid(drip_id: string) {
    const drip = (await this.db.collection<any>(COLLECTIONS.DRIP_CAMPAIGN).doc(drip_id).ref.get()).data();
    return drip;
  }

  async getContactDripStatus(contact_id: string, drip_id: string) {
    const activeDrip_tasks = await this.db
      .collection<any>(COLLECTIONS.DRIP_TASKS)
      .ref.where('campaign_id', '==', drip_id)
      .where('contact_id', '==', contact_id)
      .where('status', 'in', ['scheduled', 'rescheduled'])
      .orderBy('trigger_time', 'desc')
      .limit(2)
      .get();
    const activeDripDetails = await activeDrip_tasks.docs.map((da) => da.data());
    if (activeDripDetails.length > 0) {
      return {
        id: activeDripDetails[0].campaign_id,
        name: activeDripDetails[0].campaign_name,
        status: activeDripDetails[0].drip_status === 'Active' ? 'Active' : 'InActive',
        active_contacts_count: 0,
      };
    }
    const expiredDrip_tasks = await this.db
      .collection<any>('drip_tasks')
      .ref.where('campaign_id', '==', drip_id)
      .where('contact_id', '==', contact_id)
      .where('status', 'in', ['pending', 'completed'])
      .orderBy('trigger_time', 'desc')
      .limit(2)
      .get();
    const expiredDripDetails = await expiredDrip_tasks.docs.map((da) => da.data());
    if (expiredDripDetails.length > 0) {
      return {
        id: expiredDripDetails[0].campaign_id,
        name: expiredDripDetails[0].campaign_name,
        status: 'Completed',
        active_contacts_count: 0,
      };
    } else {
      const expiredDrip_tasks = await this.db
        .collection<any>(COLLECTIONS.DRIP_TASKS)
        .ref.where('campaign_id', '==', drip_id)
        .where('contact_id', '==', contact_id)
        .where('status', 'in', ['pending', 'completed'])
        .orderBy('trigger_time', 'desc')
        .limit(2)
        .get();
      const expiredDripDetails = await expiredDrip_tasks.docs.map((da) => da.data());
      if (expiredDripDetails.length > 0) {
        return {
          id: expiredDripDetails[0]['campaign_id'],
          name: expiredDripDetails[0]['campaign_name'],
          status: 'Completed',
          active_contacts_count: 0,
        };
      } 
        const dripDetails = (await this.db.collection<any>(COLLECTIONS.DRIP_CAMPAIGN).doc(drip_id).ref.get()).data();

        return dripDetails
          ? {
              id: dripDetails?.drip_campaign_id,
              name: dripDetails?.campaign_name,
              status: 'Hold',
              active_contacts_count: 0,
            }
          : null;
      
    }
  }

  /**
   * cloud function to assign contacts to officers
   * @param contact_Ids:Contacts Ids
   * @param userId : assigned user Id
   */
  async assignToOfficer(
    contact_assigned_to,
    contact_ids,
    areAllSelected: boolean,
    mongoParams: any,
    onlyMe: boolean,
    id: string
  ) {
    let contact_Ids: any = contact_ids;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    if (areAllSelected) {
      contact_Ids = await this.http
        .post(`${api}/v0.1/contacts/fetchContactIdsForMassAction`, { value: mongoParams, onlyMe, id }, httpOptions)
        .toPromise();
      contact_Ids = contact_Ids.contactIds.map((ids) => ids.doc_id);
    }

    return this.http
      .put(`${environment.cloud_base_url}/batchAssignContacts`, { contact_assigned_to, contact_Ids }, httpOptions)
      .toPromise();
  }

  /**
   * cloud function to batch delete contacts
   * @param contact_Ids array  of selected contacts ids
   * @param parentId parentId
   */
  async deleteBatchContacts(contact_Ids, parentId, single?: boolean) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    this.toastr.info(
      `Contact${single ? '' : '(s)'} will be deleted shortly`,
      !single ? 'Batch Operations' : 'Delete Contact',
      {
        closeButton: true,
        disableTimeOut: true,
      }
    );
    return this.http
      .post(`${environment.cloud_base_url}/contactBatchDelete`, { contact_Ids, parentId }, httpOptions)
      .toPromise();
  }
  /**
   * Function to add/remove tags batch action
   * @param contact_Ids array of contact ids
   * @param addedTags added tags
   * @param removedTags removed tags
   */

  async TagsBatch(
    contact_ids: string[],
    addedTags: string[],
    removedTags: string[],
    areAllSelected: boolean,
    mongoParams: any,
    onlyMe: boolean,
    id: string
  ) {
    let contact_Ids: any = contact_ids;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };

    if (areAllSelected) {
      contact_Ids = await this.http
        .post(`${api}/v0.1/contacts/fetchContactIdsForMassAction`, { value: mongoParams, onlyMe, id }, httpOptions)
        .toPromise();
      contact_Ids = contact_Ids.contactIds.map((ids) => ids.doc_id);
    }
    return this.http
      .post(
        `${environment.cloud_base_url}/batchUpdateContactsTags`,
        { contact_Ids, addedTags, removedTags },
        httpOptions
      )
      .toPromise();
  }

  async sendMassMail(
    data: {
      contactsData: { contactId: string; contact_assigned_to: string; isMO: boolean; email: string }[];
      mailData: { subject: string; body: string };
      areAllSelected: boolean;
      removingDocIds: any;
    },
    mongoParams: any,
    onlyMe: boolean,
    id: string
  ) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    return this.http.post(`${environment.cloud_base_url}/sendBatchMailContact`, data, httpOptions).toPromise();
  }

  async sendMassMailCampain(data, mongoParams, onlyMe, id) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    let contact_Ids: any = data.contactId;
    if (data.areAllSelected) {
      contact_Ids = await this.http
        .post(`${api}/v0.1/contacts/fetchContactIdsForMassAction`, { value: mongoParams, onlyMe, id }, httpOptions)
        .toPromise();
      contact_Ids = contact_Ids.contactIds.map((ids) => ids.doc_id);
      if (data.removingDocIds.length > 0) {
        data.removingDocIds.forEach((item) => {
          contact_Ids = _.without(contact_Ids, item);
        });
      }
      const sendData = {
        contactIds: contact_Ids,
        mailData: {
          title: data.mailData.title,
          subject: data.mailData.subject,
          body: data.mailData.body,
          campaign_id: data.mailData.campaign_id,
          email_attachments_array: data.mailData.email_attachments_array,
          cc: data.mailData.cc,
        },
      };
      return this.http.post(`${environment.cloud_base_url}/emailCampaignHandler`, sendData, httpOptions).toPromise();
    }
    const sendData = {
      contactIds: data.contactIds,
      mailData: {
        title: data.mailData.title,
        subject: data.mailData.subject,
        body: data.mailData.body,
        campaign_id: data.mailData.campaign_id,
        email_attachments_array: data.mailData.email_attachments_array,
        cc: data.mailData.cc,
      },
    };
    return this.http.post(`${environment.cloud_base_url}/emailCampaignHandler`, sendData, httpOptions).toPromise();
  }

  async sendTestMailCampain(data) {
    const sendData = {
      mailData: {
        title: data.mailData.title,
        subject: data.mailData.subject,
        body: data.mailData.body,
        email_attachments_array: data.mailData.email_attachments_array,
        cc: data.mailData.cc,
      },
    };
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    return this.http.post(`${environment.cloud_base_url}/emailCampaignTester`, sendData, httpOptions).toPromise();
  }

  async sendTestMailNormal(data) {
    const sendData = {
      mailData: {
        subject: data.mailData.subject,
        body: data.mailData.body,
        email_attachments_array: data.mailData.email_attachments_array,
        cc: data.mailData.cc,
      },
    };
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    return this.http.post(`${environment.cloud_base_url}/sendBatchMailTester`, sendData, httpOptions).toPromise();
  }

  async sendMassSms(data: {
    contactsData: { contactId: string; isMO: boolean; contact_assigned_to: string; to: string }[];
    smsData: { body: string; from: string };
  }) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    return this.http.post(`${environment.cloud_base_url}/sendBatchSMSContact`, data, httpOptions).toPromise();
  }

  async getContactDataById(doc_id: string) {
    return (await this.db.collection<any>(COLLECTIONS.CONTACTS).doc(doc_id).ref.get()).data();
  }

  async enableAI(contactId: string, true_ai_user_id: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    const url = `${environment.cloud_base_url}/contactEnableAI`;
    await this.http.post(url, { contactId, true_ai_user_id }, httpOptions).toPromise();
  }

  async disableAI(contactId: string, TRUE_AI_USER_ID: string, TRUE_AI_CONTACT_ID: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    const url = `${environment.cloud_base_url}/contactDisableAI`;
    await this.http.post(url, { contactId, TRUE_AI_USER_ID, TRUE_AI_CONTACT_ID }, httpOptions).toPromise();
  }

  /**
   * function to charge AI for contact
   * @param uid userID
   * @param jid jloID

   */
  async chargeAI(uid: string, jid: string): Promise<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    const url = `${environment.cloud_base_url}/chargecard`;
    await this.http.post(url, { uid, jId: jid, action: 'increase_contact_limit' }, httpOptions).toPromise();
  }

  async userCustomTable(customTable: any, id: string) {
    this.db.collection<any>(COLLECTIONS.USERS).doc(id).update({ custom_table: customTable });
  }

  async fileUpload(id, file) {
    const path = `voice-drop/${id}`;
    const task = this.storage.upload(path, file);
    const snpahsot = await task;
    const url = await snpahsot.ref.getDownloadURL();
    return url;
  }
  /**
   * Function to send mass Voice Drop
   * @param voiceDropPayload payload which contains information like contatcts details, media url , from    */

  async sendMassVoiceDrop(voiceDropPayload) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    const url = `${environment.cloud_base_url}/sendBatchVoiceDropContact`;
    await this.http.post(url, voiceDropPayload, httpOptions).toPromise();
  }

  async saveFilterData(pValue) {
    const x = await this.db
      .collection<any>(COLLECTIONS.SAVED_FILTERS)
      .add({ type: 'contacts', params: pValue, uid: await this.auth.getCurrentUserUid() });
    await this.db.collection<any>(COLLECTIONS.SAVED_FILTERS).doc(x.id).update({ doc_id: x.id });
    return x;
  }

  async getFilters(pType): Promise<Observable<any>> {
    const uid = await this.auth.getCurrentUserUid();
    return this.db
      .collection<any>(COLLECTIONS.SAVED_FILTERS, (ref) => ref.where('type', '==', 'contacts').where('uid', '==', uid))
      .valueChanges() as Observable<any>;
  }

  removeFilter(obj) {
    return this.db.collection<any>(COLLECTIONS.SAVED_FILTERS).doc(obj.doc_id).delete();
  }

  async mergeContact(data) {
    this.toastr.info('Merge Contact will be updated shortly', 'Batch Operations', {
      closeButton: true,
      disableTimeOut: true,
    });
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    const url = `${environment.cloud_base_url}/contactBatchMerge`;

    return this.http.post(url, data, httpOptions).toPromise();
  }

  async getFirebaseUrl(emailAttachmentArray, fromwhere?) {
    if (emailAttachmentArray && fromwhere !== 'fromDripCampaign') {
      for (const mail_attachemt of emailAttachmentArray) {
        const path = `email_attachments/${Date.now()}_${mail_attachemt.file_name}`;
        const ref = this.storage.ref(path);
        if (mail_attachemt.file) {
          this.task = this.storage.upload(path, mail_attachemt.file);
          const currUpload = await this.task.snapshotChanges().toPromise();
          const curFileUrl = await currUpload.ref.getDownloadURL();
          mail_attachemt.firebase_url = curFileUrl;
          delete mail_attachemt.file;
          delete mail_attachemt.file_base64;
        }
      }
    } else if (fromwhere === 'fromDripCampaign') {
    }
    return emailAttachmentArray;
  }

  async makePrimaryTransaction(trans_id: string, contact_id: string, contact_status: string): Promise<any> {
    try {
      const transaction = await (await this.db.collection<any>(COLLECTIONS.PARTNERS).doc(trans_id).ref.get()).data();
      if (transaction) {
        const contact_status_here = transaction.milestone;
        const update_obj = {
          status: contact_status_here ?? null,
          property_addressLine1: transaction.property_addressLine1 ?? null,
          property_addressLine2: transaction.property_addressLine2 ?? null,
          property_city: transaction.property_city ?? null,
          property_state: transaction.property_state ?? null,
          property_zip: transaction.property_zip ?? null,
          primary_transaction: trans_id,
        };

        await this.db
          .collection<any>(COLLECTIONS.CONTACTS)
          .doc(contact_id)
          .update({
            ...update_obj,
          });
        return update_obj;
      }
      return {
        msg: 'Transaction Not Fount',
      };
    } catch (error) {
      return {
        msg: error,
      };
    }
  }

  async setDoNotCall(contact_id: string, val: boolean) {
    const set = await this.db
      .collection<any>(COLLECTIONS.CONTACTS)
      .doc(contact_id)
      .update({ doNotCall: val })
      .then(() => {
        this.toastr.success(`Do Not Contact  ${val ? 'Activated' : 'Deactivated'}`);
      });
    return set;
  }

  async getcontactDetails(data) {
    if (!data || data.length === 0) {
      return { firstName: '--', lastName: '' };
    }
    return (await this.db.collection<any>(COLLECTIONS.USERS).doc(data).ref.get()).data();
  }

  sortContactSave(currentUserId: string, obj: any) {
    this.db.collection<any>(COLLECTIONS.USERS).doc(currentUserId).set(obj, { merge: true });
  }

  async addGroup(groupData: any) {
    const newDoc = await this.db.collection<any>(COLLECTIONS.GROUPS).add({ id: '' });
    groupData.id = newDoc.id;
    await this.db.collection<any>(COLLECTIONS.GROUPS).doc(newDoc.id).set(groupData, { merge: true });
  }

  getGroups(id: string) {
    const groupRef = this.db
      .collection<any>(COLLECTIONS.GROUPS, (ref) => ref.where('user_id', '==', id).orderBy('name_lower', 'asc'))
      .valueChanges();
    return groupRef;
  }

  async updateGroup(payload: any, areAllSelected: boolean, mongoParams: any, onlyMe, id) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    let { contactIds } = payload;
    if (areAllSelected) {
      contactIds = await this.http
        .post(`${api}/v0.1/contacts/fetchContactIdsForMassAction`, { value: mongoParams, onlyMe, id }, httpOptions)
        .toPromise();
      contactIds = contactIds.contactIds.map((ids) => ids.doc_id);
    }
    this.http
      .post(`${environment.cloud_base_url}/addContactsToGroup`, { ...payload, contactIds }, httpOptions)
      .subscribe(() => {
        this.toastr.clear();
        this.toastr.success('Contact(s) added to group successfully');
      });
  }

  async DeleteContactFromGroup(payload: any, areAllSelected: boolean, mongoParams: any, onlyMe, id) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    let { contactIds } = payload;
    if (areAllSelected) {
      contactIds = await this.http
        .post(`${api}/v0.1/contacts/fetchContactIdsForMassAction`, { value: mongoParams, onlyMe, id }, httpOptions)
        .toPromise();
      contactIds = contactIds.contactIds.map((ids) => ids.doc_id);
    }
    this.http
      .post(`${environment.cloud_base_url}/removeContactsFromGroup`, { ...payload, contactIds }, httpOptions)
      .subscribe(() => {
        this.toastr.clear();
        this.toastr.success('Contact(s) removed from this groups successfully');
      });
  }

  async deleteGroup(payload) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    this.http.post(`${environment.cloud_base_url}/deleteGroup`, payload, httpOptions).subscribe(() => {
      this.toastr.clear();
      this.toastr.success('Group deleted successfully');
    });
  }

  async getGroupCount(payload: any, onlyMe: boolean, uid: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };

    const data = await (
      this.http.post(
        `${api}/v0.1/contacts/getContact`,
        { value: { ...payload, group: null }, id: uid, onlyMe: !!onlyMe, groupsCount: true },
        httpOptions
      ) as Observable<{ groupCount: { _id: string; count: number }[] }>
    )
      .pipe(
        map((resp) => {
          const obsObj: { [index: string]: number } = {};
          resp.groupCount.forEach((x) => (obsObj[x._id] = x.count));

          return obsObj;
        })
      )
      .toPromise();
    return data;
  }

  async listenContactPusher() {
    return this.db
      .collection<any>(COLLECTIONS.CONTACT_PUSHER)
      .doc(await this.auth.getCurrentUserUid())
      .valueChanges();
  }

  async getAssigneeType(uid: string) {
    return (await this.db.collection<any>(COLLECTIONS.USERS).doc(uid).ref.get()).data();
  }

  async getAssignedUser(uid: any) {
    return (await this.db.collection<any>(COLLECTIONS.USERS).doc(uid).ref.get()).data();
  }

  async fixNullTransactions(contactDetail: ContactDetail) {
    const transDocs = (
      await this.db
        .collection<any>(COLLECTIONS.TRANSACTIONS)
        .ref.where('associated_contact.contact_id', '==', contactDetail.doc_id)
        .get()
    ).docs.map((doc) => doc.data());
    const loanIsNotNull = contactDetail.associated_loan_ids.filter((d) => !!d);
    if (loanIsNotNull.length === transDocs.length) {
      this.db
        .collection<any>(COLLECTIONS.CONTACTS)
        .doc(contactDetail.doc_id)
        .update({ associated_loan_ids: loanIsNotNull });
    } else {
      const missedTransaction = transDocs
        .map((doc) => doc.id)
        .find((d) => !contactDetail.associated_loan_ids.includes(d));
      const replacedList = contactDetail.associated_loan_ids.map((d) => d || missedTransaction);
      this.db
        .collection<any>(COLLECTIONS.CONTACTS)
        .doc(contactDetail.doc_id)
        .update({ associated_loan_ids: replacedList });
    }
  }
}
