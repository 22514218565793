<div id="addContact-popover" class="px-2 pb-2 addContact-popover">
  <div class="modal-header pb-0 v2-ui-integretion">
    <h5 class="pt-3 color-grey-text font-16">{{ contact ? 'EDIT' : 'ADD' }} CONTACT</h5>
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <i class="material-icons icon-color"> close </i>
    </button>
  </div>
  <dailyai-hidden-input></dailyai-hidden-input>
  <div class="modal-body name-tag v2-ui-integretion">
    <form
      [formGroup]="addContactsForm"
      class="d-flex justify-content-around align-items-center flex-wrap contactsform"
      (keydown.enter)="$event.preventDefault()"
    >
      <div class="row mx-0 px-0 col-12">
        <div class="col-6 pl-0">
          <label class="font-14 text-capitalize"> First Name*</label>
          <input
            class="form-control"
            formControlName="firstName"
            type="text"
            name="firstName"
            placeholder="First Name"
          />
          <label
            class="error error1"
            *ngIf="
              (!addContactsForm.get('firstName').valid && addContactsForm.get('firstName').touched) ||
              (!addContactsForm.get('firstName').valid && addContactsForm.get('firstName').dirty)
            "
          >
            *Invalid Contact First Name
          </label>
        </div>
        <div class="col-6 px-0">
          <label class="font-14 text-capitalize"> Last Name</label>
          <input class="form-control" formControlName="lastName" type="text" placeholder="Last Name" />
          <label
            class="error error1"
            *ngIf="
              (!addContactsForm.get('lastName').valid && addContactsForm.get('lastName').touched) ||
              (addContactsForm.get('lastName').invalid && addContactsForm.get('lastName').dirty)
            "
          >
            *Invalid Contact Last Name
          </label>
        </div>
      </div>
      <div class="row mx-0 mt-1 px-0 col-12">
        <div class="col-6 pl-0">
          <label class="font-14 text-capitalize"> Mobile Phone</label>
          <input
            class="form-control"
            [appOnlyNumber]="true"
            formControlName="phoneNumber"
            type="text"
            placeholder="9999999999"
            maxlength="10"
          />
          <label
            class="error"
            *ngIf="addContactsForm.get('phoneNumber').invalid && addContactsForm.get('phoneNumber').touched"
          >
            *Invalid Mobile Phone
          </label>
          <label
            class="error error1"
            *ngIf="
              addContactsForm.get('phoneNumber').dirty &&
              !phoneValidity &&
              addContactsForm.get('phoneNumber').value &&
              !checkingValidity
            "
          >
            *PhoneNumber Exists
          </label>
        </div>
        <div class="col-6 px-0">
          <label class="font-14 text-capitalize"> Email</label>
          <input
            autocomplete="none"
            class="form-control"
            formControlName="email"
            type="email"
            placeholder="example@mail.com"
          />
          <label
            class="error error2"
            *ngIf="addContactsForm.get('email').invalid && addContactsForm.get('email').touched"
          >
            *Invalid Mail Id
          </label>
          <label
            class="error error2 w-100"
            *ngIf="
              addContactsForm.get('email').dirty &&
              !emailValidity &&
              addContactsForm.get('email').value &&
              !checkingValidity
            "
          >
            *Mail Exists
          </label>
        </div>
      </div>
      <label
        class="error error2 w-100"
        *ngIf="
          !this.addContactsForm.get('email').value &&
          !this.addContactsForm.get('phoneNumber').value &&
          (this.addContactsForm.get('email').touched || this.addContactsForm.get('phoneNumber').touched)
        "
      >
        *Email or Mobile Phone Required
      </label>
      <div class="row mx-0 mt-1 px-0 col-12">
        <div class="col-6 pl-0">
          <label class="font-14 text-capitalize"> Address LIne 1</label>
          <input class="form-control" type="text" formControlName="addressLine1" placeholder="Address Line 1" />
        </div>
        <div class="col-6 px-0">
          <label class="font-14 text-capitalize">Address Line 2</label>
          <input class="form-control" type="text" formControlName="addressLine2" placeholder="Address Line 2" />
        </div>
      </div>
      <div class="row mx-0 mt-1 px-0 col-12">
        <div class="col-6 pl-0">
          <div class="row">
            <div class="col-6">
              <label class="font-14 text-capitalize"> City</label>
              <input class="form-control" type="text" formControlName="city" placeholder="City" />
            </div>
            <div class="col-6 pl-0">
              <label class="font-14 text-capitalize"> State</label>
              <input class="form-control" type="text" formControlName="state" placeholder="State" />
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="row">
            <div class="col-6 pl-0">
              <label class="font-14 text-capitalize"> Gender</label>
              <select class="add-select custom-select" formControlName="gender">
                <option class="disable-select" [value]="'null'" disabled selected>Gender</option>
                <option *ngFor="let gender of genders" [value]="gender">{{ gender }}</option>
              </select>
            </div>
            <div class="col-6 px-0">
              <label class="font-14 text-capitalize"> Date of Birth</label>
              <input
                class="form-control"
                type="text"
                [minDate]="{ year: today.getFullYear() - 100, month: 1, day: 1 }"
                [maxDate]="{ year: today.getFullYear(), month: today.getMonth() + 1, day: today.getDate() }"
                ngbDatepicker
                #d="ngbDatepicker"
                placeholder="Date of Birth"
                (click)="d.toggle()"
                formControlName="dateOfBirth"
                [appNoKeypress]="true"
              />
              <label
                class="error error1"
                *ngIf="!addContactsForm.get('dateOfBirth').valid && addContactsForm.get('dateOfBirth').touched"
              >
                *Invalid
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="row mx-0 mt-1 px-0 col-12">
        <div class="col-6 pl-0">
          <label class="font-14 text-capitalize">Lead Source</label>
          <select class="custom-select" formControlName="source" name="leads">
            <option value="null" [disabled]="true">Select Lead Source</option>
            <option value="partners">partners</option>
            <option [attr.value]="lead.name == 'LOS' ? 'encompass' : lead.name" *ngFor="let lead of leads">
              {{ lead.name }}
            </option>
            <option value="{{ item }}" *ngFor="let item of sourcesList$ | async">{{ item }}</option>
          </select>
        </div>
        <div class="col-6 px-0 d-flex flex-column">
          <label class="font-14 text-capitalize partner-label">Select Partner</label>
          <input
            type="button"
            class="form-control text-left input-popover-placeholder"
            [value]="partnerList.partnerName"
          />
        </div>
      </div>
      <div class="row mx-0 mt-1 px-0 col-12">
        <div class="col-6 pl-0" *ngIf="contact">
          <label class="font-14 text-capitalize">Contact Assignee*</label>
          <select
            class="custom-select"
            (change)="assignee($event)"
            formControlName="assignedto"
            [ngClass]="{
              disable: (currentUser$ | async).userType !== 'SLO' && contact?.addedBy !== (currentUser$ | async).uid
            }"
            [attr.disabled]="
              (currentUser$ | async).userType !== 'SLO' && contact?.addedBy !== (currentUser$ | async).uid ? '' : null
            "
          >
            <option value="null" disabled>Choose Contact Assignee</option>
            <option [value]="user.uid" *ngFor="let user of usersList">{{ user.firstName }} {{ user.lastName }}</option>
          </select>
          <label
            class="error error1"
            *ngIf="addContactsForm.get('assignedto').invalid && addContactsForm.get('assignedto').touched"
          >
            *Choose Assignee
          </label>
        </div>
        <div class="col-6 pl-0" *ngIf="!contact">
          <label class="font-14 text-capitalize">Contact Assignee*</label>
          <select class="custom-select" (change)="assignee($event)" formControlName="assignedto">
            <option value="null" disabled>Choose Contact Assignee</option>
            <option [value]="user.uid" *ngFor="let user of usersList">{{ user.firstName }} {{ user.lastName }}</option>
          </select>
          <label
            class="error error1"
            *ngIf="addContactsForm.get('assignedto').invalid && addContactsForm.get('assignedto').touched"
          >
            *Choose Assignee
          </label>
        </div>
        <div class="col-6 px-0" *ngIf="!contact">
          <label class="font-14 text-capitalize">Choose Drip Campaigns</label>
          <input
            type="button"
            class="form-control text-left input-popover-placeholder"
            [value]="selectvalue"
            placement="top"
            autoClose="outside"
            triggers="manual"
            #p="ngbPopover"
            (click)="p.open()"
            [ngbPopover]="popContent"
          />
          <ng-template #popContent>
            <div class="row pt-3 pb-2 px-3">
              <div class="d-flex flex-column w-100">
                <input
                  class="input-layout form-control"
                  type="text"
                  placeholder="Search"
                  formControlName="searchDrip"
                />
                <p *ngIf="dripData.length === 0" class="font-14 m-0">No Drip Campaigns</p>
                <div *ngIf="dripData.length !== 0" class="mt-2">
                  <form
                    [formGroup]="dripsForm"
                    class="heightscroll popover-box"
                    scrollable
                    (scrollPosition)="scrollHandlerDrip($event)"
                  >
                    <table class="w-100">
                      <tbody class="table-body">
                        <tr *ngFor="let drip of dripData; let i = index">
                          <td class="table-check-box">
                            <label class="v2-checkbox mb-0">
                              <input
                                type="checkbox"
                                [formControlName]="drip.drip_campaign_id"
                                (change)="dripChecked($event, drip.drip_campaign_id)"
                              />
                              <span class="checkmark"></span>
                            </label>
                          </td>
                          <td class="text-capitalize font-14 p-1">
                            {{ drip.campaign_name }} <span class="color-primary text-capitalize font-14"> (john) </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </form>
                </div>
                <div class="d-flex justify-content-end mt-4">
                  <button class="btn btn-normal mr-2" (click)="p.close(); clearSelection()">Cancel</button>
                  <button class="btn btn-primary" (click)="getDrips(); p.close()">Done</button>
                </div>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
      <div class="row mx-0 px-0 col-12">
        <div class="col-6 mt-1 pl-0" *ngIf="!contact">
          <label class="font-14 text-capitalize">Select Tags</label>
          <input
            type="button"
            class="form-control text-left input-popover-placeholder"
            [value]="selectTagvalue"
            placement="top"
            autoClose="outside"
            triggers="manual"
            #p1="ngbPopover"
            (click)="p1.open()"
            [ngbPopover]="tagPopContent"
          />
          <ng-template #tagPopContent>
            <div class="row pt-3 pb-2 px-3">
              <p *ngIf="tagsList.length === 0" class="font-14 m-0">No Tags To Assign</p>
              <div *ngIf="tagsList.length !== 0" class="d-flex flex-column w-100">
                <input
                  class="input-layout form-control"
                  type="text"
                  placeholder="Search"
                  [(ngModel)]="tagsSearch"
                  [ngModelOptions]="{ standalone: true }"
                />
                <div class="tag-pop popover-box">
                  <table class="w-100 mt-2">
                    <tbody class="table-body">
                      <tr *ngFor="let tag of tagsList; trackBy: trackByFn">
                        <td class="table-check-box">
                          <label class="v2-checkbox mb-0">
                            <input
                              type="checkbox"
                              [ngModel]="tagsSelectionCheckbox(tag)"
                              [ngModelOptions]="{ standalone: true }"
                              (change)="setTags($event, tag)"
                            />
                            <span class="checkmark"></span>
                          </label>
                        </td>
                        <td class="font-14 p-1">{{ tag }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="d-flex justify-content-end mt-4">
                  <button class="btn btn-normal mr-2" (click)="p1.close(); clearTagSelection()">Cancel</button>
                  <button class="btn btn-primary mr-2" (click)="getTags1(); p1.close()">Done</button>
                </div>
              </div>
            </div>
          </ng-template>
        </div>
        <div
          formGroupName="custom"
          class="col-6 pl-0 mt-1"
          *ngFor="let field of customFields; index as i"
          [ngClass]="{ 'pr-0': ((!contact ? 1 : 0) + i) % 2 === 1 }"
        >
          <label class="font-14 text-capitalize">{{ field.fieldName }}</label>
          <input
            *ngIf="field.fieldType === 'text'"
            type="text"
            class="form-control text-left"
            placeholder="{{ field.fieldName }}"
            formControlName="{{ field.backendName }}"
          />
          <input
            *ngIf="field.fieldType === 'date'"
            class="form-control"
            type="text"
            ngbDatepicker
            #d="ngbDatepicker"
            placeholder="{{ field.fieldName }}"
            (click)="d.toggle()"
            formControlName="{{ field.backendName }}"
            [minDate]="{ year: today.getFullYear() - 100, month: 1, day: 1 }"
            [appNoKeypress]="true"
          />
          <input
            *ngIf="field.fieldType === 'number'"
            min="0"
            type="number"
            class="form-control text-left"
            placeholder="{{ field.fieldName }}"
            formControlName="{{ field.backendName }}"
          />
        </div>
      </div>
      <div id="footer" class="justify-content-end ml-auto mb-2 mt-2 align-self-end d-flex align-items-end">
        <input type="submit" class="btn btn-normal mr-2 px-3" value="Cancel" (click)="closeModal()" />
        <input
          [disabled]="checkingValidity"
          type="button"
          class="btn btn-primary px-3 mr-2"
          value="Update"
          (click)="updateContact()"
          *ngIf="contact && !editContact"
        />
        <input
          disabled
          type="button"
          class="btn btn-primary px-3 mr-2"
          value="Updating..."
          *ngIf="contact && editContact"
        />
        <input
          type="button"
          [disabled]="checkingValidity"
          class="btn btn-primary px-3"
          value="Add"
          (click)="addContact()"
          *ngIf="!contact"
        />
      </div>
    </form>
  </div>
</div>
