import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { Store, select } from '@ngrx/store';
import { AuthService } from 'src/app/services/auth.service';
import { Observable, Subject } from 'rxjs';
import { SettingsService } from 'src/app/modules/settings/services';
import { EmailAttachmentComponent } from 'src/app/shared/components/email-attachment/email-attachment.component';
import { replaceVideoUrliframeWithAnchor, sleep } from 'src/app/shared/utils';
import { ToastrService } from 'ngx-toastr';
import { templateMergeFields , urlMergeFields } from 'src/app/shared/lib/mergefiled';
import { emailEditorConfig } from 'src/app/shared/lib/emailEditorConfig';
import { MediaAudioSelectFromComponent } from 'src/app/modules/drip-campaign/components/media-audio-select-from/media-audio-select-from.component';
import { takeUntil, take } from 'rxjs/operators';
import { MediaService } from 'src/app/modules/drip-campaign/services/getMedia.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { selectExtraMergeFields } from 'src/app/modules/settings/store/selector';
import { ValidationState } from 'src/app/shared/enums/commonEnums';
import { selectSendingEmail } from '../../store/selectors';
import { CommunicationContactSentEmail } from '../../store/actions';
import { CommunicationsState } from '../../store/state';

@Component({
  selector: 'dailyai-compose-mail',
  templateUrl: './compose-mail.component.html',
  styleUrls: ['./compose-mail.component.scss'],
})
export class ComposeMailComponent implements OnInit, OnDestroy {
  @ViewChild(EmailAttachmentComponent) EmailAttachmentComponent;

  mailForm: FormGroup;

  currentContact: any;

  public editorOptions = {
    placeholder: 'insert content...',
  };

  addForm = this.fb.group({
    title: [''],
    message: ['', Validators.required],
  });

  contactEmailLoader$: Observable<any>;

  extraMergeFields$: Observable<any>;

  currentUser: any;

  focus$ = new Subject<string>();

  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  mergeFieldEventForCC: Subject<string> = new Subject<string>();

  ccMailArray = [];

  bccMailArray = [];

  emailAttachmentsArray;

  file;

  formValid = false;

  mergeFieldList = [];

  caretPos = 0;

  mergefiledin = '';

  subjectcaretPos = 0;

  popRef;

  searchText;

  ngUnsubscribe: Subject<any> = new Subject<any>();

  embedVideoDetail: any;

  closedmediaModal = '';

  ckeConfig: any;

  @ViewChild('myckeditor') ckeditor: any;

  linkText = '';

  selectedTextForLink: any;

  mergefiledInLinkCurPosition = 0;

  emailEditorLoaded = false;

  urlMergeFieldsList: any;

  constructor(
    private modal: NgbModal,
    private fb: FormBuilder,
    private store: Store<CommunicationsState>,
    private auth: AuthService,
    private settingsService: SettingsService,
    public toastr: ToastrService,
    private mediaSvc: MediaService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.ckeConfig = emailEditorConfig;
    this.contactEmailLoader$ = this.store.pipe(select(selectSendingEmail));
    this.currentUser = this.auth.getUserData();
    this.initForm();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  close() {
    this.modal.dismissAll();
    this.mailForm.reset();
    this.emailAttachmentsArray = [];
    this.file = [];
  }

  initForm() {
    this.mailForm = this.fb.group({
      subject: ['', Validators.required],
      content: ['', Validators.required],
    });
    this.mailForm.patchValue({
      content: this.currentUser.emailSignature ? '<br><br><br><br>{{user_email_signature}}' : '',
    });
  }

  async sentMail() {
    if (this.mailForm.invalid) {
      this.mailForm.markAllAsTouched();
    } else {
      await sleep(1000);
      const InvalidBcc = this.bccMailArray.filter((mail) => mail.valid == ValidationState.INVALID);
      const InvalidCc = this.ccMailArray.filter((mail) => mail.valid == ValidationState.INVALID);
      if (InvalidBcc.length > 0 || InvalidCc.length > 0) {
        this.toastr.error('Invalid cc or bcc emails found');
        return;
      }
      this.formValid = true;
      const email_attachments_array = this.emailAttachmentsArray
        ? await this.EmailAttachmentComponent.getFirebaseUrl(this.emailAttachmentsArray)
        : [];

      let emailContent = replaceVideoUrliframeWithAnchor(this.mailForm.value.content);
      emailContent = await this.newImageHandelr(emailContent);

      const payload = {
        message: {
          to: this.currentContact.email,
          subject: this.mailForm.value.subject,
          body: emailContent,
          cc: [...new Set(this.ccMailArray.map((e) => e.email))],
          bcc: [...new Set(this.bccMailArray.map((e) => e.email))],
          email_attachments_array,
        },
        contactId: this.currentContact.doc_id,
        isMO: this.currentUser['userType'] === 'MO',
        contact_assigned_to: this.currentContact.contact_assigned_to,
      };
      const url1 = `${environment.cloud_base_url}/sendMailGmail`;
      const url2 = `${environment.cloud_base_url}/sendMailOutlook`;
      const assignedto_user = await this.auth.getUserByUid(payload.contact_assigned_to);
      const url =
        this.currentUser['userType'] === 'MO'
          ? assignedto_user['oAuthPlatform'] === 'google'
            ? url1
            : url2
          : this.currentUser['oAuthPlatform'] === 'google'
          ? url1
          : url2;
      payload['message']['body'] = payload.message.body.replace(/<p>/g, '<p style="Margin-top:0;Margin-bottom:0;">');
      this.store.dispatch(CommunicationContactSentEmail({ data: payload, url }));
      this.passEntry.emit('reset');
      this.close();
      this.bccMailArray = [];
      this.ccMailArray = [];
    }
  }

  editorOnFocus(linkPop, ck) {
    this.emailEditorLoaded = true;
    this.ckeditor = ck;

    this.ckeditor.instance.commands.link.exec = (b) => {
      this.modal.open(linkPop, {
        centered: true,
        windowClass: 'routeDripPopup',
        backdrop: 'static',
        keyboard: true,
      });
    };
    this.ckeditor.instance.commands.image.exec = async (b) => {
      this.sharedService.imageHandlerForEmail(this.ckeditor, this.currentUser.uid);
    };
    this.ckeditor.instance.addCommand('videouploadcmd', {
      exec: (edt) => {
        this.videoHandler();
      },
    });
  }

  async videoHandler() {
    const modalRef = this.modal.open(MediaAudioSelectFromComponent, {
      centered: true,
      windowClass: 'routeDripPopup',
      backdrop: 'static',
      keyboard: true,
    });
    modalRef.componentInstance.dripType = 'media';
    modalRef.componentInstance.fromWhere = 'videoEmbedInEmail';
    (modalRef.componentInstance.close as Observable<any>).pipe(takeUntil(this.ngUnsubscribe)).subscribe((x) => {
      if (x) {
        this.closedmediaModal = x;
        modalRef.close();
      }
    });
    this.mediaSvc
      .getMedia()
      .pipe(take(1))
      .subscribe(async (value) => {
        const supportedVideoArray = ['video/quicktime', 'video/mp4', 'video/webm', 'video/x-msvideo'];
        let videowithanchor = '';
        if (value) {
          this.embedVideoDetail = value;

          if (this.embedVideoDetail && this.embedVideoDetail['value']['doc_id']) {
            const vidUrl = `${environment.player_url}/player?url=${encodeURIComponent(
              this.embedVideoDetail['value']['url']
            )}&user_id=${this.currentUser.uid}`;
            videowithanchor = `<a  href="${vidUrl}"><img height="200px" class="vidCrmq" src="${this.embedVideoDetail['value']['thumbnail']}"></a>`;

            this.ckeditor.instance.ui.editor.insertHtml(videowithanchor);
          } else if (
            this.embedVideoDetail &&
            !this.embedVideoDetail['value']['doc_id'] &&
            supportedVideoArray.includes(value['value'][0]['type'])
          ) {
            const link = await this.settingsService.uploadImageEmail(
              this.embedVideoDetail['value'][0],
              this.currentUser.uid
            );
            const vidUrl = `${environment.player_url}/player?url=${encodeURIComponent(String(link))}&user_id=${
              this.currentUser.uid
            }`;

            videowithanchor = `<a href="${vidUrl}"><img height="200" src="${
              environment.player_url
            }/player/gif?url=${encodeURIComponent(
              String(link)
            )}" alt="https://firebasestorage.googleapis.com/v0/b/dailycrm-c9949.appspot.com/o/video_default_thumbnail%2Fmail-video-icon_20200704_193513175.png?alt=media&token=46d39c03-affe-478f-9458-0b1092542c71"></a>`;

            this.ckeditor.instance.ui.editor.insertHtml(videowithanchor);
          } else {
            this.toastr.warning('The selected file type not supported', 'Video embed in email');
          }
        }
      });
  }

 @Input()
  set contact(data) {
    if (data) {
      this.currentContact = data;
    }
  }

  mailAttachmentDetails(event) {
    this.emailAttachmentsArray = event;
  }

  removeAttachment(fullarray, item, i, itemIndex) {
    fullarray.splice(itemIndex, 1);
  }

  fileEvent(event) {
    this.file = event;
  }

  getChoiceLabel(choice: string, index?) {
    switch (this.mergefiledin) {
    case 'mailEditor': {
      this.ckeditor.instance.ui.editor.insertText(`{{${choice}}}`);

    break;
    }
    case 'subjectArea': {
      const message: any = this.mailForm['controls']['subject']['value']
        ? this.mailForm['controls']['subject']['value']
        : ``;

      const result: string = message
        ? `${message.slice(0, this.subjectcaretPos)  }{{${choice}}}${  message.slice(this.subjectcaretPos)}`
        : `{{${choice}}}`;
      this.subjectcaretPos += choice.length + 4;
      this.mailForm['controls']['subject'].patchValue(result);

    break;
    }
    case 'cc': {
      if (this.subjectcaretPos === 0) {
        this.mergeFieldEventForCC.next(`{{${choice}}}`);
      } else {
        this.toastr.error('Merge Fields can only be added to blank fields');
      }

    break;
    }
    default: {
      this.toastr.warning('Please focus either on  Email editor or on email subject');
    }
    }
    this.searchText = '';
    this.popRef.close();
  }

  getCaretPosition(oField, fieldname) {
    this.mergefiledin = fieldname;
    this.subjectcaretPos = oField.selectionStart;
    this.mergeFieldList = this.mergeFieldList.filter((val) => val !== 'user_email_signature');
  }

  openTagList(popover) {
    this.extraMergeFields$ = this.store.pipe(takeUntil(this.ngUnsubscribe), select(selectExtraMergeFields));
    this.extraMergeFields$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((fieldList) => {
      this.mergeFieldList = [...templateMergeFields, ...fieldList];
      this.urlMergeFieldsList = [...urlMergeFields, ...fieldList];
    });
    if (this.mergefiledin === 'mailEditor') {
      this.mergeFieldList = [...this.mergeFieldList, 'user_email_signature'];
    }
    this.popRef = popover;
    if (popover.isOpen()) {
      this.searchText = '';
      popover.close();
    } else {
      this.searchText = '';
      popover.open();
    }
  }

  async newImageHandelr(emailContent) {
    const imgpattern = new RegExp(`<img style="height:100" src="(.*?)" alt="(.*?)">`, 'g');
    const imgpatternfromSystem = new RegExp(`<img height="100" src="(.*?)">`, 'g');
    const imgpatternfromSystem2 = new RegExp(`<img src="(.*?)">`, 'g');
    const imgpatternfromSystem3 = new RegExp(`<img src="(.*?)" height="100">`, 'g');
    const srcpattern = new RegExp(`src="(.*?)"`, 'g');
    let matches = [...emailContent.matchAll(imgpattern)];
    matches = [
      ...emailContent.matchAll(imgpatternfromSystem),
      ...emailContent.matchAll(imgpatternfromSystem2),
      ...emailContent.matchAll(imgpatternfromSystem3),
    ];
    for (const [i, item] of matches.entries()) {
      const imageurl = item[0];
      const imgsrc = [...imageurl.matchAll(srcpattern)];
      if (!imgsrc[0][1].split(',')[0].includes('base64')) {
        continue;
      } else {
        const link = await this.settingsService.uploadImageEmailNew(imgsrc[0][1], this.currentUser.uid);
        const atagcontent = item[0].replace(imgsrc[0][1], link);
        emailContent = emailContent.replace(item[0], atagcontent);
      }
    }
    return emailContent;
  }

  showEmojiPicker = false;

  sets = ['native', 'google', 'twitter', 'facebook', 'emojione', 'apple', 'messenger'];

  set = 'google';

  toggleEmojiPicker() {
    this.showEmojiPicker = !this.showEmojiPicker;
  }

  addEmojiMail(event) {
    this.ckeditor.instance.ui.editor.insertText(event.emoji.native);
  }

  onFocus() {
    this.showEmojiPicker = false;
  }

  onClickedOutside(event) {
    if (event.target.nodeName !== 'SPAN') {
      this.showEmojiPicker = false;
    }
  }

  onClick(event: any): void {
    this.mergefiledin = 'mailEditor';
    this.mergeFieldList = [...templateMergeFields, 'user_email_signature'];
    if (this.ckeditor.instance.ui.editor.getSelection()) {
      const a = this.ckeditor.instance.ui.editor.getSelection().getNative();
      this.selectedTextForLink =
        a && a.anchorNode.data
          ? a.baseOffset < a.extentOffset
            ? a.anchorNode.data.slice(a.baseOffset, a.extentOffset)
            : a.anchorNode.data.slice(a.extentOffset, a.baseOffset)
          : '';
    }
  }

  setCurserPostionAndInput(position) {
    this.mergefiledInLinkCurPosition = position;
  }

  linkSet(item) {
    this.linkText = `${this.linkText.slice(0, this.mergefiledInLinkCurPosition)}{{${item}}}${this.linkText.slice(
      this.mergefiledInLinkCurPosition
    )}`;
    this.mergefiledInLinkCurPosition = this.mergefiledInLinkCurPosition + item.length + 4;
    this.popRef.close();
  }

  addLink(modal) {
    if (this.linkText && this.selectedTextForLink) {
      this.ckeditor.instance.ui.editor.insertHtml(`<a href="${this.linkText}">${this.selectedTextForLink}</a>`);
      this.linkText = '';
      this.selectedTextForLink = '';
      modal.close();
    }
  }

  cancelLinkModal(modal) {
    this.linkText = '';
    this.selectedTextForLink = '';
    modal.close();
  }

  ccMergeFieldSetter(event) {
    this.mergefiledin = event?.type;
    this.subjectcaretPos = 0;
  }

  recipientSetter(data) {
    this.ccMailArray = data.cc ?? [];
    this.bccMailArray = data.bcc ?? [];
  }
}
