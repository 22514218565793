<div class="v2-ui-integretion">
  <div class="mt-2 d-flex flex-wrap">
    <div class="d-flex align-items-center attach-files mr-2" *ngFor="let item of emailAttachmentArray">
      <span class="px-2 py-1 file-name font-14 truncate">{{ item['file_name'] }}</span>
      <span class="material-icons mx-1 font-16 cursor-pointer" (click)="removeAttachment(item)"> close</span>
    </div>
  </div>
  <span *ngIf="sizeError && userData['oAuthPlatform']=='outlook'" class="error">* file size limit for outlook integrated
    account is 3 Mb</span>
  <span *ngIf="gmailsizeError && userData['oAuthPlatform']=='google'" class="error">* file size limit for gmail
    integrated
    account is 25 Mb</span>
</div>
