import { Injectable } from '@angular/core';
import { Team } from 'src/app/models/teams.model';
import { AngularFirestore } from '@angular/fire/firestore';
import { map, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/services/auth.service';
import { ConfirmationComponent } from 'src/app/shared/components/confirmation/confirmation.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

const api = environment.mongoUrl;
const {cloud_base_url} = environment;

@Injectable({
  providedIn: 'root',
})
export class TeamsService {
  constructor(
    private db: AngularFirestore,
    private http: HttpClient,
    private auth: AuthService,
    private modalService: NgbModal,
    private toast: ToastrService
  ) {}

  checkIfValidTeamName(name: string, id: string) {
    return this.db
      .collection<any>('teams', (ref) =>
        ref.where('team-nocase', '==', name.toLowerCase()).where('viewersList', 'array-contains', id).limit(1)
      )
      .get()
      .pipe(map((d) => d.size));
  }

  async initTeam(payload) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    const resp = await (
      this.http.post(`${cloud_base_url}/createTeam`, payload, httpOptions) as Observable<{
        teamId: string;
      }>
    ).toPromise();
    return resp.teamId;
  }

  async updateTeam(payload) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    return (
      this.http.post(`${cloud_base_url}/updateTeam`, payload, httpOptions) as Observable<{
        teamId: string;
      }>
    ).toPromise();
  }

  async updateTlPermissionOrTeamName(payload) {
    await this.db
      .collection<any>('teams')
      .doc(payload.teamId)
      .update({
        ...payload,
      });
    this.toast.success('Team Name saved successfully');
  }

  async sendInvitation(payload) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    const resp = await (
      this.http.post(`${cloud_base_url}/add_user_enterprise`, payload, httpOptions) as Observable<{
        data: { userId: string; userName: string; userType: string };
      }>
    ).toPromise();
    return resp.data;
  }

  getTeamData(teamId: string) {
    return this.db.collection<any>('teams').doc(teamId).valueChanges() as Observable<Team>;
  }

  async getUsersList(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    return (
      this.http.post(`${api}/v0.1/users/getEnterpriseUsers`, data, httpOptions) as Observable<{
        count: number;
        list: any[];
      }>
    ).toPromise();
  }

  async updateRefill(uid, refillPaidBy) {
    await this.db.collection<any>('users').doc(uid).update({ refillPaidBy });
  }

  async getTeamsList(payload) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    return (
      this.http.post(`${api}/v0.1/teams/listTeams`, payload, httpOptions) as Observable<{
        count: number;
        data: any[];
      }>
    ).toPromise();
  }

  async getAccessForUser(userId: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    (
      this.http.post(`${cloud_base_url}/getUserTokenForAdmin`, { userId }, httpOptions) as Observable<{
        err: boolean;
        msg: string;
        data: {
          token_id: string;
        };
      }>
    )
      .pipe(
        switchMap((val) => {
          this.auth.signout();
          window.open(`${environment.app_url}/login?tokenId=${userId}`, '_blank');
          this.modalService.dismissAll();
          return of(val);
        })
      )
      .subscribe();
  }

  giveAccessModalOpen(userId: string) {
    const confirmRef = this.modalService.open(ConfirmationComponent, {
      centered: true,
      size: 'sm',
      backdrop: 'static',
      keyboard: false,
    });
    confirmRef.componentInstance.message =
      'Do you want to access this user account? (Current session will be signed out)';
    confirmRef.result.then((result) =>
      result === 'submit' ? this.getAccessForUser(userId) : this.modalService.dismissAll()
    );
  }

  async resendInvitation(email: string, uid: string) {
    this.toast.info('Sending Mail...', '', {
      timeOut: 5000,
      progressAnimation: 'increasing',
      progressBar: true,
    });

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };

    (
      this.http.post(
        `${cloud_base_url}/resend_mail_enterprise`,
        { uid, email },
        httpOptions
      ) as Observable<{
        error: boolean;
        msg: string;
      }>
    ).subscribe(() => {
      this.toast.clear();
      this.toast.success(`Invitation send to ${email}`);
    });
  }

  async getUsersTeamNameAndAddedBy(payload: { data: any[] }) {
    const teamName = [],
      AddedBy = [];
    let teamNamesAndAddedBy = [];

    for (const [i, user] of payload.data.entries()) {
      AddedBy[i] = this.db
        .collection<any>('users', (ref) => ref.where('uid', '==', user.createdBy))
        .get()
        .pipe(map((qSnap) => `${qSnap.docs[0]?.get('firstName')} ${qSnap.docs[0]?.get('lastName')}`))
        .toPromise();
      if (user.userType !== 'manager' && user.teamId) {
        teamName[i] = this.db
          .collection<any>('teams', (ref) => ref.where('teamId', '==', user.teamId))
          .get()
          .pipe(map((qSnap) => qSnap.docs[0]?.get('teamName')))
          .toPromise();
      }
    }

    try {
      const result = await Promise.all([...AddedBy, ...teamName]);
      teamNamesAndAddedBy = AddedBy.map((user, i) => ({
        createdByName: result[i],
        teamName: result[i + AddedBy.length],
      }));
      return teamNamesAndAddedBy;
    } catch (error) {
      return [];
    }
  }

  async getAvailableTeamListForAgent(agentId: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
      }),
    };
    return (
      this.http.post(`${environment.mongoUrl}/v0.1/teams/eligibleTeamList`, { agentId }, httpOptions) as Observable<{
        data: {
          teamId: string;
          teamName: string;
          teamLeader: {
            userId: string;
            userName: string;
            userType: string;
          };
        }[];
      }>
    ).pipe(map((val) => val.data));
  }

  async updateAgentTeamDetails(agent, newTeam, makeSelectedTeamDefault) {
    try {
      const agentRef = await this.db.collection<any>('users').doc(agent.uid).ref.get();
      let updateBody: any = {};
      if (agentRef.exists) {
        const agentRefData = agentRef.data();
        if (!agentRefData.teamIdList) {
          agentRefData['teamIdList'] = [];
        }
        const teamIdList = [...agentRefData.teamIdList, newTeam.teamId];
        if (!agentRefData.teamLeaderIdList) {
          agentRefData['teamLeaderIdList'] = [];
        }
        const teamLeaderIdList = newTeam.teamLeader
          ? [...agentRefData.teamLeaderIdList, newTeam.teamLeader.userId]
          : [...agentRefData.teamLeaderIdList];
        const teamLeader = newTeam.teamLeader ?? null;
        if (!agentRefData.teamList) {
          agentRefData['teamList'] = [];
        }
        const teamList = [...agentRefData.teamList, { teamId: newTeam.teamId, teamName: newTeam.teamName, teamLeader }];
        // TODO: only working when agents are added by managers, must need to change in the feature.
        const managerId = await this.auth.getCurrentUserUid(); 
        updateBody = {
          teamIdList,
          teamLeaderIdList,
          teamList,
          managerId,
        };

        if (teamList.length > 1) {
          updateBody.transactionLayout = 'list';
        }

        if (!agentRefData.teamId || makeSelectedTeamDefault) {
          const {teamId} = newTeam;
          const teamLeaderId = newTeam?.teamLeader?.userId ?? null;
          const {teamName} = newTeam;
          updateBody = {
            ...updateBody,
            teamId,
            teamLeaderId,
            teamName,
          };
        }

        const teamsRef = await this.db.collection<any>('teams').doc(newTeam.teamId).ref.get();
        if (teamsRef.exists) {
          const jloArray = [
            ...teamsRef.data().jlos,
            {
              userId: agentRefData.uid,
              userName: `${agentRefData.firstName  } ${  agentRefData.lastName}`,
              userType: 'JLO',
            },
          ];
          let memberCount = jloArray.length;
          if (teamLeader) {
            memberCount++;
          }
        }
      }

      await this.db.collection<any>("users").doc(agent.uid).ref.update(updateBody);
      return true;

    } catch (error) {
      return false;
    }
  }

  async updateAgentDefaultTeam(agent, defaultTeam) {
    try {
      const agentRef = await this.db.collection<any>('users').doc(agent.uid).ref.get();
      let updateBody: any = {};
      if (agentRef.exists) {
        const {teamId} = defaultTeam;
        const teamLeaderId = defaultTeam?.teamLeader?.userId ?? null;
        const {teamName} = defaultTeam;
        updateBody = {
          teamId,
          teamLeaderId,
          teamName,
        };
        await agentRef.ref.update(updateBody);
        this.toast.success('Default team saved successfully');
      }
      return updateBody;
    } catch {
      this.toast.error('Error occurred while updating');
      return [];
    }
  }

  async updateAgentPrivilegedStatus(agent, privileged_jlo) {
    try {
      let toast;
      toast = privileged_jlo ? 'Agent made privileged successfully' : 'Privileged agent made normal agent successfully';
      await this.db.collection<any>('users').doc(agent.uid).update({ privileged_jlo });
      this.toast.success(toast);
      return true;
    } catch {
      this.toast.error('Error occurred while updating');
      return false;
    }
  }

  async deleteUsers(user) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    return this.http
      .post(
        `${cloud_base_url}/enqueueAccountDeletion`,
        {
          userId: user.uid,
        },
        httpOptions
      )
      .toPromise();
  }

  async deleteTeam(team) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    const payload = { teamId: team.teamId };
    return (
      this.http.post(`${cloud_base_url}/deleteEmptyTeam`, payload, httpOptions)
    ).toPromise();
  }
}
