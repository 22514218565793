import { createAction, props } from '@ngrx/store';

export const ListCommunicationContacts = createAction(
  '[Communication Page] List Communication Contacts',
  props<{ id: string; unread?: boolean; payload: any }>()
);
export const ListCommunicationContactsSuccess = createAction(
  '[Communication Page] List Communication Contacts Success',
  props<{ data: any }>()
);
export const ListCommunicationContactsError = createAction(
  '[Communication Page] List Communication Contacts Error',
  props<{ msg: string }>()
);

export const ListMyConversationCommunicationContacts = createAction(
  '[Communication Page] List My Conversation Communication Contacts',
  props<{ searchTerm?: string; unread?: string | null; contactType?: string; curPage?: number }>()
);
export const ListMyConversationCommunicationContactsSuccess = createAction(
  '[Communication Page] List  My Conversation Communication Contacts Success',
  props<{ data: any }>()
);
export const ListMyConversationCommunicationContactsError = createAction(
  '[Communication Page] List  My Conversation Communication Contacts Error',
  props<{ msg: string }>()
);

export const ResetCommunicationContacts = createAction('[Communication Page] Reset Communication contacts');

export const ListCommunicationContactsSmsLogs = createAction(
  '[Communication Page] List Communication Contacts Sms Logs',
  props<{ id: string }>()
);
export const ListCommunicationContactsSmsLogsSuccess = createAction(
  '[Communication Page] List Communication Contacts Sms Logs Success',
  props<{ data: any }>()
);
export const ListCommunicationContactsSmsLogsError = createAction(
  '[Communication Page] List Communication Contacts Sms Logs Error',
  props<{ msg: string }>()
);

export const ResetSmsLogs = createAction('[Communication Page] Reset Sms Logs');

export const ListCommunicationContactsEmailLogs = createAction(
  '[Communication Page] List Communication Contacts Email Logs',
  props<{ id: string }>()
);
export const ListCommunicationContactsEmailLogsSuccess = createAction(
  '[Communication Page] List Communication Contacts Email Logs Success',
  props<{ data: any }>()
);
export const ListCommunicationContactsEmailLogsError = createAction(
  '[Communication Page] List Communication Contacts Email Logs Error',
  props<{ msg: string }>()
);

export const ResetEmailLogs = createAction('[Communication Page] Reset Email Logs');

export const CommunicationContactSentSms = createAction('[Communication page] Sent Sms ', props<{ data: any }>());
export const CommunicationContactSentSmsSuccess = createAction('[Communication Page] Sent Sms Success');
export const CommunicationContactSentSmsError = createAction(
  '[Communication Page] Sent Sms Error',
  props<{ msg: string }>()
);

export const CommunicationContactSentEmail = createAction(
  '[Communication page] Sent Email ',
  props<{ data: any; url: string }>()
);
export const CommunicationContactSentEmailSuccess = createAction('[Communication Page] Sent Email Success');
export const CommunicationContactSentEmailError = createAction(
  '[Communication Page] Sent Email Error',
  props<{ msg: string }>()
);

export const SelectedCommunicationContact = createAction(
  '[Communication Page] Selected Contact',
  props<{ data: any }>()
);
export const GetCurrentContactData = createAction('[Cummuncation Page] get current conatct', props<{ id: string }>());
