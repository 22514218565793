import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/services/auth.service';
import { Plans } from 'src/app/models/choose-plan.model';
import * as moment from 'moment';
import { SettingsService } from 'src/app/modules/settings/services';
import { timeZonesMap , sleep } from '../utils';
import { UPGRADE_PLAN_URL, PLAN_NAMES, DISCOUNT, VALIDATE_USER_PHONE_NUMBER } from '../config';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  isSideNavOpen = false;

  isCardsAdded = false;

  sideNavStat = new BehaviorSubject<boolean>(false);

  settingNavStat = new BehaviorSubject<boolean>(false);

  cardAddStatus = new BehaviorSubject<boolean>(false);

  isSettingNavOpen = false;

  isMilestonesBlocked = true;

  openSideNav(option?: { stat: boolean }) {
    this.isSideNavOpen = option ? option.stat : !this.isSideNavOpen;
    this.sideNavStat.next(this.isSideNavOpen);
    this.openSettingNav({stat: false});
    if (this.isSideNavOpen) {
      sleep(500).then((_) => {
        window.addEventListener('click', this.clickListener);
      });
    } else {
      window.removeEventListener('click', this.clickListener);
    }
  }

  openSettingNav(option?: { stat: boolean }) {
    this.isSettingNavOpen = option ? option.stat : true;
    this.settingNavStat.next(this.isSettingNavOpen);
    if (this.isSettingNavOpen) {
      sleep(500).then((_) => {
        window.addEventListener('click', this.clickListener);
      });
    } else {
      window.removeEventListener('click', this.clickListener);
    }
  }

  getSideNavStat() {
    return this.sideNavStat.asObservable();
  }

  getSettingNavStat() {
    return this.settingNavStat.asObservable();
  }

  setMilestonesSetting(blocked: boolean) {
    this.isMilestonesBlocked = blocked;
  }

  addCardLayout(option?: { stat: boolean }) {
    this.cardAddStatus.next(option.stat);
  }

  getaddCardLayoutStat() {
    return this.cardAddStatus.asObservable();
  }

  clickListener = (eve: any) => {
    if (
      document.getElementsByClassName('side-nav-mobile')[0] &&
      (!document.getElementsByClassName('side-nav-mobile')[0].contains(eve.target) ||
        document.getElementsByClassName('menu-box')[0].contains(eve.target) ||
        document.getElementsByClassName('menu-box')[1]?.contains(eve.target))
    ) {
      this.isSideNavOpen ? this.openSideNav({ stat: false }) : this.openSettingNav({ stat: false });
    }
  }

  constructor(
    private toastr: ToastrService,
    private db: AngularFirestore,
    private router: Router,
    private auth: AuthService,
    private http: HttpClient,
    private settingsService: SettingsService
  ) {}

  imageHandler(quill) {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();
    const currEditor = quill;
    input.addEventListener('change', async () => {
      const file = input.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      const supportedFiles = ['image/jpeg', 'image/gif', 'image/png'];
      const type = file['type'];
      if (supportedFiles.includes(type)) {
        reader.addEventListener('load', () => {
          const range = currEditor.getSelection(true);
          currEditor.insertEmbed(range.index, 'image', reader.result);
          currEditor.root.innerHTML = currEditor.root.innerHTML.replace(/<img/g, '<img height="100"');
          return currEditor.root.innerHTML;
        });
      } else {
        this.toastr.warning('The selected file type not supported', 'image in email');
      }
    });
  }

  async imageHandlerForEmail(ckeditor, id) {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();
    input.addEventListener('change', async () => {
      const file = await input.files[0];
      const reader = await new FileReader();
      reader.readAsDataURL(file);
      const supportedFiles = ['image/jpeg', 'image/gif', 'image/png'];
      const type = file['type'];
      if (supportedFiles.includes(type)) {
        reader.addEventListener('load', async () => {
          const link = await this.settingsService.uploadImageEmailNew(reader.result, id);
          ckeditor.instance.ui.editor.insertHtml(` <img alt="" src="${link}"
           height="200">`);
        });
      } else {
        this.toastr.warning('The selected file type not supported', 'image in email');
      }
    });
  }

  listenPusherShared(uid: string, collection: string) {
    return this.db.collection<any>(`${collection}-pusher`).doc(uid).valueChanges();
  }

  async selectPlan(payload: {
    uid: string;
    data: {
      discountId: string;
      senior_planId: string;
      planName: string;
      quantity: number;
      isSmallEnterprise: boolean;
      slo_first_subscription_amount: number;
      amount: number;
      max: number;
    };
  }) {
    try {
      await this.db
        .collection<any>('users')
        .doc(payload.uid)
        .update({ ...payload.data });
      await this.db
        .collection<any>('users')
        .doc(payload.uid)
        .update({
          onboarding: {
            status: 'incomplete',
            state: 1,
          },
        });
      this.router.navigate(['/onboarding/payment']);
    } catch (error) {
      return true;
    }
  }

  async upgradePlan(payload: {
    uid: string;
    data: {
      senior_planId: string;
      planName: string;
      quantity: number;
      amount: number;
      max: number;
    };
  }) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    return this.http.post(UPGRADE_PLAN_URL, { ...payload.data, uid: payload.uid }, httpOptions).toPromise();
  }

  formatUpgradeResponse(str: string) {
    const planName: string = str.slice(str.lastIndexOf(' ') + 1, str.length);
    return `${str.split('plan')[0] + PLAN_NAMES[planName]  } plan`;
  }

  toUpgradePlans(plans: Plans[], planName: string) {
    if (planName) {
      return plans.slice(plans.indexOf(plans.find((plan) => plan.nickname === planName)) + 1);
    }
    return plans;
  }

  initialChargeJlo(user: any, amount: number) {
    const SLO = user;
    const amount_in_dollar = amount * 100;
    let daysCalculated = this.calculateDays(SLO.CurrentPeriodEnd, SLO);
    const daysInMonth_now = moment().daysInMonth();
    const priceperDay = amount_in_dollar / daysInMonth_now;
    daysCalculated = daysCalculated + 1;
    let netamount = daysCalculated * priceperDay;
    const today = moment().tz(timeZonesMap[SLO.timezone]) ? moment().tz(timeZonesMap[SLO.timezone]).toISOString() : '';
    if (SLO.discount_expiry_date && SLO.discountId && moment(today).isBefore(SLO.discount_expiry_date)) {
        netamount = this.calculateDiscount(netamount, SLO.discountId);
      }
    netamount = Math.round(netamount);
    return netamount / 100;
  }

  calculateDiscount(total: number, discount: string) {
    let discounted_amount = 0;
    const difference_amount = total * (DISCOUNT[discount] / 100);
    discounted_amount = total - difference_amount;
    return discounted_amount;
  }

  calculateDays(required_date_epoch: number, user: any) {
    let noofDays = 0;
    let date4;
    date4 = moment.unix(required_date_epoch).format('DD/MM/YYYY');
    const dateofvisit = moment(date4, 'DD-MM-YYYY');
    const today1 = +moment().tz(timeZonesMap[user.timezone]).hour(9).minute(30);
    noofDays = dateofvisit.diff(today1, 'days');
    return noofDays;
  }

  customgenerateDateWithTimeZone(
    {
      day = null,
      month = null,
      year = null,
      timezone,
    }: {
      day: number;
      month: number;
      year: number;
      timezone: string;
    },
    end = false
  ) {
    if (day === null || month === null || year === null) {
      return null;
    }
    const dateIso = moment
      .tz(timeZonesMap[timezone])
      .date(day)
      .month(month - 1)
      .year(year)
      .hour(end ? 23 : 0)
      .minute(end ? 59 : 1)
      .toISOString();
    return dateIso;
  }

  generateNbgDateStructFromISO(ISOString: string = null) {
    if (!ISOString) {
      return null;
    }
    return {
      day: moment(ISOString).date(),
      month: moment(ISOString).month() + 1,
      year: moment(ISOString).year(),
    };
  }

  async newAmountPayable(user: any): Promise<number> {
    const plan: any = (await this.db.collection<any>('plans').doc('unlimited_dialler').ref.get()).data();
    const today = moment().tz(timeZonesMap[user.superAdminTimezone]).hour(9).minute(30).toISOString();
    const days_in_month: number = moment().daysInMonth();
    const no_of_days_remaining = moment(user.billingPeriodTo).diff(today, 'days');

    let total_amount_payable: number = no_of_days_remaining * (plan.amount / days_in_month);
    total_amount_payable = Math.round(total_amount_payable) / 100;
    return total_amount_payable;
  }

  async getCsvMappings(uid: string, forWho: string) {
    if (!uid) {
      return null;
    }
    const ref = await this.db.collection<any>('csv-templates').doc(uid).ref.get();
    if (!ref.exists) {
      return null;
    }
    return ref.data()[forWho];
  }

  async updateCsvMappings(uid: string, mapData: any, forWho: string) {
    if (!uid) {
      return null;
    }
    const ref = await this.db.collection<any>('csv-templates').doc(uid).ref.get();
    if (!ref.exists) {
      const upObject = {
        contact: null,
        transaction: null,
        partner: null,
      };
      upObject[forWho] = mapData;
      return this.db
        .collection<any>('csv-templates')
        .doc(uid)
        .set({ ...upObject });
    }
    const upData = {};
    Object.entries(mapData).forEach(([key, value]) => {
      upData[`${forWho}.${key}`] = value;
    });
    return this.db.collection<any>('csv-templates').doc(uid).update(upData);
  }

  async getContactDataById(doc_id: string) {
    return (await this.db.collection<any>('contacts').doc(doc_id).ref.get()).data();
  }

  getFeatureFlag(feature: string) {
    return this.db.collection<any>('feature-flags').doc(feature).valueChanges();
  }

  public changeUserName(uid: string) {
    return (!uid || uid === '') ? null : this.db.collection('name_change').doc(uid).valueChanges();
  }

  public changeCompanyName(uid: string) {
    return (!uid || uid === '') ? null : this.db.collection('company_change').doc(uid).valueChanges();
  }

  public changeProfileImage(uid: string) {
    return (!uid || uid === '') ? null : this.db.collection('profile_image_change').doc(uid).valueChanges();
  }

  public changeUserEmail(uid: string) {
    return (!uid || uid === '') ? null : this.db.collection('email_change').doc(uid).valueChanges();
  }

  public changeInTwilioNumber(uid: string) {
    return (!uid || uid === '') ? null : this.db.collection('twilio_number_change').doc(uid).valueChanges();
  }

  public changeUserPhone(uid: string) {
    return (!uid || uid === '') ? null : this.db.collection('phone_number_change').doc(uid).valueChanges();
  }

  async getValidationCode(payload){
    const jwtoken = await this.auth.getUserToken();
    const headers = new HttpHeaders({
      Authorization: `Bearer ${jwtoken}`,
    });
    return this.http.post(VALIDATE_USER_PHONE_NUMBER, payload, { headers });
  }
}
