
import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Store, select } from '@ngrx/store';
import { ContactsState } from 'src/app/modules/contacts/store/state';
import { selectCurrentUser } from 'src/app/modules/login/store/selectors';

@Component({
  selector: 'dailyai-email-attachment',
  templateUrl: './email-attachment.component.html',
  styleUrls: ['./email-attachment.component.scss'],
})
export class EmailAttachmentComponent implements OnInit, OnDestroy {
  @Output() mailAttachmentArray = new EventEmitter();

  @Input() upload?: boolean = false;

  emailAttachmentArray = [];

  currentUser$: Observable<any>;

  percentage: Observable<number>;

  snapshot: Observable<any>;

  downloadURL;

  task: AngularFireUploadTask;

  sizey = 0;

  sizeError = false;

  gmailsizeError = false;

  userData;

  ngUnsubscribe: Subject<any> = new Subject<any>();

  closedModal = false;

  templateEmailAttachmentsArray = [];

  constructor(
    private readonly storage: AngularFireStorage,
    private toastr: ToastrService,
    private store: Store<ContactsState>
  ) {}

  ngOnInit(): void {
    this.currentUser$ = this.store.pipe(select(selectCurrentUser), takeUntil(this.ngUnsubscribe));
    this.currentUser$.subscribe(async (data) => {
      if (data) {
        this.userData = data;
      }
    });
  }

  fileEvent(event) {
    const nameExists = this.emailAttachmentArray.filter((e) => e.file_name === event.target.files[0].name);
    const nameExistsInTemplates = this.templateEmailAttachmentsArray.filter(
      (e) => e.file_name === event.target.files[0].name
    );

    if (nameExists && nameExists.length === 0 && nameExistsInTemplates && nameExistsInTemplates.length === 0) {
      const file: File = event && event.target && event.target.files ? event.target.files[0] : {};
      this.sizey += file['size'];
      if (
        this.userData &&
        this.userData['oAuthPlatform'] &&
        this.userData['oAuthPlatform'] == 'outlook' &&
        (file['size'] > 3000000 || this.sizey > 3000000)
      ) {
        this.sizey -= file['size'];
        this.sizeError = true;
      } else if (
        this.userData &&
        this.userData['oAuthPlatform'] &&
        this.userData['oAuthPlatform'] == 'google' &&
        (file['size'] > 25000000 || this.sizey > 25000000)
      ) {
        this.sizey -= file['size'];
        this.gmailsizeError = true;
      } else {
        const reader = new FileReader();
        if (event && event.target && event.target.files) {
          reader.readAsDataURL(file);
        }
        reader.addEventListener('load', () => {
          const fileDetails = {
            file_name: event.target.files[0].name,
            file_base64: reader.result,
            file_type: event.target.files[0].type,
            file,
          };
          this.emailAttachmentArray.push(fileDetails);
        });
        this.mailAttachmentArray.emit(this.emailAttachmentArray);
      }
    }
  }

  removeAttachment(attachment) {
    this.emailAttachmentArray = this.emailAttachmentArray.filter((item) => item.file_name !== attachment.file_name);
    this.mailAttachmentArray.emit(this.emailAttachmentArray);
  }

  async getFirebaseUrl(emailAttachmentArray, fromwhere?) {
    if (emailAttachmentArray && fromwhere !== 'fromDripCampaign') {
      for (const mail_attachemt of emailAttachmentArray) {
        const path = `email_attachments/${Date.now()}_${mail_attachemt.file_name}`;
        const ref = this.storage.ref(path);
        if (mail_attachemt['file']) {
          this.task = this.storage.upload(path, mail_attachemt['file']);
          const currUpload = await this.task.snapshotChanges().toPromise();
          const curFileUrl = await currUpload.ref.getDownloadURL();
          mail_attachemt['firebase_url'] = curFileUrl;
          delete mail_attachemt.file;
          delete mail_attachemt.file_base64;
        }
      }
    }
    return emailAttachmentArray;
  }

  deleteFromFirebaseStorage(firebaseUrl) {
    return this.storage.storage.refFromURL(firebaseUrl).delete();
  }

  @Input()
  set file(value) {
    if (value) {
      this.fileEvent(value);
    }
  }

  @Input()
  set templatesEmailAttachments(value) {
    if (value) {
      this.templateEmailAttachmentsArray = value;
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
