<div class="px-0 pb-2 v2-ui-integretion">
  <div class="modal-header pt-2 pb-2 px-4">
    <h6 class="pt-4 text-capitalize font-18 font-weight-normal">ADD CONTACT TO CAMPAIGNS</h6>
    <button type="button" class="close" aria-label="Close" (click)="closeTemplate()">
      <i class="material-icons icon-color"> close </i>
    </button>
  </div>
  <div class="modal-body pt-0">
    <div>
      <div class="d-flex flex-column justify-content-between align-items-center body-layout m-0">
        <div class="table-layout popover-tag">
          <div class="input-group">
            <input
              class="input-layout form-control"
              type="text"
              placeholder="Search"
              [(ngModel)]="demandSearchText"
              (ngModelChange)="demandSearch$.next($event)"
              [ngModelOptions]="{ standalone: true }"
            />
            <div class="input-group-prepend">
              <span class="input-group-text material-icons pr-0 mr-2 cursor-pointer" style="bottom: 1px">search</span>
            </div>
          </div>
          <table class="table table-hover border">
            <thead>
              <tr class="">
                <th class="w-40px">
                </th>
                <th><span class="">Campaign Name</span></th>
                <th><span class="text-uppercase">Status</span></th>
                <th><span class="text-uppercase">Created By</span></th>
              </tr>
            </thead>
            <p
              *ngIf="!toAddtoDrip.length"
              class="table-body text-black-50 font-weight-bold note-center d-flex align-items-center justify-content-center my-auto"
            >
              <span class="font-14 d-flex justify-content-center">No Drip Campaigns To Add</span>
            </p>
            <tbody *ngIf="toAddtoDrip.length" scrollable (scrollPosition)="scrollDrip($event)" style="height: 450px">
              <tr class="list-tr pointer" *ngFor="let drip of toAddtoDrip; let i = index" (click)="radioClicked(drip)">
                <td class="w-40px">
                  <input
                    class="drip-add-contact"
                    type="radio"
                    (click)="radioClicked(drip)"
                    [checked]="drip.id === model.option"
                  />
                </td>
                <td>
                  <span
                    class="truncate"
                    placement="top"
                    ngbTooltip=" {{ drip.name }}"
                    tooltipClass="tooltip-page-class"
                  >
                    {{ drip?.name }}</span
                  >
                </td>
                <td>
                  <span class="font-12 active">{{
                    drip?.status == 'Expired' && drip?.active_contacts_count == 0 ? 'Active' : drip.status
                  }}</span>
                </td>
                <td>
                  <span
                    class="truncate text-capitalize"
                    placement="top"
                    ngbTooltip=" {{ drip?.added_by_name }}"
                    tooltipClass="tooltip-page-class"
                  >
                    {{ drip?.added_by_name }}</span
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="d-flex justify-content-end btns mt-4">
        <button class="btn btn-normal mr-2 w-25 drip-cancel-btn" (click)="closeTemplate()">Cancel</button>
        <button (click)="addCampaign()" class="btn btn-primary w-25 drip-add-btn">Add</button>
      </div>
    </div>
  </div>
</div>
