import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, from, merge, Observable } from 'rxjs';
import { map, mergeMap, catchError, switchMap, tap, withLatestFrom, takeUntil, delay } from 'rxjs/operators';
import { AppService } from 'src/app/shared/app.service';
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngrx/store';
import { State } from 'src/app/reducers';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SubscriptionService } from 'src/app/services/subscriptions.service';
import { TypedAction } from '@ngrx/store/src/models';
import * as PartnerActions from '../../partners/store/actions';
import { TransactionService } from '../services/transaction.service';
import * as TransactionActions from './actions';

@Injectable()
export class TransactionEffects {
  currentUser$: Observable<any>;

  currentUser: any;

  constructor(
    private actions$: Actions,
    private transactionService: TransactionService,
    private store$: Store<State>,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private appService: AppService,
    private store: Store<any>,
    private subService: SubscriptionService
  ) {}

  GetTransactions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TransactionActions.LoadTransaction),
      switchMap((payload) => {
        this.appService.showLoading(true);
        return this.transactionService.getTransactions(payload.user_id).pipe(
          delay(2000),
          takeUntil(this.subService.unsubscribe$),
          map((transactions) => {
            this.appService.showLoading(false);
            return TransactionActions.LoadTransactionSuccess({ data: transactions });
          }),
          catchError((error) => {
            return of(TransactionActions.LoadTransactionError({ msg: error.msg }));
          })
        );
      })
    )
  );

  GetTransactionById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TransactionActions.getTransactionData),
      switchMap((payload) =>
        this.transactionService.getTransactionByID(payload.id).pipe(
          takeUntil(this.subService.unsubscribe$),
          map((transactions) => {
            return TransactionActions.selectedTransaction({ data: transactions });
          }),
          catchError((error) => of(TransactionActions.LoadTransactionError({ msg: error.msg })))
        )
      )
    )
  );

  AddTransaction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TransactionActions.AddTransaction),
      withLatestFrom(this.store$, (action, state) => ({ state, action })),
      switchMap((payload) => {
        return from(
          this.transactionService.addTransaction(payload.action.data, payload.state.login.currentUser.uid)
        ).pipe(
          delay(4000),
          switchMap(() => {
            this.modalService.dismissAll();
            this.toastr.success('Transaction Added Successfully');
            let fetchAction: Observable<
              {
                data: any;
              } & TypedAction<string>
            > = of(
              TransactionActions.GetTransactionsModified({
                data: payload.state.transactions.lastActionPayload,
              })
            );
            if (
              payload.state.login.currentUser.transactionLayout &&
              payload.state.login.currentUser.transactionLayout !== 'list'
            ) {
              fetchAction = of(
                TransactionActions.getTransactionCard({
                  data: { curPage: 1, last_thirty: payload.state.transactions.last_thirty },
                })
              );
            }
            return merge(
              of(TransactionActions.AddTransactionSuccess()),
              of(PartnerActions.PartnersList({ userId: payload.state.login.currentUser.uid })),
              fetchAction
            );
          }),
          catchError((err) => {

            return of(TransactionActions.AddTransactionError({ msg: err.msg as string }));
          })
        );
      })
    )
  );

  EditTransaction$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TransactionActions.EditTransaction),
      withLatestFrom(this.store$, (action, state) => ({ state, action })),
      switchMap((payload) => {
        return from(
          this.transactionService.editTransaction(payload.action.data, payload.state.login.currentUser.uid)
        ).pipe(
          switchMap(() => {
            this.modalService.dismissAll();
            this.toastr.success('Transaction Updated');
            let fetchAction: Observable<
              {
                data: any;
              } & TypedAction<string>
            > = of(
              TransactionActions.GetTransactionsModified({
                data: payload.state.transactions.lastActionPayload,
              })
            );
            if (
              payload.state.login.currentUser.transactionLayout &&
              payload.state.login.currentUser.transactionLayout !== 'list'
            ) {
              fetchAction = of(
                TransactionActions.getTransactionCard({
                  data: { curPage: 1, last_thirty: payload.state.transactions.last_thirty },
                })
              );
            }
            return merge(of(TransactionActions.EditTransactionSuccess()), fetchAction);
          }),
          catchError((err) => {
            console.log(err);
            return of(TransactionActions.EditTransactionError({ msg: err.msg as string }));
          })
        );
      })
    );
  });

  UploadCsv$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TransactionActions.UploadCSVTransactions),
      switchMap((payload) =>
        from(
          this.transactionService.uploadCsv(
            payload.id,
            payload.file,
            payload.groupId,
            payload.optout,
            payload.selectedDripIds,
            payload.parentId
          )
        )
      ),
      map((_) => {
        this.toastr.success('CSV Uploading in progress', 'File Upload', { positionClass: 'toast-top-right' });
        this.modalService.dismissAll();
        return TransactionActions.UploadCSVTransactionsSuccess();
      }),
      catchError((err) => {
        this.toastr.error('CSV Upload Failed', 'File Upload', { positionClass: 'toast-top-right' });
        return of(TransactionActions.UploadCSVTransactionsError({ msg: err.message }));
      })
    )
  );

  FetchTransactionCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TransactionActions.GetTransactionCount),
      withLatestFrom(this.store$, (action, state) => ({ state, action })),
      mergeMap((payload) =>
        from(
          this.transactionService.fetchCount(payload.state.login.currentUser.uid, payload.state.transactions.onlyMe)
        ).pipe(
          map((data) => {
            return TransactionActions.TransactionCount({ data: data['count'] });
          })
        )
      )
    )
  );

  FetchTransactions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TransactionActions.GetTransactions),
      withLatestFrom(this.store$, (action, state) => ({ state, action })),
      mergeMap((payload) => {
        this.appService.showLoading(true);
        return from(
          this.transactionService.fetchTransactions(
            payload.action.data,
            payload.state.login.currentUser.uid,
            payload.state.transactions.onlyMe
          )
        ).pipe(
          map((result) => {
            this.appService.showLoading(false);
            return TransactionActions.LoadTransactionSuccess({ data: result });
          })
        );
      })
    )
  );

  GetTransactionsModified$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TransactionActions.GetTransactionsModified),
      withLatestFrom(this.store$, (action, state) => ({ state, action })),
      mergeMap((payload) => {
        this.appService.showLoading(true);
        return from(
          this.transactionService.getTransactionModified(
            payload.action.data,
            payload.state.login.currentUser.uid,
            payload.state.transactions.onlyMe
          )
        ).pipe(
          map((result) => {
            this.appService.showLoading(false);
            return TransactionActions.LoadTransactionSuccess({ data: result });
          })
        );
      })
    )
  );

  SearchTransactions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TransactionActions.SearchTransaction),
      withLatestFrom(this.store$, (action, state) => ({ state, action })),
      switchMap((payload) =>
        from(
          this.transactionService.searchTransactions(
            payload.action.data,
            payload.state.login.currentUser.uid,
            payload.state.transactions.onlyMe
          )
        ).pipe(
          map((result) => {
            return TransactionActions.LoadTransactionSuccess({ data: result });
          })
        )
      )
    )
  );

  FilterTransactions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TransactionActions.FilterTransaction),
      withLatestFrom(this.store$, (action, state) => ({ state, action })),
      mergeMap((payload) => {
        this.appService.showLoading(true);
        return from(
          this.transactionService.filterTransactions(
            payload.action.data,
            payload.state.login.currentUser.uid,
            payload.state.transactions.onlyMe
          )
        ).pipe(
          map((result) => {
            this.appService.showLoading(false);
            return TransactionActions.LoadTransactionSuccess({ data: result });
          })
        );
      })
    )
  );

  SortTransaction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TransactionActions.SortTransaction),
      withLatestFrom(this.store$, (action, state) => ({ state, action })),
      mergeMap((payload) => {
        this.appService.showLoading(true);
        return from(
          this.transactionService.sortTransaction(
            payload.action.data,
            payload.state.login.currentUser.uid,
            payload.state.transactions.onlyMe
          )
        ).pipe(
          map((result) => {
            this.appService.showLoading(false);
            return TransactionActions.LoadTransactionSuccess({ data: result });
          })
        );
      })
    )
  );

  AddBussinessContact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TransactionActions.AddBussinessContact),
      switchMap((payload) => from(this.transactionService.AddBussinessContact(payload.data, payload.id))),
      map((_) => {
        this.toastr.success('Business Contact Added Successfully');
        this.modalService.dismissAll();
        return TransactionActions.AddBussinessContactSuccess();
      }),
      catchError((err) => {
        return of(TransactionActions.AddBussinessContactError({ msg: err.message }));
      })
    )
  );

  UpdateTransactionStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TransactionActions.UpdateTransactionStatus),
      withLatestFrom(this.store$, (action, state) => ({ state, action })),
      mergeMap((payload) => {
        return from(this.transactionService.updateTransactionStatus(payload.action.id, payload.action.status)).pipe(
          tap(() => {
            this.toastr.success('Transaction Status Updated Successfully');
          }),
          delay(2000),
          map((_) => {
            if (
              !payload.state.login.currentUser.transactionLayout ||
              payload.state.login.currentUser.transactionLayout === 'list'
            ) {
              return TransactionActions.GetTransactionsModified({
                data: { curPage: payload.state.transactions.curPage },
              });
            }
              const tempPayload = payload.action.extraData ?? { last_thirty: payload.state.transactions.last_thirty };
              return TransactionActions.getTransactionCard({
                data: tempPayload,
              });

          })
        );
      })
    )
  );

  FetchTransactionsCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TransactionActions.getTransactionCard),
      withLatestFrom(this.store$, (action, state) => ({ state, action })),
      switchMap((payload) => {
        this.appService.showLoading(true);
        return from(
          this.transactionService.fetchTransactionsCard(
            payload.action.data,
            payload.state.login.currentUser.uid,
            [...new Set(payload.state.users.statusNames.map((l) => l.status.toLowerCase()))],
            payload.state.transactions.onlyMe
          )
        ).pipe(
          switchMap((result) => {
            this.appService.showLoading(false);
            this.modalService.dismissAll();
            return merge(
              of(TransactionActions.LoadTransactionSuccess({ data: result })),
              of(
                TransactionActions.transactionCardFetchSuccessAll({
                  milestones: payload.state.users.statusNames.map((l) => l.status),
                })
              )
            );
          })
        );
      })
    )
  );

  TransactionsCardScroll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TransactionActions.transactionCardScrollDemand),
      withLatestFrom(this.store$, (action, state) => ({ state, action })),
      switchMap((payload) => {
        this.appService.showLoading(true);
        return from(
          this.transactionService.transactionsCardScroll(
            {
              ...payload.action.data,
              curPage: payload.state.transactions.scrollProgress[payload.action.data.milestone],
            },
            payload.state.login.currentUser.uid,
            payload.state.transactions.onlyMe
          )
        ).pipe(
          switchMap((result) => {
            this.appService.showLoading(false);
            return merge(
              of(
                TransactionActions.transactionCardFetchSuccess({
                  data: result,
                  milestone: payload.action.data.milestone,
                })
              )
            );
          })
        );
      })
    )
  );

  DeleteTransaction$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TransactionActions.deleteTransaction),
      withLatestFrom(this.store$, (action, state) => ({ state, action })),
      switchMap((payload) => {
        return from(
          this.transactionService.deleteTransaction(payload.action.selectedTransaction, payload.action.uId)
        ).pipe(
          delay(3000),
          switchMap(() => {
            this.toastr.success('Transaction(s) Deleted');
            return merge(
              of(TransactionActions.deleteTransactionSuccess({ data: payload.state.transactions.curPage })),
              of(
                TransactionActions.GetTransactionsModified({
                  data: { curPage: payload.state.transactions.curPage },
                })
              )
            );
          }),
          catchError((err) => {
            return of(TransactionActions.deleteTransactionError({ msg: err.msg as string }));
          })
        );
      })
    );
  });

  GetTransactionUIData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TransactionActions.GetTransactionUIData),
      withLatestFrom(this.store$, (_, state) => ({ state })),
      switchMap((payload) =>
        from(this.transactionService.getTransactionUI(payload.state.login.currentUser.uid)).pipe(
          map((result) => TransactionActions.GetTransactionUIDataSuccess({ uiData: result }))
        )
      )
    )
  );
}
