import { Component, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { filter, take, takeUntil } from 'rxjs/operators';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import * as Twilio from 'twilio-client';
import { Validators, FormBuilder } from '@angular/forms';
import { AmazingTimePickerService } from 'amazing-time-picker';
import * as moment from 'moment-timezone';
import { LoginState } from '../modules/login/store/state';

import { selectCurrentUser, selectPermissionObject } from '../modules/login/store/selectors';
import { AuthService } from '../services/auth.service';
import { LayoutService } from './services/layout.service';
import { MakePaymentPopupComponent } from './make-payment-popup/make-payment-popup.component';
import { PaymentService } from '../services/payment.service';
import { ContactService } from '../modules/contacts/services/contact.service';
import { AddNote } from '../modules/contacts/store/action';
import { selectAddNoteSuccess } from '../modules/contacts/store/selector';
import { AddTaskService } from '../modules/contacts/services/create-task.service';
import { sleep, stripeDisabledToastContent, timeZonesMap, vCard } from '../shared/utils';
import { generateDateWithTimeZone } from '../modules/contacts/helpers/contact-helper';
import { SharedService } from '../shared/services/shared.service';
import { AddVcard, GetCustomFields } from '../modules/settings/store/action';
import { PopupPaymentComponent } from '../modules/settings/components/popup-payment/popup-payment.component';
import { SettingsService } from '../modules/settings/services';
import { GetTransactionUIData } from '../modules/transactions/store/actions';
import { NoteVisibility } from '../modules/contacts/components/note-popup/note-popup.component';
import { ForceSsoPopupComponent } from './force-sso-popup/force-sso-popup.component';

const { version } = require('../../../package.json');

declare var require: any;
declare var pendo: any;
declare var freshpaint: any;
declare var Appcues: any;

@Component({
  selector: 'dailyai-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit, OnDestroy {
  public version = '';

  stripeWarningToast = stripeDisabledToastContent;

  activeTab: string;

  username: string;

  role: string;

  currentUser$: Observable<any>;

  ngUnsubscribe: Subject<any> = new Subject<any>();

  imgUrl: string;

  public today = new Date();

  userType: any;

  paymentNow = false;

  uid: string;

  customer_id: string;

  settingPagePayment = false;

  remainDays = 0;

  hover: boolean;

  callMuted = false;

  currentUser: any;

  selectedTask: any = {};

  remainingPayableAmount_dollar: number = null;

  callStatus = this.fb.group({
    status: ['completed', Validators.required],
    notes: [''],
    dueDate: [''],
    dueTime: [''],
    subject: [this.selectedTask ? this.selectedTask.subject : ''],
  });

  dueDateForm = this.fb.group({
    dueDate: ['', Validators.required],
    notes: [''],
  });

  showSubject = false;

  timeLeft = 5;

  interval;

  @ViewChild('logoutUser') logoutModal: TemplateRef<any>;

  @ViewChild('callModal') dialerModal: TemplateRef<any>;

  @ViewChild('callStatusModal') callStatusModal: TemplateRef<any>;

  @ViewChild('callerIdSelection') callerIdSelection: TemplateRef<any>;

  contactDetails: any;

  time = '00:00';

  callModalStatus: string;

  minutesLabel: string;

  secondsLabel: string;

  hide = false;

  show = true;

  connection: any;

  loading: boolean;

  totalSeconds = 0;

  timer: any;

  parentId: string;

  assigned_to: any;

  popup: NgbModalRef;

  isNavOpen = false;

  expandPostpone = false;

  addNoteSuccess$: Observable<any>;

  mandatoryPay = false;

  useCallerId = false;

  activeModal: NgbModalRef;

  userVersion: number;

  disconnecting: Boolean = false;

  showTabletInfo = true;

  constructor(
    private route: Router,
    private store: Store<LoginState>,
    private layoutService: LayoutService,
    private auth: AuthService,
    private modalService: NgbModal,
    private paymentService: PaymentService,
    private toastr: ToastrService,
    private contactService: ContactService,
    private fb: FormBuilder,
    private taskService: AddTaskService,
    private atp: AmazingTimePickerService,
    private sharedService: SharedService,
    private settingsService: SettingsService
  ) {
    this.version = version;
    Date.prototype.yyyymmdd = function (date?) {
      if (date) {
        const d = date.toString();
        this.setYear(d[0] + d[1] + d[2] + d[3]);
        this.setMonth(d[4] + d[5], 1);
        this.setDate(d[6] + d[7]);
        return this;
      }
      return this.getFullYear() * 10000 + this.getMonth() * 100 + this.getDate();
    };
    this.currentUser$ = this.store.pipe(select(selectCurrentUser));
    this.currentUser$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(async (data) => {
      if (!data) {
        return;
      }
      this.store.dispatch(GetCustomFields({ uid: data.superUserId ?? data.parentId ?? data.uid }));
      this.currentUser = data;
      this.parentId = data.parentId ? data.parentId : data.uid;
      if (!data.isEnterprise) {
        const today: number = Math.round(Date.now() / 1000);
        if (data.CurrentPeriodEnd <= today && data.trialEnd) {
          this.remainDays = Math.round((data.trialEnd - today) / 86400);
          this.paymentNow = this.remainDays !== Number.NaN ? true : false;
        } else {
          this.paymentNow = false;
        }
      } else {
        const today: string = moment().toISOString();
        if (!moment().isBefore(data.CurrentPeriodEnd, 'day') && data.trialEndEnterprise) {
          this.remainDays = moment(data.trialEndEnterprise).diff(today, 'days');
          if (this.remainDays !== Number.NaN) {
            this.paymentNow = true;
            const payment_history = await this.layoutService.calculateRemainigAmount(this.currentUser.uid);
            this.remainingPayableAmount_dollar = payment_history?.total;
          } else {
            this.paymentNow = false;
          }
        } else {
          this.paymentNow = false;
        }
      }
      this.username = data.firstName;
      this.userType = data.userType;
      this.uid = data.uid;
      this.role = data.userType === 'SLO' ? 'senior' : data.userType === 'JLO' ? 'junior' : 'marketing';
      this.imgUrl = data.image ? data.image : './assets/adduser-icon.svg';
    });
    this.route.events.subscribe((val) => {
      this.settingPagePayment = false;
      this.activeTab = this.route.url.split('/')[1];
      if (this.activeTab.includes('?')) {
        this.activeTab = this.activeTab.split('?')[0];
      }
      if (this.activeTab === 'drip-campaign') {
        this.activeTab = 'drip campaign';
      }
      if (this.activeTab === 'settings' && this.paymentNow) {
        this.settingPagePayment = true;
      }
    });
  }

  submitCallerId(call) {
    this.activeModal.close({ call });
  }

  testCall() {
    const obj = {
      firstName: 'Muhammad',
      lastName: 'T S',
      contact_assigned_to: this.currentUser.uid,
      phoneNumber: '+918089909511',
    };
    this.layoutService.callContactOperation(obj);
  }

  ngOnInit() {
    window.addEventListener('popstate', () => {
      if (this.currentUser.isEnterprise && this.currentUser.refilPaidBy === 'self' && !this.currentUser.customerId) {
        this.modalService.dismissAll();
      }
    });
    this.store.dispatch(GetTransactionUIData());
    this.sharedService
      .getSideNavStat()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((stat) => {
        this.isNavOpen = stat;
      });
    this.pendoSetup();
    this.freshpaintSetup();
    this.appcuesSetup();
    this.createVcardDefault();
    if (this.currentUser.isEnterprise && this.currentUser.refilPaidBy === 'self' && !this.currentUser.customerId) {
      this.mandatoryPay = true;
      const mandatoryPayRef = this.modalService.open(PopupPaymentComponent, {
        centered: true,
        backdrop: 'static',
        keyboard: false,
        windowClass: 'add-card-popup',
      });
      mandatoryPayRef.componentInstance.mandatoryAdd = true;
      mandatoryPayRef.componentInstance.user = this.currentUser;
      mandatoryPayRef.componentInstance.fromWhere = 'layout';
    }
    if (this.currentUser?.isEnterprise) {
      this.store.pipe(select(selectPermissionObject), takeUntil(this.ngUnsubscribe)).subscribe((permissions) => {
        if (permissions?.requireSSO) {
          this.auth.getCurrentUser().then((authUser) => {
            const hasMicrosoftOAuth : boolean = authUser.providerData.map((x) => x.providerId).includes('microsoft.com');
            if (!hasMicrosoftOAuth && this.currentUser.userType !== 'Super Admin') {
              const forceSSOModalInstance = this.modalService.open(ForceSsoPopupComponent, {
                centered: true,
                backdrop: 'static',
                keyboard: false,
                windowClass: 'add-card-popup',
              });
              forceSSOModalInstance.componentInstance.currentAuthUser = authUser;
            }
          })
        }
      });
    }
    this.addNoteSuccess$ = this.store.pipe(select(selectAddNoteSuccess));
    this.callStatus.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.expandPostpone = res.status === 'postponed' ? true : false;
    });
    if (this.uid) {
      this.paymentService
        .findCustomer(this.uid)
        .then((customer: any) => {
          this.customer_id = customer.customerId;
        })
        .catch((error) => {
          console.warn('err', error);
        });
    }
    this.layoutService.callEvent$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((pValue) => {
      if (!pValue) {
        return;
      }
      if (this.connection) {
        this.toastr.warning('Call in progress');
        return;
      }
      this.useCallerId = false;
      this.contactDetails = pValue;
      if (!this.contactDetails.phoneNumber || this.contactDetails.phoneNumber.length <= 10) {
        this.toastr.error('No or Invalid Mobile Phone');
        return;
      }
      this.activeModal = this.modalService.open(this.callerIdSelection, {
        centered: true,
      });
      this.activeModal.result.then((val: { call: boolean }) => {
        if (!val.call) {
          this.modalService.dismissAll();
          return;
        }

        if (this.contactDetails.task_id) {
          this.layoutService.getTaskData(this.contactDetails.task_id).then((td) => {
            this.callStatus.get('subject').setValue(td.subject);
            this.selectedTask = td;
          });
        }
        this.auth.getUserByUid(this.contactDetails.contact_assigned_to).then((data) => {
          this.assigned_to = data;
          this.callContact(this.dialerModal, this.useCallerId);
        });
      });
    });

    this.callStatus.controls['status'].valueChanges.subscribe((value) => {
      this.showSubject = value === 'on hold' ? true : false;
    });
    this.auth
      .getEmailVersion()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((version) => {
        if (this.userVersion === undefined) {
          this.userVersion = version;
        } else if (this.userVersion !== version) {
          this.openPopupAndLogout();
        }
      });
    this.contactService.getTwilioToken();
  }

  openPopupAndLogout() {
    this.modalService.dismissAll();
    this.modalService.open(this.logoutModal, {
      centered: true,
      windowClass: 'routeDripPopup',
      backdrop: 'static',
      keyboard: false,
    });
    this.timerLogout();
  }

  timerLogout() {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else if (this.timeLeft <= 0) {
        this.timeLeft = 0;
        clearInterval(this.interval);
        this.modalService.dismissAll();
        this.route.navigate(['/logout']);
      }
    }, 1000);
  }

  async createVcardDefault() {
    if (this.currentUser && !this.currentUser?.vCard?.vCard_Url) {
      const vCardData = {
        firstName: this.currentUser.firstName,
        lastName: this.currentUser.lastName,
        phoneNumberOne: this.currentUser.twilioNumber ? this.currentUser.twilioNumber.phoneNumber.slice(-10) : '',
        phoneNumberTwo: this.currentUser.phoneNo.slice(-10),
        companyName: this.currentUser.companyName ? this.currentUser.companyName : '',
        email: this.currentUser.email,
        websiteUrl: '',
      };
      const card = vCard();
      card.init();
      card.name(vCardData.firstName, vCardData.lastName);
      card.cell(vCardData.phoneNumberOne);
      card.work(vCardData.phoneNumberTwo);
      card.email(vCardData.email);
      card.organization(vCardData.companyName);
      card.url(vCardData.websiteUrl);
      const myVcard = card.get();
      const blob = new Blob([myVcard], { type: 'text/vcard;charset=utf-8;' });
      const vCardFile = new File([blob], 'vCardFile.vcf', { type: 'text/vcard' });
      const vCardUrl = await this.settingsService.vCardUpload(
        this.currentUser.uid,
        vCardFile,
        `${this.currentUser.firstName} ${this.currentUser.lastName}`
      );
      const vCardPayload = {
        vCardData,
        uid: this.currentUser.uid,
      };
      vCardPayload.vCardData['vCard_Url'] = vCardUrl;
      sleep(10000).then(() => {
        this.store.dispatch(AddVcard({ data: vCardPayload, noToast: true }));
      });
    }
  }

  pendoSetup() {
    this.currentUser$
      .pipe(
        filter((val) => val && val.uid),
        take(1)
      )
      .subscribe((user) => {
        pendo.initialize({
          visitor: {
            id: user.uid,
            email: user.email,
            full_name: `${user.firstName} ${user.lastName}`,
            role: user.userType,
            phone_number: user.phoneNo,
            designation: user.designation ?? null,
            team_name: user.teamName ?? null,
            status: user.status,
          },

          account: {
            id: user.uid,
            name: `${user.firstName} ${user.lastName}`,
            enterpriseId: user.enterpriseId ?? null,
            isEnterprise: user.isEnterprise ?? null,

          },
        });
      });
  }

  freshpaintSetup() {
    this.currentUser$
      .pipe(
        filter((val) => val && val.uid),
        take(1)
      )
      .subscribe((user) => {
        freshpaint.identify(user.uid,
          {
            email: user.email,
            full_name: `${user.firstName} ${user.lastName}`,
            role: user.userType,
            phone_number: user.phoneNo,
            designation: user.designation ?? null,
            team_name: user.teamName ?? null,
            status: user.status,
            name: `${user.firstName} ${user.lastName}`,
            enterpriseId: user.enterpriseId ?? null,
            isEnterprise: user.isEnterprise ?? null,
          });
      });
  }

  appcuesSetup() {
    this.currentUser$
      .pipe(
        filter((val) => val && val.uid),
        take(1)
      )
      .subscribe((user) => {
        Appcues.identify(user.uid, {
          id: user.uid,
          email: user.email,
          full_name: `${user.firstName} ${user.lastName}`,
          role: user.userType,
          phone_number: user.phoneNo,
          designation: user.designation ?? null,
          team_name: user.teamName ?? null,
          status: user.status,
          name: `${user.firstName} ${user.lastName}`,
          enterpriseId: user.enterpriseId ?? null,
          isEnterprise: user.isEnterprise ?? null,
        });

        Appcues.track('User Visit');
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  signout() {
    this.route.navigate(['/logout']);
  }

  openPayment() {
    const disabledTemporarily = false;
    if(disabledTemporarily){
      this.toastr.warning(this.stripeWarningToast);
      return;
    }
    const paymentRef = this.modalService.open(MakePaymentPopupComponent, {
      centered: true,
      windowClass: 'open-dialog-csv',
    });
    if (!this.currentUser.isEnterprise) {
      paymentRef.componentInstance.customerId = this.customer_id;
    } else {
      paymentRef.componentInstance.customerId = this.currentUser.customerId;
      paymentRef.componentInstance.remainingPayableAmount_dollar = this.remainingPayableAmount_dollar;
    }
  }

  getDate(
    dateobj: { year: number; month: number; day: number },
    duetime: { hour: number; minute: number; second: number }
  ) {
    const dueTime = moment()
      .tz(timeZonesMap[this.currentUser.timezone])
      .year(dateobj.year)
      .month(dateobj.month - 1)
      .date(dateobj.day)
      .hour(0)
      .minute(0)
      .second(0)
      .milliseconds(0)
      .toISOString();
    return dueTime;
  }

  openTimePicker(ev) {
    const amazingTimePicker = this.atp.open();
    amazingTimePicker.afterClose().subscribe((time) => {
      this.time = time;
      this.callStatus
        .get('dueTime')
        .setValue({ hour: Number.parseInt(time.split(':')[0], 10), minute: Number.parseInt(time.split(':')[1], 10), second: 0 });
    });
  }

  openCallStatusUpdateModal() {
    this.modalService.dismissAll();
    this.callStatus.get('status').setValue('completed');
    this.callStatus.get('notes').setValue('');
    sleep(1000).then((s) =>
      this.modalService
        .open(this.callStatusModal, {
          centered: true,
          windowClass: 'call-popup',
          backdrop: 'static',
          keyboard: false,
        })
    );
  }

  async updateCallStatusWithTask() {
    if (this.callStatus.invalid) {
      this.callStatus.markAllAsTouched();
    } else {
      let task_payload = {};
      task_payload = this.callStatus.get('status').value !== 'completed' ? {
          dueTime: this.selectedTask.dueTime,
          taskStatus: this.callStatus.value.status,
          note: this.callStatus.value.notes,
          subject: this.callStatus.value.subject,
          updated_at: new Date().toISOString(),
          visibility: true,
        } : {
          dueTime: moment()
            .tz(timeZonesMap[this.currentUser.timezone])
            .hour(0)
            .minutes(0)
            .seconds(0)
            .milliseconds(0)
            .toISOString(),
          taskStatus: this.callStatus.value.status,
          note: this.callStatus.value.notes,
          subject: this.callStatus.value.subject,
          updated_at: new Date().toISOString(),
        };
      const notes_payload = {
        data: {
          creator_id: this.currentUser.uid,
          creator_name: `${this.currentUser.firstName  } ${  this.currentUser.lastName}`,
          note: this.callStatus.value.notes,
          created_at: new Date().toISOString(),
          contact_id: this.contactDetails.doc_id,
        },
      };

      try {
        this.loading = true;
        await this.contactService.updateCallStatus(task_payload, this.selectedTask.task_id);
        if (notes_payload.data.note !== '') {
          this.store.dispatch(AddNote(notes_payload));
        }
        this.loading = false;
        this.modalService.dismissAll();
        this.toastr.success('Task performed');
        this.callStatus.get('subject').setValue('');
      } catch (error) {
        this.loading = false;
        console.warn(error);
      }
    }
  }

  public get getVisibilityType(): typeof NoteVisibility {
    return NoteVisibility;
  }

  async updateCallStatus() {
    this.callMuted = false;
    if (this.contactDetails.task_id) {
      this.updateCallStatusWithTask();
      return;
    }
    if (this.callStatus.invalid) {
      this.callStatus.markAllAsTouched();
    } else {
      const task_payload = {
        assignedto: this.currentUser,
        task: 'call',
        visibility: this.callStatus.value.status !== 'completed',
        transactionid: this.callStatus.value.status,
        assignedby: this.currentUser,
        updated_at: new Date().toISOString(),
        taskStatus: this.callStatus.value.status,
        note: this.callStatus.value.notes,
        dueTime: moment()
          .tz(timeZonesMap[this.currentUser.timezone])
          .hour(0)
          .minutes(0)
          .seconds(0)
          .milliseconds(0)
          .toISOString(),
        subject: this.callStatus.value.subject,
      };
      if (this.expandPostpone && this.callStatus.value.dueDate !== '' && this.callStatus.value.dueTime !== '') {
        task_payload['dueTime'] = this.getDate(this.callStatus.value.dueDate, this.callStatus.value.dueTime);
      }
      const payload = {
        data: {
          creator_id: this.parentId,
          creator_name: `${this.currentUser.firstName} ${this.currentUser.lastName}`,
          note: this.callStatus.value.notes,
          created_at: new Date().toISOString(),
          contact_id: this.contactDetails.doc_id,
          visibilityType: NoteVisibility.internal
        },
      };
      this.loading = true;
      await this.taskService.createTask(task_payload, this.contactDetails, true);
      if (payload.data.note !== '') {
        this.store.dispatch(AddNote(payload));
        this.addNoteSuccess$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data) => {
          if (data) {
            this.loading = true;
            this.modalService.dismissAll();

          }
        });
      }
      this.loading = true;
      this.modalService.dismissAll();
      this.callStatus.get('subject').setValue('');

    }
  }

  postponeOpen(content) {
    this.time = '';
    this.modalService.dismissAll();
    this.dueDateForm.reset();
    this.dueDateForm.get('dueDate').setValidators([Validators.required]);
    this.dueDateForm.updateValueAndValidity();
    this.modalService.open(content, { centered: true, size: 'sm', backdrop: 'static', keyboard: false });
  }

  showValidator() {
    this.show = !this.show;
  }

  getDatefromTimeline(dateobj: { year: number; month: number; day: number }) {
    const dueTime = moment()
      .tz(timeZonesMap[this.currentUser.timezone])
      .year(dateobj.year)
      .month(dateobj.month - 1)
      .date(dateobj.day)
      .hour(0)
      .minute(0)
      .second(0)
      .milliseconds(0)
      .toISOString();
    return dueTime;
  }

  async updatePostpone() {
    if (this.dueDateForm.invalid) {
      this.dueDateForm.markAllAsTouched();
      return;
    }
    const time = this.getDatefromTimeline(this.dueDateForm.value.dueDate);
    const yyyymmdd = new Date(this.getDatefromTimeline(this.dueDateForm.value.dueDate)).yyyymmdd();
    const day = new Date(this.getDatefromTimeline(this.dueDateForm.value.dueDate)).getDate();
    const month = new Date(this.getDatefromTimeline(this.dueDateForm.value.dueDate)).getMonth();
    const year = new Date(this.getDatefromTimeline(this.dueDateForm.value.dueDate)).getFullYear();
    const due_timestamp = generateDateWithTimeZone(day, month, year, 9, 30, this.currentUser.timezone);
    const payload = {
      taskStatus: 'postponed',
      dueTime: time,
      yyyymmdd,
      updated_at: new Date().toISOString(),
      due_timestamp,
    };
    if (this.dueDateForm.value.notes !== '') {
      payload['note'] = this.dueDateForm.value.notes;
      await this.contactService.taskPostpone(payload, this.selectedTask.task_id);
      this.toastr.success('Task PostPoned');
      const payloadnote = {
        data: {
          creator_id: this.parentId,
          creator_name: `${this.currentUser.firstName} ${this.currentUser.lastName}`,
          note: this.dueDateForm.value.notes,
          created_at: new Date().toISOString(),
          contact_id: this.contactDetails.doc_id,
        },
      };
      this.store.dispatch(AddNote(payloadnote));
    } else {
      await this.contactService.taskPostpone(payload, this.selectedTask.task_id);
      this.toastr.success('Task PostPoned');
    }
  }

  toggleMuteCall() {
    try {
      if (this.connection && this.connection.activeConnection && this.connection.activeConnection().mute) {
        this.callMuted = !this.callMuted;
        this.connection?.activeConnection()?.mute(this.callMuted);
      }
    } catch (error) { console.error(error) }
  }

  minimizeCallBox(modelReference?) {
    this.hide = !this.hide;
    if (this.hide) {
      this.popup.close();
      scroll(0, 0);
    }
    if (modelReference) {
      this.popup = this.modalService.open(modelReference, {
        windowClass: 'call-popup',
        backdrop: 'static',
        keyboard: false,
      });
    }
  }

  close() {
    this.modalService.dismissAll();
    this.dueDateForm.reset();
  }

  setTime() {
    this.totalSeconds = this.totalSeconds + 1;
    this.secondsLabel = this.pad(this.totalSeconds % 60);
    const minLabel = this.totalSeconds / 60;
    this.minutesLabel = this.pad(Math.floor(minLabel));
  }

  resetTimer() {
    this.totalSeconds = 0;
    this.secondsLabel = '00';
    this.minutesLabel = '00';
  }

  pad(val) {
    const valString = `${val  }`;
    if (valString.length < 2) {
      return `0${  valString}`;
    }
      return valString;

  }

  disconnectCall2() {
    this.hide = false;
    try {
      this.connection.destroy();
    } catch (error) {
      setTimeout(() => {
        this.connection.destroy();
        this.connection = null;
      }, 2000)
    }
    this.connection = null;
    this.callModalStatus = 'Call Ended';
    if (!this.connection) {
      this.modalService.dismissAll();
    }
  }

  disconnectCall() {
    this.disconnecting = true;
    if (this.callModalStatus === 'Connecting..') {
      this.disconnectCall2();
      return;
    }
    this.connection.disconnectAll();
  }

  async callContact(modal, useCallerId) {
    if (this.connection) {
      this.disconnectCall2();
    }
    if (!this.contactDetails.phoneNumber || this.contactDetails.phoneNumber.length <= 10) {
      this.toastr.error('No or Invalid Mobile Phone');
      return;
    }
    this.disconnecting = false;
    this.loading = false;
    this.resetTimer();
    clearInterval(this.timer);
    this.popup = this.modalService
      .open(modal, {
        centered: true,
        windowClass: 'call-popup',
        backdrop: 'static',
        keyboard: false,
      });
    this.resetTimer();

    this.connection = new Twilio.Device();
    this.callModalStatus = 'Connecting..';
    this.connection.on('disconnect', async () => {
      this.disconnectCall2();
      if (this.contactDetails.task_id) {
        this.openCallStatusUpdateModal();
        this.layoutService.contactLastContacted(this.contactDetails.doc_id);
      } else if (this.contactDetails.doc_id) {
        this.openCallStatusUpdateModal();
        this.layoutService.contactLastContacted(this.contactDetails.doc_id);
      } else {
        this.modalService.dismissAll();
      }
      this.hide = false;
      this.connection = null;
    });
    this.connection.on('connect', () => {
      this.callModalStatus = 'connected';
      this.timer = setInterval(() => {
        this.setTime();
      }, 1000);
    });
    const payload = {
      callerId:
        this.currentUser['userType'] === 'MO'
          ? this.assigned_to.twilioNumber.phoneNumber
          : this.currentUser.twilioNumber.phoneNumber,
      to: this.contactDetails.phoneNumber.split('-').join(''),
      user_id: this.currentUser.uid,
      isMO: this.currentUser['userType'] === 'MO',
      contact_assigned_to: this.contactDetails.contact_assigned_to,
      phoneNumber: this.currentUser['userType'] === 'MO' ? this.assigned_to.phoneNo : this.currentUser.phoneNo,
      contact_id: this.contactDetails.doc_id,
      useCallerId,
    };

    const token = await this.contactService.getTwilioToken();
    if (!this.connection?.setup) {
      this.connection = new Twilio.Device();
    }
    this.connection.setup(token);
    this.connection.on('ready', () => {
      if (!this.connection) {
        return;
      }
      this.callModalStatus = 'Calling..';
      this.connection.connect({ number: JSON.stringify(payload) });
    });
  }

  addTimeLineTask(contactData: any) {
    this.layoutService.addTimelineTask(contactData, this.currentUser);
  }

  handleCloseNotification() {
    this.showTabletInfo = !this.showTabletInfo;
  }
}
