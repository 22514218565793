import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { first, takeUntil, take, filter, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment-timezone';
import { environment } from 'src/environments/environment';
import * as firebase from 'firebase/app';
import { PermissionObject } from '../shared/utils';
import { SubscriptionService } from './subscriptions.service';
import { GET_USER, SET_PERMISSION_URL, SET_PASSWORD_URL, GET_FIRST_TIME_LOGIN_TOKEN_URL, SET_SSO_ACTIVE_URL } from "../shared/config";
import * as LoginStateActions from '../modules/login/store/actions';
import { LoginState } from '../modules/login/store/state';
import { User } from '../models/user.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    public afAuth: AngularFireAuth,
    private db: AngularFirestore,
    private router: Router,
    private store: Store<LoginState>,
    private http: HttpClient,
    private subservice: SubscriptionService,
    private toast: ToastrService
  ) {
  }

  userToken: { createdDate: Date; token: string } = null;

  currentUser: any;

  userSubscription: Subscription;

  getUserData() {
    return this.currentUser;
  }

  async getUserByUid(uid) {
    if (!uid) {
      return {};
    }
    const user = (await this.db.collection<any>('users').doc(uid).ref.get()).data();
    return user;
  }

  async getMOData(parentId) {
    const mo = (
      await this.db.collection<any>('users').ref.where('parentId', '==', parentId).where('userType', '==', 'MO').get()
    ).docs;
    if (mo.length > 0) {
      return mo[0].data();
    }
    return null;
  }

  async getJuniorUserData(userId: string) {
    return (await this.db.collection<any>('junior-users').doc(userId).ref.get()).data();
  }

  async createUser(value): Promise<any> {
    const existQuery = await this.db
      .collection<any>('license_requests')
      .ref.where('email', '==', value.userEmail)
      .where('status', '==', 'pending')
      .get();

    if (existQuery.size > 0) {
      throw new Error('Your Request is pending with us please wait for approval');
    }

    return this.afAuth.createUserWithEmailAndPassword(value.userEmail, value.userPassword).then((res) => {
      const uID = res.user.uid;
      let firstName = (value.firstName as string).toLowerCase();
      firstName = firstName.charAt(0).toUpperCase() + firstName.slice(1);
      let lastName = (value.lastName as string).toLowerCase();
      lastName = lastName.charAt(0).toUpperCase() + lastName.slice(1);
      this.afAuth.currentUser.then((user) =>
        user
          .updateProfile({ displayName: `${firstName} ${lastName}` })
          .then()
          .catch()
      );
      const toSet: any = {
        email: value.userEmail,
        firstName: value.firstName,
        lastName: value.lastName,
        uid: uID,
        phoneNo: value.phoneNo,
        timezone: value.timezone,
        companyName: value.companyName,
        onboarding: {
          status: value.userType === 'MO' ? 'complete' : 'incomplete',
          state: value.userType === 'MO' ? 4 : -1,
        },
        emailSignature: `<div><br></div>With Regards,<div><b>${firstName} ${lastName}</b></div>`,
        status: 'Active',
      };
      if (value.userType === 'JLO' || value.userType === 'MO') {
        toSet['parentId'] = value.parentId;
        toSet['userType'] = value.userType;
        toSet['designation'] = value.designation;
        toSet['isSmallEnterprise'] = value.isSmallEnterprise;
        toSet['userId'] = value.uiD;
        toSet['CurrentPeriodStart'] = value.CurrentPeriodStart;
        toSet['CurrentPeriodEnd'] = value.CurrentPeriodEnd;
        toSet['jlo_should_refill'] = value.jlo_should_refill;
        if (value.privileged_jlo) {
          toSet['privileged_jlo'] = true;
        }
        this.db
          .collection<any>('users')
          .doc(uID)
          .set(toSet)
          .then((resp) => {
            this.db
              .collection<any>('junior-users')
              .doc(value.uiD)
              .update({
                status: 'Active',
                tokenStatus: 'Expired',
                phoneNo: value.phoneNo,
                uid: uID,
              })
              .then((data) => {
                this.setPermissions(uID)
                  .then((re) => {
                  })
                  .catch((error) => {
                  });

                this.signout();
              });
          });
      } else {
        toSet.userType = value.userType;
        this.db
          .collection<any>('users')
          .doc(uID)
          .set(toSet)
          .then((r) => {
            if (value.userType !== 'SLO') {
              this.signout();
            } else {
              this.router.navigate(['/dashboard']);
            }
          });
      }
    });
  }

  loginUser(value): Promise<any> {
    this.subservice.init();
    return this.afAuth.signInWithEmailAndPassword(value.userEmail, value.userPassword);
  }

  loginUserMicrosoft(): Promise<any> {
    this.subservice.init();
    return this.afAuth.signInWithPopup(new firebase.default.auth.OAuthProvider('microsoft.com'));
  }

  async loginWithToken(token_id: string, skipRouting = false, silenceToast = false) {
    const tokenData = (await this.db.collection<any>('admin-tokens').doc(token_id).ref.get()).data() as {
      token: string;
      expiry: string;
      id: string;
    };
    if (!tokenData) {
      this.toast.error('Invalid URL', 'Admin Login');
      this.router.navigate(['/login']);
      return;
    }
    if (new Date() <= new Date(tokenData.expiry)) {
      try {
        !silenceToast ? this.toast.info('Verifying URL, Please wait', 'Admin Login') : null;
        await this.afAuth.signInWithCustomToken(tokenData.token);
        this.db.collection<any>('admin-tokens').doc(token_id).ref.delete();
        !skipRouting ? this.router.navigate(['/onboarding']) : null;
      } catch (error) {
        this.toast.error('Something Went Wrong', 'Admin Login');
      }
    } else {
      this.toast.error('URL Expired', 'Admin Login');
      this.db.collection<any>('admin-tokens').doc(token_id).ref.delete();
      this.router.navigate(['/login']);
    }
  }

  async setPermissions(uid: string): Promise<any> {
    const jwtoken = await this.getUserToken();
    const theaders = new HttpHeaders({
      Authorization: `Bearer ${jwtoken}`,
    });
    return this.http.post(SET_PERMISSION_URL, { uid }, { headers: theaders }).toPromise();
  }

  async setUser() {
    const userRef = this.db.collection<any>('users').doc((await this.afAuth.currentUser).uid);
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
    this.userSubscription = userRef
      .valueChanges()
      .pipe(takeUntil(this.subservice.unsubscribe$))
      .subscribe((data: User) => {
        const userData = data as User;
        this.currentUser = userData;
        this.store.dispatch(LoginStateActions.LoginSuccess(userData));
        if (data?.isEnterprise) {
          this.getEnterpriseRenewal();
        }
      });
    userRef
      .valueChanges()
      .pipe(take(1))
      .subscribe((val: any) => {
        if (val.isEnterprise) {
          this.store.dispatch(LoginStateActions.GetEnterprisePermissions());
        }
      });
  }

  async doesUserExistInFirestore(uid) {
    const userRef = this.db.collection("users").doc(uid);

    const user = await userRef.get().toPromise();

    return user.exists;
  }

  getEnterpriseRenewal() {
    const pArray: Promise<any>[] = [];
    pArray.push(
      this.db
        .collection<any>('billing_admins')
        .ref.where('enterpriseId', '==', this.currentUser?.enterpriseId)
        .limit(1)
        .get()
    );
    pArray.push(this.db.collection<any>('users').doc(this.currentUser?.superUserId).ref.get());
    Promise.all(pArray).then((val) => {
      const data = {
        ...this.currentUser,
        CurrentPeriodEnd: val[0].docs[0]?.get('CurrentPeriodEnd') ?? null,
        CurrentPeriodStart: val[0].docs[0]?.get('CurrentPeriodStart') ?? null,
        billingPeriodTo: val[0].docs[0]?.get('billing_to') ?? null,
        billingPeriodFrom: val[0].docs[0]?.get('billing_from') ?? null,
        trialEndEnterprise: val[0].docs[0]?.get('trialEnd') ?? null,
        superAdminTimezone: val[1].data().timezone,
        superAdminName: `${val[1].data()?.firstName}${val[1].data()?.lastName}`,
      };
      this.store.dispatch(LoginStateActions.LoginSuccess(data));
    });
  }

  signout(resetPassword = false) {
    this.userToken = null;
    this.subservice.logOut();
    this.afAuth.signOut().then((_) => {
      this.userToken = null;
      this.currentUser = null;
      if (this.userSubscription) {
        this.userSubscription.unsubscribe();
      }
      if (!resetPassword) {
        this.router.navigate(['/login'], { replaceUrl: true });
      }
      this.store.dispatch(LoginStateActions.Logout({ action: 'Logout' }));
      this.userToken = null;
    });
  }

  isLoggedIn() {
    return this.afAuth.authState.pipe(first()).toPromise();
  }

  resetPassword(email): Promise<any> {
    return this.afAuth.sendPasswordResetEmail(email);
  }

  verifyPasswordResetCode(code): Promise<any> {
    return this.afAuth.verifyPasswordResetCode(code);
  }

  confirmPasswordReset(code, newPassword): Promise<any> {
    return this.afAuth.confirmPasswordReset(code, newPassword);
  }

  setPassword(uid: string, newPassword: string, email: string, timezone: string): Promise<any> {
    return this.http.post(SET_PASSWORD_URL, { uid, password: newPassword, timezone }).toPromise();
  }

  setSSOActive(uid: string, timezone: string): Promise<any> {
    return this.http.post(SET_SSO_ACTIVE_URL, { uid, timezone }).toPromise();
  }

  getUserTokenForSSOFirstTimeLogin(uid: string) {
    return this.http.post(GET_FIRST_TIME_LOGIN_TOKEN_URL, { uid }).toPromise();
  }

  async getUserToken() {
    if (this.userToken && this.userToken.token) {
      const afterOneHour = moment(this.userToken.createdDate).add(1, 'hour').toDate();
      if (new Date() > afterOneHour) {
        const newToken = await (await this.afAuth.currentUser).getIdToken(true);
        this.userToken = { token: newToken, createdDate: new Date() };
        return newToken;
      }
      return this.userToken.token;
    }
      const newToken = await (await this.afAuth.currentUser).getIdToken(true);
      this.userToken = { token: newToken, createdDate: new Date() };
      return newToken;

  }

  getUser(token: string, fromWhere?: string): any {
    let obj: any = { token };
    if (fromWhere) {
      obj = { ...obj, isEnterprise: true };
    }
    return this.http
      .get<any>(GET_USER, { params: obj })
      .toPromise();

  }

  async getEmailCampData(campaign_id: string) {
    return (await this.db.collection<any>('email_campaigns').doc(campaign_id).ref.get()).data();
  }

  async setOnboarding(state: number) {
    const userRef = this.db.collection<any>('users').doc((await this.afAuth.currentUser).uid);
    userRef.update({
      onboarding: {
        status: state > 4 ? 'complete' : 'incomplete',
        state,
      },
    });
  }

  async loadMilestoneAction(payload: any) {
    const userRef = this.db.collection<any>('users').doc((await this.afAuth.currentUser).uid);
    await userRef.update({
      ...payload,
    });
    this.router.navigate(['/contacts']);
  }

  async getDB() {
    const doc = await this.db
      .collection<any>('users')
      .doc((await this.afAuth.currentUser).uid)
      .get()
      .toPromise();
    return doc.data();
  }

  async syncLisceneofuser() {
    const doc = await this.db
      .collection<any>('users')
      .doc((await this.afAuth.currentUser).uid)
      .get()
      .toPromise();
    const user = doc.data();
    const existQuery = await this.db.collection<any>('license_requests').ref.where('email', '==', user.email).get();
    if (existQuery.size > 0) {
      let liscence_user: any = {};
      for (const element of existQuery.docs) {
        liscence_user = element.data();
      }
      await this.db
        .collection<any>('users')
        .doc(user.uid)
        .update({ planType: liscence_user.planType, planValue: liscence_user.planValue });
    }

  }

  getDBref() {
    return this.db.collection<any>('users').doc(this.currentUser.uid);
  }

  async setEncompassref(id, data) {
    return this.db.collection<any>('encompassDetails').doc(id).set(data);
  }

  async getEncompassref(key: string) {
    return this.db.collection<any>('encompassDetails').ref.where('secretKey', '==', key).get();
  }

  getUserCommunicationRef() {
    return this.db.collection<any>('user_communications').doc(this.currentUser.uid);
  }

  async getCurrentUser() {
    return this.afAuth.currentUser;
  }

  async getCurrentUserUid() {
    return (await this.afAuth.currentUser).uid;
  }

  getCurrentUser2() {
    return this.afAuth.currentUser;
  }

  getContactRef(id) {
    return this.db.collection<any>('contacts').doc(id);
  }

  getArrayUnion() {
    return firebase.default.firestore.FieldValue.arrayUnion;
  }

  async skipEncompass() {
    const dbRef = this.getDBref();
    const user = await dbRef.get().toPromise();
    const toUpdate = {
      onboarding: {},
    };
    const userData = await user.data();
    toUpdate.onboarding = this.setonboardingObject(userData);
    await this.db.collection<any>('users').doc(this.currentUser.uid).update(toUpdate);
  }

  async skipEmailIntegration() {
    await this.db
      .collection<any>('users')
      .doc(this.currentUser.uid)
      .update({
        onboarding: {
          state: !this.currentUser?.isEnterprise ? 4 : 5,
          status: !this.currentUser?.isEnterprise ? 'incomplete' : 'complete',
        },
      });
  }

  async getMyUsers(parentId: string) {
    const user_ids = [parentId];
    const users = await this.db.collection<any>('users').ref.where('parentId', '==', parentId).get();
    for (const user of users.docs) {
      user_ids.push(user.data().uid);
    }
    return user_ids;
  }

  async getCampaignData(id: string) {
    const data = await this.db.collection<any>('drip-campaign').doc(id).ref.get();
    return data.data();
  }

  async getCampaignContacts(payload: { campaign_id: string }) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    const url = `${environment.mongoUrl}/v0.1/mailCampaign/getCampaignContactsEmail`;
    const data: any = await this.http.post(url, payload, httpOptions).toPromise();
    return data.data;
  }


  setonboardingObject(user: any) {
    let obj = null;
    if (user.userType === 'SLO') {
      obj = {
        status: 'incomplete',
        state: 0,
      };
    }
    if (user.userType === 'JLO' && user.jlo_should_refill) {
      obj = {
        status: 'incomplete',
        state: 1,
      };
    }

    if (user.userType === 'MO') {
      obj = {
        status: 'incomplete',
        state: 3,
      };
    }

    if (user.userType === 'JLO' && !user.jlo_should_refill) {
      obj = {
        status: 'incomplete',
        state: 2,
      };
    }

    return obj;
  }

  getEnterprisePermissionObject(enterpriseId: string) {
    return this.db.collection<any>('permissions').doc(enterpriseId).valueChanges() as Observable<PermissionObject>;
  }

  getEmailVersion() {
    return this.db
      .collection<any>('email_change')
      .doc(this.currentUser.uid)
      .valueChanges()
      .pipe(
        filter((val: any) => !!val),
        map((val: any) => (val?.userVersion ?? 0) as number)
      );
  }
}
