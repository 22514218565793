import { createReducer, on } from '@ngrx/store';

import * as TransactionActions from './actions';
import { TransactionState } from './state';

export const initialState: TransactionState = {
  loadTransactionError: null,
  loadTransactionsSuccess: null,
  loadingTransactions: false,
  transactionList: [],
  selectedTransaction: null,
  addTransactionError: null,
  addTransactionSuccess: null,
  addingTransaction: false,
  editTransactionError: null,
  editTransactionSuccess: null,
  editingTransaction: false,
  uploadCSVError: null,
  uploadCSVSuccess: null,
  uploadingCSV: false,
  count: 0,
  curPage: 1,
  addBussinessContact: false,
  addBussinessContactSuccess: null,
  addBussinessContactError: null,
  filterData: null,
  onlyMe: false,
  scrollProgress: {},
  last_thirty: true,
  areAllSelected: false,
  deleteTransactionError: null,
  deleteTransaction: false,
  deleteTransactionSuccess: false,
  lastActionPayload: null,
  uiData: null,
};

export const TransactionReducer = createReducer(
  initialState,
  on(TransactionActions.setInitPayload, (state, payload) => ({
    ...state,
    lastActionPayload: payload.data.initPayload,
    last_thirty: payload.data.last_thirty,
    onlyMe: payload.data.onlyMe,
  })),
  on(TransactionActions.transactionOnlyMe, (state, payload) => ({
    ...state,
    onlyMe: payload.onlyMe,
  })),
  on(TransactionActions.getTransactionData, (state, payload) => ({
    ...state,
  })),
  on(TransactionActions.getTransactionCard, (state, payload) => ({
    ...state,
    loadingTransactions: true,
    loadTransactionError: null,
    loadTransactionsSuccess: null,
    curPage: payload.data.curPage,
    lastActionPayload: payload.data,
  })),
  on(TransactionActions.toggleLastThirty, (state, payload) => ({
    ...state,
    last_thirty: payload.last_thirty,
  })),
  on(TransactionActions.transactionCardFetchSuccessAll, (state, payload) => {
    const scrollProgress: { [index: string]: number } = {};
    for (const m of payload.milestones) {
      scrollProgress[m] = 2;
    }
    return { ...state, scrollProgress };
  }),
  on(TransactionActions.transactionCardFetchSuccess, (state, payload) => {
    let scrollProgress = {
      ...state.scrollProgress,
    };
    let {transactionList} = state;
    if (payload.data.data && payload.data.data.length > 0) {
      transactionList = [...transactionList, ...payload.data.data];
      scrollProgress = {
        ...state.scrollProgress,
        [payload.milestone]: state.scrollProgress[payload.milestone] + 1,
      };
    }
    return {
      ...state,
      scrollProgress,
      transactionList,
      count: payload.data.count !== undefined ? payload.data.count : state.count,
    };
  }),
  on(TransactionActions.LoadTransaction, (state, payload) => ({
    ...state,
    loadingTransactions: true,
    loadTransactionError: null,
    loadTransactionsSuccess: null,
  })),
  on(TransactionActions.UpdateTransactionStatus, (state, payload) => ({
    ...state,
    loadingTransactions: true,
    loadTransactionError: null,
    loadTransactionsSuccess: null,
  })),
  on(TransactionActions.FilterTransaction, (state, payload) => ({
    ...state,
    loadingTransactions: true,
    loadTransactionError: null,
    loadTransactionsSuccess: null,
  })),
  on(TransactionActions.SearchTransaction, (state, payload) => ({
    ...state,
    loadingTransactions: true,
    loadTransactionError: null,
    loadTransactionsSuccess: null,
  })),
  on(TransactionActions.SortTransaction, (state, payload) => ({
    ...state,
    loadingTransactions: true,
    loadTransactionError: null,
    loadTransactionsSuccess: null,
  })),
  on(TransactionActions.LoadTransactionSuccess, (state, payload) => ({
    ...state,
    loadingTransactions: false,
    loadTransactionsSuccess: true,
    loadTransactionError: null,
    transactionList: payload.data.data ? payload.data.data : payload.data,
    count: payload.data.count !== undefined ? payload.data.count : state.count,
  })),
  on(TransactionActions.LoadTransactionError, (state, payload) => ({
    ...state,
    loadTransactionError: payload.msg,
    loadingTransactions: false,
    loadTransactionsSuccess: null,
  })),
  on(TransactionActions.selectedTransaction, (state, payload) => ({
    ...state,
    selectedTransaction: payload.data,
  })),
  on(TransactionActions.AddTransaction, (state) => ({
    ...state,
    addTransactionError: null,
    addTransactionSuccess: null,
    addingTransaction: true,
  })),
  on(TransactionActions.AddTransactionSuccess, (state) => ({
    ...state,
    addTransactionError: null,
    addTransactionSuccess: true,
    addingTransaction: null,
  })),
  on(TransactionActions.AddTransactionError, (state, payload) => ({
    ...state,
    addTransactionError: payload.msg,
    addTransactionSuccess: null,
    addingTransaction: null,
  })),
  on(TransactionActions.EditTransaction, (state) => ({
    ...state,
    editTransactionError: null,
    editTransactionSuccess: null,
    editingTransaction: true,
  })),
  on(TransactionActions.EditTransactionSuccess, (state) => ({
    ...state,
    editTransactionError: null,
    editTransactionSuccess: true,
    editingTransaction: null,
  })),
  on(TransactionActions.EditTransactionError, (state, payload) => ({
    ...state,
    editTransactionError: payload.msg,
    editTransactionSuccess: null,
    editingTransaction: null,
  })),
  on(TransactionActions.UploadCSVTransactions, (state) => ({
    ...state,
    uploadingCSV: true,
    uploadCSVError: null,
    uploadCSVSuccess: null,
  })),
  on(TransactionActions.UploadCSVTransactionsSuccess, (state) => ({
    ...state,
    uploadingCSV: false,
    uploadCSVError: null,
    uploadCSVSuccess: true,
  })),
  on(TransactionActions.UploadCSVTransactionsError, (state, payload) => ({
    ...state,
    uploadingCSV: false,
    uploadCSVError: payload.msg,
    uploadCSVSuccess: null,
  })),
  on(TransactionActions.TransactionCount, (state, payload) => ({
    ...state,
    count: payload.data,
  })),
  on(TransactionActions.GetTransactions, (state, payload) => ({
    ...state,
    loadingTransactions: true,
    loadTransactionError: null,
    loadTransactionsSuccess: null,
    curPage: payload.data.curPage,
  })),
  on(TransactionActions.GetTransactionsModified, (state, payload) => ({
    ...state,
    filterData: payload.data.params ? payload.data.params : null,
    term: payload.data.term ? payload.data.term : null,
    loadingTransactions: !payload.silent,
    loadTransactionError: null,
    loadTransactionsSuccess: null,
    curPage: payload.data.curPage,
    lastActionPayload: payload.data,
  })),
  on(TransactionActions.AddBussinessContact, (state) => ({
    ...state,
    addBussinessContact: true,
    addBussinessContactSuccess: null,
    addBussinessContactError: null,
  })),
  on(TransactionActions.AddBussinessContactSuccess, (state) => ({
    ...state,
    addBussinessContactError: null,
    addBussinessContactSuccess: true,
    addBussinessContact: null,
  })),
  on(TransactionActions.AddBussinessContactError, (state, payload) => ({
    ...state,
    addTransactionError: payload.msg,
    addBussinessContactSuccess: null,
    addBussinessContact: null,
  })),
  on(TransactionActions.FilterTransaction, (state, payload) => ({
    ...state,
    filterData: payload.data.params,
  })),
  on(TransactionActions.setAreAllSelected, (state, payload) => ({
    ...state,
    areAllSelected: payload.areAllSelected,
  })),
  on(TransactionActions.deleteTransaction, (state, payload) => ({
    ...state,
    deleteTransaction: true,
    deleteTransactionSuccess: false,
    deleteTransactionError: null,
  })),
  on(TransactionActions.deleteTransactionSuccess, (state, payload) => ({
    ...state,
    deleteTransactionError: null,
    deleteTransaction: false,
    deleteTransactionSuccess: true,
  })),
  on(TransactionActions.deleteTransactionError, (state, payload) => ({
    ...state,
    deleteTransactionSuccess: false,
    deleteTransaction: false,
    deleteTransactionError: payload,
  })),
  on(TransactionActions.GetTransactionUIDataSuccess, (state, payload) => ({
    ...state,
    uiData: payload.uiData,
  }))
);
