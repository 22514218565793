import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './services/auth-guard.service';
import { LayoutComponent } from './layout/layout.component';
import { OnboardingGuardService } from './services/onboarding-guard.service';
import { LoginSignupGuardService } from './services/login-signup-guard.service';
import { CustomPreloadingService } from './custom-preloading.service';
import { ActivateTransactions } from './modules/transactions/services/transaction-activate';

import { LogoutComponent } from './logout/logout.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./modules/dashboard/dashboard.module').then((mod) => mod.DashboardModule),
        canActivate: [AuthGuardService],
      },
      {
        path: 'dashboard-beta',
        loadChildren: () => import('./modules/dashboard-beta/dashboard-beta.module').then((mod) => mod.DashboardBetaModule),
        canActivate: [AuthGuardService],
      },
      {
        path: 'art-of-homeownership',
        data: { preload: true },
        loadChildren: () => import('./modules/art-of-homeownership/art-of-homeownership.module').then((mod) => mod.ArtOfHomeownershipModule),
      },

      {
        path: 'contacts',
        data: { preload: true },
        canActivate: [AuthGuardService],
        loadChildren: () => import('./modules/contacts/contacts.module').then((mod) => mod.ContactsModule),
      },
      {
        path: 'transactions',
        data: { preload: true },
        loadChildren: () => import('./modules/transactions/transactions.module').then((mod) => mod.TransactionsModule),
        canActivate: [ActivateTransactions],
      },
      {
        path: 'settings',
        loadChildren: () => import('./modules/settings/settings.module').then((mod) => mod.SettingsModule),
      },
      {
        path: 'partners',
        loadChildren: () => import('./modules/partners/partners.module').then((mod) => mod.PartnersModule),
      },
      {
        path: 'task',
        data: { preload: true },
        loadChildren: () => import('./modules/tasks/tasks.module').then((mod) => mod.TasksModule),
      },
      {
        path: 'website',
        loadChildren: () => import('./modules/website/website.module').then((mod) => mod.WebsiteModule),
      },
      {
        path: 'drip-campaign',
        loadChildren: () =>
          import('./modules/drip-campaign/drip-campaign.module').then((mod) => mod.DripCampaignModule),
      },
      {
        path: 'communications',
        loadChildren: () =>
          import('./modules/communications/communications.module').then((mod) => mod.CommunicationsModule),
      },
      {
        path: 'reporting',
        loadChildren: () => import('./modules/reporting/reporting.module').then((mod) => mod.ReportingModule),
      },
      {
        path: 'recording',
        loadChildren: () => import('./modules/media/media.module').then((mod) => mod.MediaModule),
      },
      {
        path: 'marketing',
        loadChildren: () => import('./modules/marketing/marketing.module').then((mod) => mod.MarketingModule),
      },
      {
        path: 'calendar',
        loadChildren: () => import('./modules/calendar/calendar.module').then((mod) => mod.CalendarModule),
      },
      {
        path: 'enterprise',
        loadChildren: () => import('./modules/role-modules/teams/teams.module').then((mod) => mod.TeamModule),
      },
    ],
  },
  {
    path: 'login',
    loadChildren: () => import('./modules/login/login.module').then((mod) => mod.LoginModule),
    canActivate: [LoginSignupGuardService],
  },
  {
    path: 'logout',
    component: LogoutComponent,
  },
  {
    path: 'signup',
    loadChildren: () => import('./modules/signup/signup.module').then((mod) => mod.SignupModule),
    canActivate: [LoginSignupGuardService],
  },
  {
    path: 'onboarding',
    loadChildren: () => import('./modules/onboarding/onboarding.module').then((mod) => mod.OnboardingModule),
    canActivate: [OnboardingGuardService],
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./modules/reset-password/reset-password.module').then((mod) => mod.ResetPasswordModule),
    canActivate: [LoginSignupGuardService],
  },
  { path: '**', redirectTo: 'dashboard' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: CustomPreloadingService,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
