import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { COLLECTIONS } from 'src/app/shared/config';
import { SettingsService } from './settings.service';

const api = environment.mongoUrl;
const {cloud_base_url} = environment;
@Injectable({
  providedIn: 'root',
})
export class MilestoneService {
  constructor(
    private http: HttpClient,
    private db: AngularFirestore,
    private settingsService: SettingsService,
    private auth: AuthService
  ) {}

  async addMilestone(payload: any): Promise<any> {
    const uID = this.auth.currentUser.uid;
    const userQuery = await this.db.collection<any>(COLLECTIONS.USERS).doc(uID).ref.get();
    const user = userQuery.data();
    const req_uids = user?.isEnterprise
      ? user.userType === 'Super Admin' || user.userType === 'admin'
        ? [user.enterpriseId]
        : [user.uid]
      : await this.setids(uID, user);
    const {prevData} = payload;
    if (payload.delete_ids.length > 0) {
      for (const id of payload.delete_ids) {
        await this.db.collection<any>(COLLECTIONS.MILESTONE_DATES).doc(id).delete();
        await this.db.collection<any>(COLLECTIONS.MILESTONE_STATUS).doc(id).delete();
      }
    }

    for (const element of payload.data) {
      for (const e of element.milestone_details) {
        let data: any = {};
        data = payload.tab === 'process' ? e[payload.radio] : e;
        if (data.template_action === 'updateTemplate') {
          const templateQuery = await this.db.collection<any>(COLLECTIONS.MAIL_TEMPLATES).doc(data.templateId).ref.get();
          await (templateQuery.exists ? this.db
              .collection<any>(COLLECTIONS.MAIL_TEMPLATES)
              .doc(data.templateId)
              .update({ content: data.content, description: data.description }) : this.db.collection<any>(COLLECTIONS.MAIL_TEMPLATES).doc(data.templateId).set({
              content: data.content,
              description: data.description,
              name: data.description,
              templateId: data.templateId,
              viewersList: req_uids,
              created_at: new Date().toISOString(),
            }));
        }
        if (data.sms_action == 'updateTemplate') {
          const smsQuery = await this.db.collection<any>(COLLECTIONS.SMS_TEMPLATES).doc(data.smsId).ref.get();
          await (smsQuery.exists ? this.db
              .collection<any>(COLLECTIONS.SMS_TEMPLATES)
              .doc(data.smsId)
              .update({ name: data.name, subject: data.subject }) : this.db.collection<any>(COLLECTIONS.SMS_TEMPLATES).doc(data.smsId).set({
              subject: data.subject,
              name: data.name,
              smsId: data.smsId,
              viewersList: req_uids,
              created_at: new Date().toISOString(),
            }));
        }
      }
    }

    for (let data of payload.data) {

      const milestone_details_here: any[] = [];
      if (data.milestone_details.length >= 0) {
        for (const obj of data.milestone_details) {
          let modified_obj = {};

          modified_obj = payload.tab === 'process' ? obj[payload.radio] : obj;

          if (payload.tab === 'process') {
            if (obj[payload.radio].to_upload && obj[payload.radio].type.includes('image')) {
              const url = await this.settingsService.fileUpload(
                obj[payload.radio].to_upload,
                'settings/mms-templates/'
              );
              const { to_upload, image_url, ...rest } = obj[payload.radio];

              modified_obj = { ...rest, image_url: url };
            }
            milestone_details_here.push({ ...obj, [payload.radio]: modified_obj });
          } else {
            if (obj.to_upload && obj.type.includes('image')) {
              const url = await this.settingsService.fileUpload(obj.to_upload, 'settings/mms-templates/');
              const { to_upload, image_url, ...rest } = obj;

              modified_obj = { ...rest, image_url: url };
            }
            milestone_details_here.push(modified_obj);
          }
        }
      }

      data = { ...data, milestone_details: milestone_details_here };

      let filtered_mms_array_formatted = [];

      filtered_mms_array_formatted = payload.tab === 'process' ? data.milestone_details.map((md) => md[payload.radio]) ?? [] : data.milestone_details ?? [];

      const filtered_mms_array = filtered_mms_array_formatted.filter(
        (v) => v.mms_action && v.mms_action === 'updateTemplate'
      );

      for (let obj of filtered_mms_array) {
        if (!obj.mmsId && obj.mms_name) {
          const mmsQuery_name = await this.db
            .collection<any>(COLLECTIONS.MMS_TEMPLATES)
            .ref.where('name', '==', obj.mms_name)
            .where('viewersList', 'array-contains', uID)
            .get();
          if (mmsQuery_name.size > 0) {
            const mil_array: string[] = [];
            for (const mil of mmsQuery_name.docs) {
              mil_array.push(mil.data().mmsId);
            }
            obj = { ...obj, mmsId: mil_array[0] };
          }
        }

        if (obj.mmsId) {
          const mmsQuery = await this.db.collection<any>(COLLECTIONS.MMS_TEMPLATES).doc(obj.mmsId).ref.get();
          if (mmsQuery.exists) {
            await (obj.type && obj.type.split('/')[0] === 'video' ? mmsQuery.ref.update({
                name: obj.mms_name,
                subject: obj.mms_subject,
                type: obj.type,
                thumbnail: obj.video_thumbnail ? obj.video_thumbnail : null,
                url: obj.video_url ? obj.video_url : null,
              }) : mmsQuery.ref.update({
                name: obj.mms_name,
                subject: obj.mms_subject,
                type: obj.type,
                url: obj.image_url ? obj.image_url : null,
              }));
          }
        }
      }
      if (!data.added_by) {
        data.added_by = user.uid;
      }
      if (
        (user.userType === 'JLO' && !user.isEnterprise) ||
        (user.isEnterprise && data.added_by !== user.uid && !['Super Admin', 'admin'].includes(user.userType))
      ) {
        let item = prevData.find((x) => x.milestone_id === data.milestone_id);
        item = JSON.parse(JSON.stringify(item));
        if (item && item.jlo_milestone_details && item.jlo_milestone_details.length > 0) {
          const jloItem = item.jlo_milestone_details.find((x) => x.jlo_id === uID);
          if (jloItem) {
            jloItem.milestone_details = milestone_details_here;
          } else {
            item.jlo_milestone_details.push({ jlo_id: uID, milestone_details: milestone_details_here });
          }
        } else {
          item.jlo_milestone_details = [];
          item.jlo_milestone_details.push({ jlo_id: uID, milestone_details: milestone_details_here });
        }
        item.milestone_details = item.milestone_details_clone;
        delete item.milestone_details_clone;
        item = { ...item, stop_previous_drip: data.stop_previous_drip ? data.stop_previous_drip : false };
        data = JSON.parse(JSON.stringify(item));
      }
      if (user.isEnterprise && data.added_by !== user.uid && !['Super Admin', 'admin'].includes(user.userType)) {
        if (data.jlo_milestone_details?.length) {
          const userMilestoneData = data.jlo_milestone_details.find((x) => x.jlo_id === user.uid);
          if (userMilestoneData?.milestone_details) {
            const actionData = {
              milestone_details: userMilestoneData?.milestone_details,
              uid: user.uid,
              milestone_id: data.milestone_id,
              doc_id: `${data.milestone_id}_${user.uid}`,
            };
            await this.db.collection<any>(COLLECTIONS.ENTERPRISE_MILESTONE_DETAILS).doc(actionData.doc_id).set(actionData);
          }
        }
        const modified = { ...data, jlo_milestone_details: [] };

        await this.db
          .collection<any>(COLLECTIONS.MILESTONE_STATUS)
          .doc(data.milestone_id)
          .update({ ...modified, enterprise_user_details: firebase.default.firestore.FieldValue.arrayUnion(user.uid) });
      } else if (data.milestone_id) {

          await this.db.collection<any>(COLLECTIONS.MILESTONE_STATUS).doc(data.milestone_id).update(data);
        } else {
          if (payload.tab === 'process') {
            data = { ...data, inProcess: true };
          }
          const addStatus = await this.db.collection<any>(COLLECTIONS.MILESTONE_STATUS).add(data);
          await this.db
            .collection<any>(COLLECTIONS.MILESTONE_STATUS)
            .doc(addStatus.id)
            .update({ milestone_id: addStatus.id, viewersList: req_uids, created_at: new Date().toISOString() });
        }
    }
  }

  getMilestone(uid: any, userData: any, userType?: string, tab?: string): Observable<any> {
    if (userData.isEnterprise) {
      try {
        let vL = [uid];

        if (userData.userType === 'JLO') {
          if (userData?.teamLeaderId) {
            vL = [userData?.teamLeaderId];
          }
          if (userData?.managerId && !userData?.teamLeaderId) {
            vL = [userData?.managerId];
          }
          if (!userData?.managerId && !userData?.teamLeaderId && userData?.enterpriseId) {
            vL = [userData?.enterpriseId];
          }
        }

        if (userData.userType === 'Super Admin' || userData.userType === 'admin') {
          vL = [userData?.enterpriseId];
        }

        let milestone_query = this.db.collection<any>(COLLECTIONS.MILESTONE_STATUS, (ref) =>
          ref.where('viewersList', 'array-contains-any', vL)
        );
        if (tab === 'process') {
          milestone_query = this.db.collection<any>(COLLECTIONS.MILESTONE_STATUS, (ref) =>
            ref.where('viewersList', 'array-contains-any', vL).where('inProcess', '==', true)
          );
        }

        return milestone_query.get().pipe(
          map((d) => d.docs.map((x) => x.data())),

          map((docs: any[]) => {
            const admin_milestones = docs.filter((d) => d.userType === 'admin')
              ? docs.filter((d) => d.userType === 'admin').sort((a, b) => a.index - b.index)
              : [];
            const manager_milestones = docs.filter((d) => d.userType === 'manager')
              ? docs.filter((d) => d.userType === 'manager').sort((a, b) => a.index - b.index)
              : [];
            const team_leader_milestones = docs.filter((d) => d.userType === 'TeamLeader')
              ? docs.filter((d) => d.userType === 'TeamLeader').sort((a, b) => a.index - b.index)
              : [];
            const agent_milestones = docs.filter((d) => d.userType === 'JLO') ?? [];
            const final_milestones = [
              ...admin_milestones,
              ...manager_milestones,
              ...team_leader_milestones,
              ...agent_milestones,
            ];
            return final_milestones;
          })
        );
      } catch (error) {
        console.log('error ??????', error);
      }
    } else {
      try {
        let milestone_query = this.db.collection<any>(COLLECTIONS.MILESTONE_STATUS, (ref) =>
          ref.where('viewersList', 'array-contains', uid)
        );
        if (tab === 'process') {
          milestone_query = this.db.collection<any>(COLLECTIONS.MILESTONE_STATUS, (ref) =>
            ref.where('viewersList', 'array-contains', uid).where('inProcess', '==', true)
          );
        }
        return milestone_query.get().pipe(
          map((d) => d.docs.map((x) => x.data())),
          map((doc: any) => {
            if (userType === 'JLO') {
              for (const elem of doc) {
                elem['milestone_details_clone'] = elem['milestone_details'];
                if (elem && elem.jlo_milestone_details) {
                  elem['milestone_details'] = elem.jlo_milestone_details.find((v) => v.jlo_id === uid)
                    ? elem.jlo_milestone_details.find((v) => v.jlo_id === uid).milestone_details
                    : [];
                } else {
                  elem['milestone_details'] = [];
                }
              }
            }
            return doc;
          }),
          map((docs: any[]) => docs.sort((a, b) => a.index - b.index))
        );
      } catch (error) {
        console.log(error, '<===error');
      }
    }
  }

  async getTransactions(uid: string): Promise<any> {
    const status: any[] = [];
    const transaction_query = await this.db
      .collection<any>(COLLECTIONS.TRANSACTIONS)
      .ref.where('viewersList', 'array-contains', uid)
      .get();
    for (const transaction of transaction_query.docs) {
      status.push(transaction.data());
    }

    let milestone_status = status.map((v) => v.milestone);
    milestone_status = milestone_status.filter((v) => v != null);

    return milestone_status;
  }

  async setids(uID: string, user_data: any) {
    const user_ids: any[] = [];
    let parent_id = uID;
    const user = user_data;
    if (user.userType === 'JLO' || user.userType === 'MO') {
      parent_id = user.parentId;
    }
    const user_ids_query = await this.db
      .collection<any>(COLLECTIONS.USERS)
      .ref.where('parentId', '==', parent_id)
      .where('status', '==', 'Active')
      .get();
    if (user_ids_query.size > 0) {
      for (const element of user_ids_query.docs) {
        user_ids.push(element.data().uid);
      }
    }
    user_ids.push(parent_id);
    return user_ids;
  }

  async updateUser(uid: string, flag: boolean): Promise<any> {
    return this.db.collection<any>(COLLECTIONS.USERS).doc(uid).update({ milestone_communication_blocked: flag });
  }

  async getUserMilestoneDateList(uId: string) {
    const milestoneDates = await this.db.collection<any>(COLLECTIONS.MILESTONE_DATES).ref.where('added_by', '==', uId).get();
    let milestoneDatesList = [];
    milestoneDatesList = milestoneDates.docs.map((milestoneDate) => milestoneDate.data());
    return milestoneDatesList;
  }

  async getMilestoneStatusTasks() {
    const statusTasks = [
      {status: 'test'},
    ];

    return statusTasks;
  }

  async updateMilestoneList(milestoneDatesList: []) {
    for (const milestoneDate of milestoneDatesList) {
      const tempArray: any[] = milestoneDate['dateDetails'];
      const taskDetails: any[] = [];
      tempArray.forEach((task) => {
        if (task.type === 'milestone transition') {
          taskDetails.push({
            type: task.type,
            days: task.days,
            offset: task.offset,
            milestoneStatus: task.milestone_transition,
          });
        } else if (task.type) {
          taskDetails.push(task);
        }
      });

      await (milestoneDate['milestone_date_id'] ? this.updateMilestoneTaskStatus(milestoneDate, taskDetails) : this.addMilestoneTaskStatus(milestoneDate, taskDetails));
    }
  }

  async updateMilestoneTaskStatus(milestoneDate, taskDetails) {
    const updateBody = {
      added_by: milestoneDate['uid'],
      task_details: taskDetails,
      milestone_date_id: milestoneDate['milestone_date_id'],
      type: milestoneDate['type'],
      enterpriseId: milestoneDate['enterpriseId'],
      viewersList: [milestoneDate['uid']],
    };

    const updateMilestoneDateInDB = await this.db
      .collection<any>(COLLECTIONS.MILESTONE_DATES)
      .doc(milestoneDate['milestone_date_id'])
      .ref.get();

    if (updateMilestoneDateInDB.exists) {
      await updateMilestoneDateInDB.ref.update(updateBody);
    }
  }

  async addMilestoneTaskStatus(milestoneDate, taskDetails) {
    if (taskDetails.length === 0) {
      return;
    }
    const createBody = {
      added_by: milestoneDate['uid'],
      task_details: taskDetails,
      type: milestoneDate['type'],
      enterpriseId: milestoneDate['enterpriseId'],
      viewersList: [milestoneDate['uid']],
    };

    const createMilestoneDateInDB = this.db.collection<any>(COLLECTIONS.MILESTONE_DATES);
    createMilestoneDateInDB
      .add(createBody)
      .then(async (res) => {
        await createMilestoneDateInDB.doc(res.id).update({ milestone_date_id: res.id });
      });
  }

  async getUserList(uId: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    return this.http
      .post(
        `${api}/v0.1/users/searchJLOUser`,
        {
          id: uId,
        },
        httpOptions
      )
      .toPromise();
  }

  async getMilestoneStatuses(uId: string) {
    const milestoneStatuses = await this.db.collection(COLLECTIONS.MILESTONE_STATUS).ref
      .where('added_by', '==', uId).get();

    const statuses: string[] = [];
    milestoneStatuses.docs.forEach(doc => {
      if (doc.exists && doc.data()) {
        const data: any = doc.data();
        statuses.push(data.status_name);
      }
    });

    return statuses;
  }

  async resyncMilestonesToTeamLead() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    return this.http.post(`${cloud_base_url}/enqueueMilestoneResync`, {}, httpOptions).toPromise();
  }
}
