import { BrowserModule, HammerModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';

import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule, USE_EMULATOR as FIRESTORE_EMULATOR } from '@angular/fire/firestore';
import { AngularFireAuthModule, USE_EMULATOR as AUTH_EMULATOR } from '@angular/fire/auth';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AngularFireStorage, AngularFireStorageModule } from '@angular/fire/storage';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AmazingTimePickerModule } from 'amazing-time-picker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { QuillModule } from 'ngx-quill';
import { EmojiModule } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { CKEditorModule } from 'ng2-ckeditor';
import { LyHammerGestureConfig } from '@alyle/ui';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { reducers, metaReducers } from './reducers';
import { AppEffects } from './app.effects';
import { environment, firebase } from '../environments/environment';
import { AddPartnerService } from './modules/partners/services/add-partner.service';
import { ContactEffects } from './modules/contacts/store/effects';
import { UserEffects } from './modules/settings/store/effect';
import { LayoutComponent } from './layout/layout.component';
import { SideMenuBarComponent } from './layout/side-menu-bar/side-menu-bar.component';
import { PartnerService } from './modules/partners/services/partner.service';
import { AppService } from './shared/app.service';
import { DashboardEffects } from './modules/dashboard/store/effect';
import { TaskEffects } from './modules/tasks/store/effects';
import { DripEffects } from './modules/drip-campaign/store/effects';
import { TransactionEffects } from './modules/transactions/store/effects';
import { MakePaymentPopupComponent } from './layout/make-payment-popup/make-payment-popup.component';
import { ForceSsoPopupComponent } from './layout/force-sso-popup/force-sso-popup.component';
import { OtpPopupComponent } from './modules/signup/components/otp-popup/otp-popup.component';
import { SharedModule } from './shared/shared.module';
import { PopupPaymentComponent } from './modules/settings/components/popup-payment/popup-payment.component';
import { ViewDetailsComponent } from './layout/view-details/view-details.component';
import { DialerPopupComponent } from './layout/dialer-popup/dialer-popup.component';
import { CommunicationEffects } from './modules/communications/store/effects';
import { EmailBodyComponent } from './modules/communications/components/email-body/email-body.component';
import { ComposeMailComponent } from './modules/communications/components/compose-mail/compose-mail.component';
import { VideoRecordModelComponent } from './modules/media/components/video-record-model/video-record-model.component';
import { VideoPlayModelComponent } from './modules/media/components/video-play-model/video-play-model.component';
import { AudioRecordModelComponent } from './modules/media/components/audio-record-model/audio-record-model.component';
import { ShareModelComponent } from './modules/drip-campaign/components/share-model/share-model.component';
import { ContactResolver } from './modules/contacts/contact-resolver.service';
import { MobileSideNavComponent } from './layout/mobile-side-nav/mobile-side-nav.component';
import { EnterpriseEffects } from './modules/role-modules/teams/store/effects';
import { LogoutComponent } from './logout/logout.component';
import './firebase-initialization';
import { NameEmailFilterComponent } from './shared/grid-renderers/name-email-filter.component';
import { GridTooltipComponent } from './shared/grid-renderers/grid-tooltip.component';
import { SavedFiltersPanelComponent } from './shared/components/saved-filters-panel/saved-filters-panel.component';
import { LinkButtonRendererComponent } from './shared/grid-renderers/link-button-renderer.component';

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    SideMenuBarComponent,
    MakePaymentPopupComponent,
    OtpPopupComponent,
    PopupPaymentComponent,
    VideoRecordModelComponent,
    VideoPlayModelComponent,
    ViewDetailsComponent,
    DialerPopupComponent,
    EmailBodyComponent,
    ComposeMailComponent,
    AudioRecordModelComponent,
    ShareModelComponent,
    MobileSideNavComponent,
    LogoutComponent,
    ForceSsoPopupComponent,
    NameEmailFilterComponent,
    GridTooltipComponent,
    SavedFiltersPanelComponent,
    LinkButtonRendererComponent,
  ],
  schemas: [],
  imports: [
    BrowserModule,

    HammerModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(firebase),
    AngularFireStorageModule,
    AngularFireAuthModule,
    AngularFirestoreModule,
    FormsModule,
    HttpClientModule,
    NgbModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
    AmazingTimePickerModule,
    BrowserAnimationsModule,
    SharedModule,
    DragDropModule,
    QuillModule,
    PickerModule,
    EmojiModule,
    CKEditorModule,

    ToastrModule.forRoot({
      timeOut: 8000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),

    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    EffectsModule.forRoot([
      AppEffects,
      ContactEffects,
      UserEffects,
      DashboardEffects,
      TaskEffects,
      DripEffects,
      TransactionEffects,
      CommunicationEffects,
      EnterpriseEffects,
    ]),
    !environment.production
      ? StoreDevtoolsModule.instrument({
          maxAge: 25,
          logOnly: environment.production,
        })
      : [],
  ],
  providers: [
    AddPartnerService,
    AngularFireStorage,
    PartnerService,
    AppService,
    ContactResolver,
    { provide: HAMMER_GESTURE_CONFIG, useClass: LyHammerGestureConfig },
    {
      provide: FIRESTORE_EMULATOR,
      useValue: environment?.emulator ? [environment.custom_network ? 'cf-emulator' : 'localhost', '8081'] : undefined,
    },
    {
      provide: AUTH_EMULATOR,
      useValue: environment?.emulator ? [environment.custom_network ? 'cf-emulator' : 'localhost', '9099'] : undefined,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
