import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Injectable()
export class AddPartnerService {
  task: AngularFireUploadTask;

  percentage: Observable<number>;

  snapshot: Observable<any>;

  downloadURL: Observable<string>;

  constructor(private firestore: AngularFirestore, private storage: AngularFireStorage) {}

  addPartner(partner, file, id) {
    return this.firestore
      .collection<any>('partners')
      .add({ ...partner, viewersList: [id] })
      .then(async (res) => {
        if (file) {
          this.uploadImage(res.id, file);
        }
        return { partnerId: res.id, partnerName: `${partner.firstName  } ${  partner.lastName}` };
      });
  }

  /**
   * upload partner image and update url to partner document
   * @param id
   * @param file
   * @returns
   */
  uploadImage(id, file) {
    const path = `partner/${id}`;
    const ref = this.storage.ref(path);
    this.task = this.storage.upload(path, file);
    return this.task
      .snapshotChanges()
      .pipe(
        finalize(() => {
          ref.getDownloadURL().subscribe((url) => {
            this.firestore.collection<any>('partners').doc(id).update({ image: url });
          });
        })
      )
      .subscribe();
  }

  async updatePartner(id, partner, file?, updatedContactsList?) {
    const contactArr = partner.referralContacts ?? [];
    const contactSet = new Set(contactArr);

    const refredByContacts = (
      await this.firestore.collection<any>('contacts').ref.where('partnerId', '==', id).get()
    ).docs
      .map((doc) => doc.id)
      .filter((d) => !contactSet.has(d));
    if (refredByContacts?.length) {
      const processs = refredByContacts.map((c) =>
        this.firestore.collection<any>('contacts').doc(c).update({ partnerId: null, partnerName: null })
      );
      await Promise.all(processs);
    }
    return this.firestore
      .collection<any>('partners')
      .doc(id)
      .update(partner)
      .then((response) => {
        if (file) {
          this.uploadImage(id, file);
        }
      });
  }
}
