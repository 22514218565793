import { ToastrService } from 'ngx-toastr';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { switchMap, mergeMap, map, catchError, takeUntil, withLatestFrom } from 'rxjs/operators';
import { from, of, merge } from 'rxjs';
import { Router } from '@angular/router';
import { SubscriptionService } from 'src/app/services/subscriptions.service';
import { Store } from '@ngrx/store';
import { State } from 'src/app/reducers';
import { CommunicationsService } from '../services/communications.service';
import * as communicationStateActions from './actions';

@Injectable()
export class CommunicationEffects {
  constructor(
    private actions$: Actions,
    private toastr: ToastrService,
    private commservice: CommunicationsService,
    private router: Router,
    private subService: SubscriptionService,
    private store$: Store<State>
  ) {}

  /**
   * Effect to list Communication Contacts
   * with demand scroll
   */
  ListCommunicationContacts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(communicationStateActions.ListCommunicationContacts),
      withLatestFrom(this.store$, (action, state) => ({
        state,
        action,
      })),
      switchMap((payload) => {

        return from(
          this.commservice.communicationContactList(
            payload.action.id,
            payload.state.communications.last_doc_contacts,
            payload.action.payload,
            payload.action.unread,
            true
          )
        ).pipe(
          takeUntil(this.subService.unsubscribe$),
          switchMap((contacts: any[]) => {
            return merge(of(communicationStateActions.ListCommunicationContactsSuccess({ data: contacts })));
          }),
          catchError((error) => {
            return of(communicationStateActions.ListCommunicationContactsError({ msg: error.message }));
          })
        );
      })
    )
  );

  /**
   * Effect to list My Coversation Communication Contacts
   * @author Rajat Kumar
   */
  ListMyConversationCommunicationContacts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(communicationStateActions.ListMyConversationCommunicationContacts),
      withLatestFrom(this.store$, (action, state) => ({
        state,
        action,
      })),
      switchMap((payload) => {
        return from(
          this.commservice.fetchContactForMyConversation(
            payload.action.searchTerm,
            payload.action.unread,
            payload.action.contactType,
            payload.action.curPage
          )
        ).pipe(
          takeUntil(this.subService.unsubscribe$),
          switchMap((contacts: any[]) => {
            return merge(of(communicationStateActions.ListCommunicationContactsSuccess({ data: contacts })));
          }),
          catchError((error) => {
            return of(communicationStateActions.ListCommunicationContactsError({ msg: error.message }));
          })
        );
      })
    )
  );

  /**
   * Effect to list Sms logs of contact
   */
  ListCommunicationContactsSmsLogs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(communicationStateActions.ListCommunicationContactsSmsLogs),
      withLatestFrom(this.store$, (action, state) => ({
        state,
        action,
      })),
      switchMap((payload) => {
        return this.commservice
          .communicationContactsSmsLog(payload.action.id, payload.state.communications.last_doc_sms_logs)
          .pipe(
            takeUntil(this.subService.unsubscribe$),
            switchMap((smsLogs: any[]) => {
              return merge(of(communicationStateActions.ListCommunicationContactsSmsLogsSuccess({ data: smsLogs })));
            }),
            catchError((error) =>
              of(communicationStateActions.ListCommunicationContactsSmsLogsError({ msg: error.message }))
            )
          );
      })
    )
  );

  /**
   * Effect to get current contact
   */
  GetCurrentContact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(communicationStateActions.GetCurrentContactData),
      switchMap((payload) => {
        return from(this.commservice.getCurrentContact(payload.id)).pipe(
          takeUntil(this.subService.unsubscribe$),
          map((data) => {
            return communicationStateActions.SelectedCommunicationContact({ data });
          })
        );
      })
    )
  );

  /**
   * Effect to list Email logs of contact
   */
  ListCommunicationContactsEmailLogs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(communicationStateActions.ListCommunicationContactsEmailLogs),
      withLatestFrom(this.store$, (action, state) => ({
        state,
        action,
      })),
      mergeMap((payload) => {
        return this.commservice
          .communicationContactsEmailLog(payload.action.id, payload.state.communications.last_doc_email_logs)
          .pipe(
            takeUntil(this.subService.unsubscribe$),
            map((contact) => communicationStateActions.ListCommunicationContactsEmailLogsSuccess({ data: contact })),
            catchError((err) => {
              return of(null);
            })
          );
      })
    )
  );

  CommunicationContactSentSms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(communicationStateActions.CommunicationContactSentSms),
      switchMap((payload) => {
        return from(this.commservice.communicationContactsSentSms(payload.data)).pipe(
          switchMap((contact: any) => {
            this.toastr.clear();
            this.toastr.success('Message Sent Successfully');
            return merge(
              of(
                communicationStateActions.CommunicationContactSentSmsSuccess()
              )
            );
          })
        );
      })
    )
  );

  CommunicationContactSentEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(communicationStateActions.CommunicationContactSentEmail),
      mergeMap((payload) => {
        return from(this.commservice.communicationContactsSentMail(payload.data, payload.url)).pipe(
          map((contact) => {
            this.toastr.clear();
            this.toastr.success('Email Sent Successfully');
            return communicationStateActions.CommunicationContactSentEmailSuccess();
          })
        );
      })
    )
  );
}
