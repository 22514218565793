import { createAction, props } from '@ngrx/store';
import { Drip } from '../models/drip-campaign.model';

export const AddDrip = createAction(
  '[Drip-campaign Page] Add Drip',
  props<{ data: Drip; id: string; date: any; deleted_steps: any[]; pushDripObj: [] }>()
);
export const AddDripSuccess = createAction('[Drip-campaign Page] Add Drip Success');
export const AddDripError = createAction('[Drip-campaign page] Add Drip Error', props<{ msg: string }>());

export const ListDrip = createAction('[Drip-campaign page] List Drip', props<{ id: string; no_limit?: boolean }>());
export const ListDripSuccess = createAction('[Drip-campaign page] List Drip Success', props<{ data: any }>());
export const ListDripError = createAction('[Drip-campaign Page] List Drip Error', props<{ msg: string }>());

export const EditDrip = createAction('[Drip-campaign Page] Edit Drip', props<{ id: string }>());
export const EditDripSuccess = createAction('[Drip-campaign Page] Edit Drip Success');
export const EditDripError = createAction('[Drip-campaign Page] Edit Drip Error', props<{ msg: string }>());

export const DeleteDrip = createAction('[Drip-campaign Page] Delete Drip', props<{ id: string }>());
export const DeleteDripSuccess = createAction('[Drip-campaign Page] Delete Drip Success');
export const DeleteDripError = createAction('[Drip-campaign Page] Delete Drip Error', props<{ msg: string }>());

export const DeleteDripStep = createAction('[Drip-campaign Page] Delete Drip Step', props<{ id: string }>());
export const DeleteDripStepSuccess = createAction('[Drip-campaign Page] Delete Drip Step Success');
export const DeleteDripStepError = createAction(
  '[Drip-campaign Page] Delete Drip Step Error',
  props<{ msg: string }>()
);

export const GetDripsCount = createAction('[Drip-campaign Page] GetDripsCount', props<{ data: any }>());
export const GetDripsList = createAction('[Drip-campaign Page] GetDripsList', props<{ data: any }>());
export const SearchDrip = createAction('[Drip-campaign Page] SearchDrip', props<{ data: any }>());
export const FilterDrip = createAction('[Drip-campaign Page] FilterDrip', props<{ data: any }>());
export const SearchForJlo = createAction('[Drip-campaign Page] SearchForJlo ', props<{ data: any }>());

export const SaveDripsCount = createAction(
  '[Drip-campaign Page] SaveDripsCount',
  props<{ count: any; active: number }>()
);
export const SortDrip = createAction('[Drip-campaign Page] Sort Drip', props<{ data: any }>());

export const ListSources = createAction('[Drip-campaign page] List Sources', props<{ user: any }>());
export const ListSourcesSuccess = createAction('[Drip-campaign page] List Sources Success', props<{ data: any }>());
export const ListSourcesError = createAction('[Drip-campaign Page] List Sources Error', props<{ msg: string }>());

export const ListDripWithDemandScroll = createAction(
  '[Partner page] List drip with demand scroll',
  props<{ user_id: string; user: any; data?: any; search?: boolean; onlyMe?: boolean }>()
);
export const ListDripWithDemandScrollSuccess = createAction(
  '[Partner page] List drip with demand scroll success',
  props<{ data: any; curPage?: any; search?: any }>()
);
export const ListDripWithDemandScrollError = createAction(
  '[Partner page] List drip with demand scroll error',
  props<{ data: any }>()
);
export const ListDripWithDemandScrollMongo = createAction(
  '[Partner page] List drip with demand scroll mongo',
  props<{ user_id: string; user: any; curPage: any; contactDrips: string[] }>()
);
export const ListDripWithDemandScrollMongoSuccess = createAction(
  '[Partner page] List drip with demand scroll mongo success',
  props<{ data: any; curPage?: any }>()
);
export const ListDripWithDemandScrollMongoError = createAction(
  '[Partner page] List drip with demand scroll mongo error',
  props<{ data: any }>()
);
export const ResetDemandScrollDataMongo = createAction('[Drip] reset demand scroll');
export const ResetDemandScrollData = createAction('[Drip] reset demand scroll');
export const DripsOnlyMe = createAction('[Drip Campaign] Drips Only Me', props<{ onlyMe: boolean }>());
export const JloSearchSuccess = createAction('[Drip Campaign] jlo Search sharemodal ', props<{ data: any }>());

export const DripClear = createAction('[Drip Campaign] Clear Drip Listt');
