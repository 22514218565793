import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { DripState } from 'src/app/modules/drip-campaign/store/state';
import { Store, select } from '@ngrx/store';
import { ListDripWithDemandScroll, ResetDemandScrollData } from 'src/app/modules/drip-campaign/store/action';
import { Observable, Subject } from 'rxjs';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { selectDripWithDemand } from 'src/app/modules/drip-campaign/store/selector';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DripCampaignService } from 'src/app/modules/drip-campaign/services/drip-campaign.service';

@Component({
  selector: 'dailyai-drip-list',
  templateUrl: './drip-list.component.html',
  styleUrls: ['./drip-list.component.scss'],
})
export class DripListComponent implements OnInit, OnDestroy {
  @Input() user: any;

  @Input() drip_passed: any;

  model: any = { option: 'null' };

  demandSearchText = '';

  demandCurPage = 1;

  demandSearch$ = new Subject<any>();

  DripList$: Observable<any>;

  ngUnsubscribe: Subject<any> = new Subject<any>();

  DripList: any[];

  toAddtoDrip: {
    id: string;
    name: string;
    status: string;
    active_contacts_count: number;
    checked: boolean;
    added_by: string;
    added_by_name?: string;
  }[] = [];

  drip_selected: any;

  constructor(
    private store: Store<DripState>,
    private activeModel: NgbActiveModal,
    private campaignService: DripCampaignService
  ) {
    this.DripList$ = this.store.pipe(select(selectDripWithDemand));
  }

  ngOnInit() {
    this.store.dispatch(ResetDemandScrollData());
    this.store.dispatch(
      ListDripWithDemandScroll({ user_id: this.user.uid, user: this.user, onlyMe: this.user.userType !== 'MO' })
    );
    this.DripList$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((dripList) => {
      this.DripList = dripList;
      if (this.DripList.length > 0) {
        this.toAddtoDrip = this.DripList.map((drip) => {
          return {
            id: drip.drip_campaign_id,
            name: drip.campaign_name ? drip.campaign_name : null,
            status: drip.status ? drip.status : null,
            active_contacts_count: drip.active_contacts_count ?? 0,
            checked: false,
            added_by: drip.added_by,
            added_by_name: `${this.user.firstName  }${  this.user.lastName}`,
          };
        });

        if (this.drip_passed) {
          this.model = { option: this.drip_passed.id };
          this.drip_selected = this.drip_passed;
        }

      } else {
        this.toAddtoDrip = [];
      }
    });
    this.demandSearch$
      .pipe(takeUntil(this.ngUnsubscribe), debounceTime(400), distinctUntilChanged())
      .subscribe((val) => {
        this.demandCurPage = 1;
        if (val.length === 0) {
          this.store.dispatch(ResetDemandScrollData());
          this.store.dispatch(
            ListDripWithDemandScroll({ user_id: this.user.uid, user: this.user, onlyMe: this.user.userType !== 'MO' })
          );
        } else {
          this.store.dispatch(
            ListDripWithDemandScroll({
              user_id: this.user.uid,
              user: this.user,
              data: { text: val, curPage: 1 },
              search: true,
              onlyMe: true,
            })
          );
        }
      });
  }

  addCampaign() {
    this.activeModel.close(this.drip_selected);
  }

  closeTemplate() {
    this.activeModel.close();
  }

  radioClicked(drip: any) {
    this.model.option = drip.id;
    this.drip_selected = drip;
  }

  scrollDrip(event) {
    if (event === 'bottom') {
      if (this.demandSearchText && this.demandSearchText.length > 0) {
        this.demandCurPage++;
        this.store.dispatch(
          ListDripWithDemandScroll({
            user_id: this.user.uid,
            user: this.user,
            data: { text: this.demandSearchText, curPage: this.demandCurPage },
            search: true,
          })
        );
      } else {
        this.store.dispatch(
          ListDripWithDemandScroll({ user_id: this.user.uid, user: this.user, onlyMe: this.user.userType !== 'MO' })
        );
      }
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
