import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  GET_PLANS,
  GET_COUPONS,
  ADD_BRAINTREE_CARD_URL,
  GET_BRAINTREE_CLIENT_TOKEN_URL,
  GET_BRAINTREE_CUSTOMER_URL,
  CHANGE_BRAINTREE_DEFAULT_CARD_URL,
  DELETE_BRAINTREE_CARD_URL,
  CREATE_BRAINTREE_CUSTOMER_URL,
  PAYNOW_ON_FAIL_BRAINTREE_ENTERPRISE_URL
} from '../shared/config';
import { AuthService } from './auth.service';
import { Plans, Coupons } from '../models/choose-plan.model';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  constructor(private db: AngularFirestore, private http: HttpClient, private auth: AuthService) {}

  async findCustomer(uid: string) {
    let customer = {};
    const customer_query = await this.db.collection<any>('payments').ref.where('uid', '==', uid).get();
    customer_query.forEach((doc) => {
      customer = doc.data();
    });

    return customer;
  }

  getPlans() {
    return this.http
      .get<{ error: boolean; plans: Plans[] }>(GET_PLANS)
      .toPromise()
      .then((response) => response.plans.sort((a, b) => a.min - b.min));
  }

  getCoupons() {
    return this.http
      .get<{ error: boolean; coupons: Coupons[] }>(GET_COUPONS)
      .toPromise()
      .then((response) => response.coupons.sort((a, b) => a.percent_off - b.percent_off));
  }

  async createBraintreeCustomer(user: any, paymentMethodNonce: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.auth.getUserToken()}`,
        'Access-Control-Allow-Origin': 'http://localhost:4200/',
      }),
    };
    if (!user.isEnterprise) {
      const error = user.userType === 'SLO' && user.isSmallEnterprise ? new Error('Not Implemented') : new Error('Not Implemented');
      throw error;
    } else {
      const payload = {
        uid: user.uid,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        paymentMethodNonce,
        userType: user.userType
      }
      return this.http.post(CREATE_BRAINTREE_CUSTOMER_URL, payload, httpOptions).toPromise();
    }
  }

  getBraintreeClientToken() {
    return this.http.post(GET_BRAINTREE_CLIENT_TOKEN_URL, {}).toPromise();
  }

  getBraintreeCustomer(customerId: string) {
    return this.http.post(GET_BRAINTREE_CUSTOMER_URL, { customerId }).toPromise();
  }

  changeBraintreeDefaultCard(cardToken: string) {
    return this.http.post(CHANGE_BRAINTREE_DEFAULT_CARD_URL, { cardToken }).toPromise();
  }

  addBraintreeCard(customerId: string, paymentMethodNonce: any, page: string) {
    if (page === 'unlimited_call_button') {
      throw new Error('Not Implemented');
    } else {
      return this.http.post(ADD_BRAINTREE_CARD_URL, { customerId, paymentMethodNonce }).toPromise();
    }
  }

  deleteBraintreeCard(cardToken: string) {
    return this.http.post(DELETE_BRAINTREE_CARD_URL, { cardToken }).toPromise();
  }

  async payNowOnFailBraintree(cardToken: string) {
    const user = await this.auth.getDB();
    if (!user.isEnterprise) {
      if (user.userType === 'SLO') {
        throw new Error('Not Implemented')
      }
    } else if (user.userType === 'Super Admin') {
        return this.http.post(PAYNOW_ON_FAIL_BRAINTREE_ENTERPRISE_URL, { uid: user.uid, cardToken }).toPromise();
      }
  }
}
