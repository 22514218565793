// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// mongoUrl: 'https://testapi.dailycrm.ai/api',
// mongoUrl: 'mongodb://localhost/mortgage:3000',

export const environment = {
  production: false,
  emulator: false,
  custom_network: true,
  // mongoUrl: 'http://localhost:8080/api',
  mongoUrl: 'https://devapi.dailyai.io/api',
  countryCode: '+1',
  cloud_base_url: 'https://us-central1-dailycrm-c9949.cloudfunctions.net',
  // cloud_base_url: 'http://localhost:5001/dailycrm-c9949/us-central1',
  player_url: 'https://test-player.dailycrm.ai',
  app_url: 'https://testapp.dailycrm.ai',
  outlook_client_id: 'eadfce52-cbf7-461e-bf42-e4c196b9c0a1',
  aoh_lendingapp: 'https://aoh-dev.mylendingapp.com',
  gapi_client_id: '636198002120-kt8sb482fhr4qvlli8tlebumpdovekj1.apps.googleusercontent.com',
  domain_masking_url: 'https://testapi.mylendingapp.com',
  partnerBaseURL: 'https://test-partner-upload.web.app/',
  customWebsiteEnterprises: new Set([
    'LYODSGA2Q64mMZ3kbCLg',
    'kSrcOUVBrtRF0zr8QzGM', // NEO Home Loans
    'OiTzoIDM8Xktjd8q2cAA',
    'O8VCiKUtPOv8AC8cO0LG', // Art of Home Ownership
    'abjGu6NLulGGmO3jPVgM',
    'nti3LsHguj5Y8fbSQdn0', // Sosi Avila Enterprise
    '2zrHi0IeFirI55eWuOBc', // Churchill Mortgage Corporation
    'UVqfRuZUDZvYjjCtuKeT', // Barrett Financial Group
    'ZMS6NwZwRJim6yBBlPlp', // Sosi New Enterprise (04/01/2022)
    'JEmM685HosZTBcZlwUvO', // Mason Buckles (04/04/2022)
    'MnVfhKaVMMFtswu5yizx', // The Family Mortgage Team (04/20/2022)
  ]),
};

export const firebase = {
  apiKey: 'AIzaSyDYJz7M69u1Q_AG8hXpjdf9WchLp5Vm-S4',
  authDomain: 'dailycrm-c9949.firebaseapp.com',
  databaseURL: 'https://dailycrm-c9949.firebaseio.com',
  projectId: 'dailycrm-c9949',
  storageBucket: 'dailycrm-c9949.appspot.com',
  messagingSenderId: '726161968570',
  appId: '1:726161968570:web:aaca3bf110de282c',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
