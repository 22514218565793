<div class="container-fluid dashboard {{ activeTab }}">
  <div class="row">
    <div
      class="d-block d-sm-none position-absolute side-nav-mobile"
      [ngStyle]="{ width: isNavOpen ? '250px' : '0' }"
      [ngClass]="{ 'border-right shadow': isNavOpen }"
    >
      <dailyai-side-menu-bar [role]="userType"></dailyai-side-menu-bar>
    </div>
    <div class="col-sm-1 sidenavbar px-0 d-none d-sm-block">
      <dailyai-side-menu-bar [role]="userType"></dailyai-side-menu-bar>
    </div>
    <div class="col-xs-12 col-sm-11 px-0 bg-color dashboard-layout">
      <div
        class="position-absolute call-popup-drag v2-ui-integretion"
        *ngIf="hide"
        cdkDragBoundary=".dashboard"
        cdkDrag
      >
        <div class="call-popup-body d-flex flex-row justify-content-between align-items-center">
          <div class="d-flex flex-column justify-content-start w-50">
            <span class="font-14 text-capitalize color-text-black">{{
              contactDetails?.firstName + ' ' + contactDetails?.lastName
            }}</span>
            <span class="font-22 color-text-black" *ngIf="callModalStatus !== 'connected'">{{ callModalStatus }}</span>
            <div class="font-22 color-text-black" *ngIf="callModalStatus == 'connected'">
              <span>{{ minutesLabel }}</span
              ><span class="px-1">:</span> <span>{{ secondsLabel }}</span>
            </div>
          </div>
          <div class="d-flex flex-row justify-content-between btns-area w-50">
            <div class="d-flex flex-column justify-content-center align-items-center">
              <div class="btn-call-area" (click)="minimizeCallBox(callModal)">
                <img src="../../../../assets/icons/icon_3.svg" alt="" />
              </div>
              <span class="color-text-black font-10 pt-1">Maximize</span>
            </div>
            <div class="d-flex flex-column justify-content-center align-items-center">
              <div
                class="btn-call-area"
                [ngStyle]="{ background: callMuted ? 'black' : 'white' }"
                (click)="toggleMuteCall()"
              >
                <img src="../../../../assets/icons/icon_2.svg" alt="" />
              </div>
              <span class="color-text-black font-10 pt-1">{{ callMuted ? 'Unmute' : 'Mute' }}</span>
            </div>
            <div class="d-flex flex-column justify-content-center align-items-center">
              <div class="btn-call-area btn-call-drop" (click)="!disconnecting && disconnectCall()">
                <img src="../../../../assets/icons/call_white.svg" alt="" />
              </div>
              <span class="color-text-black font-10 pt-1">Decline</span>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column h-100">
        <div
          *ngIf="paymentNow && (currentUser.userType === 'SLO' || currentUser.userType === 'Super Admin')"
          class="d-flex rounded expiry-layout py-3 px-4 mb-3 v2-ui-integretion"
          [class.if-payment-top]="paymentNow"
        >
          <div><i class="material-icons-round"> warning </i></div>
          <div class="d-flex flex-column ml-3 mr-auto">
            <p *ngIf="!currentUser.isEnterprise" class="mb-0 font-16">Your payment was not successfully executed</p>
            <p *ngIf="currentUser.isEnterprise" class="mb-0 font-16">
              Payment for super admin {{ currentUser.superAdminName }} was not successfully executed
            </p>
            <p class="mb-0 font-14">
              <span
                >Please make the payment or reach out to our customer support team or your account could be suspended.
              </span>
            </p>
          </div>
          <button
            *ngIf="
              (currentUser?.userType === 'SLO' && !currentUser?.isEnterprise) ||
              (currentUser?.userType === 'Super Admin' && currentUser?.isEnterprise)
            "
            class="mt-1 align-items-center justify-content-center btn bg-white shadow paybtn font-12 px-3"
            (click)="openPayment()"
          >
            PAY NOW
          </button>
        </div>
        <div class="wrapper h-100" [class.if-payment-wraper]="paymentNow || mandatoryPay">
          <router-outlet> </router-outlet>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="showTabletInfo" class="mobile-warning color-black-grey-text">
  <div class="info-icon">
    <i class="icon-exclamationcircle"></i>
  </div>
  <div>
    <span class="font-16">Seems like you're on a phone or tablet.</span>
    <div class="font-14">Daily AI CRM provides more functionality and a better experience when used on a desktop.</div>
  </div>
  <button (click)="handleCloseNotification()" class="close-btn">
    <i class="icon-close font-14"></i>
  </button>
</div>
<ng-template #callModal let-modal class="modal call-modal">
  <div class="d-flex flex-column position-relative call-popup v2-ui-integretion">
    <dailyai-hidden-input></dailyai-hidden-input>
    <div class="call-popup-header">
      <div class="call-animation d-flex justify-content-center align-items-center">
        <img class="img-circle" src="../../../../assets/icons/icon_1.svg" alt="" />
      </div>
    </div>
    <div class="call-popup-body d-flex flex-column justify-content-center align-items-center">
      <span class="font-18 text-capitalize pt-2">{{ contactDetails?.firstName + ' ' + contactDetails?.lastName }}</span>
      <span class="font-32 pt-2" *ngIf="callModalStatus !== 'connected'">{{ callModalStatus }}</span>
      <div
        class="d-flex flex-row justify-content-center font-32 pt-2 color-text-black"
        *ngIf="callModalStatus == 'connected'"
      >
        <span>{{ minutesLabel }}</span
        ><span class="px-1">:</span> <span>{{ secondsLabel }}</span>
      </div>
      <div class="d-flex justify-content-between w-100 px-5 btns-area">
        <div class="d-flex flex-column justify-content-center align-items-center">
          <div class="btn-call-area" (click)="minimizeCallBox()">
            <img src="../../../../assets/icons/icon_3.svg" alt="" />
          </div>
          <span class="color-text-black font-14 pt-1">Minimize</span>
        </div>
        <div class="d-flex flex-column justify-content-center align-items-center">
          <div
            class="btn-call-area"
            [ngStyle]="{ background: callMuted ? 'black' : 'white' }"
            (click)="toggleMuteCall()"
          >
            <img src="../../../../assets/icons/icon_2.svg" alt="" />
          </div>
          <span class="color-text-black font-14 pt-1">Mute</span>
        </div>
        <div class="d-flex flex-column justify-content-center align-items-center">
          <div class="btn-call-area btn-call-drop" (click)="!disconnecting && disconnectCall()">
            <img src="../../../../assets/icons/call_white.svg" alt="" />
          </div>
          <span class="color-text-black font-14 pt-1">Decline</span>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #callStatusModal let-modal class="modal call-modal">
  <div class="modal-body v2-ui-integretion" [ngStyle]="{ width: 'call-popup' ? '100%' : '100%' }">
    <dailyai-hidden-input></dailyai-hidden-input>
    <p class="heading font-16">CALL STATUS</p>
    <form [formGroup]="callStatus">
      <div id="radioGroup" class="d-flex flex-column">
        <div class="d-flex align-items-center mt-2">
          <input type="radio" value="Contacted" formControlName="status" /> <span class="font-14 ml-2">Contacted</span>
        </div>
        <div class="d-flex align-items-center mt-2">
          <input type="radio" value="Voicemail" formControlName="status" /> <span class="font-14 ml-2">Voicemail</span>
        </div>
        <div class="d-flex align-items-center mt-2">
          <input type="radio" value="No Answer" formControlName="status" /> <span class="font-14 ml-2">No Answer</span>
        </div>
        <div class="d-flex align-items-center mt-2">
          <input type="radio" value="Bad Number" formControlName="status" />
          <span class="font-14 ml-2">Bad Number</span>
        </div>
        <div class="d-flex align-items-center mt-2">
          <input type="radio" value="completed" formControlName="status" /> <span class="font-14 ml-2">Completed</span>
        </div>
        <input
          type="text"
          *ngIf="showSubject"
          formControlName="subject"
          name="subject"
          class="textNote mt-3 px-3 py-1"
          placeholder="Subject"
        />
        <div *ngIf="expandPostpone">
          <div class="d-flex flex-column">
            <label class="font-14 mt-3">Due Date</label>
            <input
              class="form-control w-100 mt-1"
              type="text"
              ngbDatepicker
              #d="ngbDatepicker"
              placeholder="dd/mm/yy"
              (click)="d.toggle()"
              formControlName="dueDate"
              [minDate]="{ year: 1900, month: 1, day: 1 }"
              [maxDate]="{ year: 2048, month: 12, day: 31 }"
              [appNoKeypress]="true"
            />
          </div>
          <div class="d-flex flex-column mt-2">
            <label class="font-14">Due Time</label>
            <input
              class="form-control w-100 mt-1"
              placeholder="00:00"
              (click)="openTimePicker($event)"
              [value]="time"
            />
          </div>
        </div>
        <textarea formControlName="notes" rows="4" placeholder="Notes" class="textNote mt-3 px-3 py-1"></textarea>
        <button (click)="postponeOpen(postPone)" *ngIf="contactDetails.task_id" class="btn btn-normal mt-4">
          <span>Postpone</span>
        </button>
        <button class="btn btn-primary mt-4 w-100" *ngIf="!loading" (click)="updateCallStatus()">Done</button>
        <button [disabled]="loading" *ngIf="loading" class="btn btn-primary mt-4 w-100">
          Done<span
            [ngClass]="{
              'spinner-border spinner-border-sm': loading,
              nil: !loading
            }"
            role="status"
            aria-hidden="true"
          ></span>
        </button>
      </div>
    </form>
  </div>
</ng-template>
<ng-template #postPone let-modal class="modal call-modal">
  <div class="modal-header pb-0">
    <dailyai-hidden-input></dailyai-hidden-input>
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <i class="material-icons icon-color"> close </i>
    </button>
  </div>
  <div class="modal-body py-0 v2-ui-integretion">
    <p class="heading font-18 text-uppercase text-left">Change Due Date</p>
    <form [formGroup]="dueDateForm">
      <div class="pl-0 d-flex flex-column">
        <label class="font-14 mt-2">Due Date</label>
        <input
          class="form-control"
          type="text"
          ngbDatepicker
          #d="ngbDatepicker"
          placeholder="dd/mm/yy"
          (click)="d.toggle(); showValidator()"
          formControlName="dueDate"
          [appNoKeypress]="true"
          autoClose="inside"
          [minDate]="{ year: today.getFullYear(), month: today.getMonth() + 1, day: today.getDate() }"
          [maxDate]="{ year: 2048, month: 12, day: 31 }"
        />
        <div class="error-box">
          <label class="error" *ngIf="dueDateForm.get('dueDate').invalid && dueDateForm.get('dueDate').touched && show">
            *Choose Date
          </label>
        </div>
      </div>
      <br />
      <textarea formControlName="notes" rows="4" placeholder="Notes" class="textNote w-100 px-3 py-2"></textarea>
      <div class="d-flex flex-row-reverse w-100 py-2"></div>
      <div class="d-flex my-4 w-100 flex-row-reverse">
        <button class="btn btn-primary w-100" (click)="updatePostpone()">Done</button>
      </div>
    </form>
  </div>
</ng-template>
<ng-template #callerIdSelection let-modal class="modal call-modal">
  <div class="modal-body p-0 text-center v2-ui-integretion">
    <dailyai-hidden-input></dailyai-hidden-input>
    <p class="font-16 py-4">Select Number to Call From</p>
    <div class="d-flex align-items center">
      <div
        class="d-flex w-50 align-items-center py-2 px-3 cursor-pointer"
        [ngClass]="{ 'btn-info': !useCallerId, 'btn-default': useCallerId }"
        (click)="useCallerId = false"
        id="crm"
      >
        <div class="d-flex align-content-center justify-content-center pr-2">
          <span class="material-icons color-text-black"> call </span>
        </div>
        <div class="d-flex flex-column" id="CRM">
          <span class="font-16">CRM Number</span>
          <span class="font-12 color-text-gray">{{ currentUser?.twilioNumber?.phoneNumber | phoneNumberFormat }}</span>
        </div>
      </div>
      <div
        class="d-flex w-50 align-items-center py-3 px-3 cursor-pointer"
        [ngClass]="{ 'btn-info': useCallerId, 'btn-default': !useCallerId }"
        (click)="useCallerId = true"
        id="verified"
      >
        <div class="d-flex align-content-center justify-content-center pr-2">
          <span class="material-icons color-text-black"> call </span>
        </div>
        <div class="d-flex flex-column">
          <span class="font-16">Verified Number</span>
          <span class="font-12 color-text-gray"> {{ currentUser?.phoneNo | phoneNumberFormat }}</span>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center my-4">
      <button class="btn btn-normal w-25 mr-2" (click)="submitCallerId(false)">Cancel</button>
      <button class="btn btn-primary shadow w-25" id="submitCallerId" (click)="submitCallerId(true)">Call</button>
    </div>
  </div>
</ng-template>
<ng-template #logoutUser let-modal>
  <div class="px-2 pb-3 v2-ui-integretion">
    <div class="modal-header pb-0 justify-content-center">
      <h4 class="color-grey-text text-uppercase">Email Updated</h4>
    </div>
    <div class="modal-body">
      <div class="d-flex flex-column justify-content-center">
        <div class="col-12 px-0 d-flex flex-column justify-content-center">
          <h5 class="color-black-grey-text text-center">
            You will be logged out in:<span class="px-2" style="color: red">{{ timeLeft }} Seconds</span>
          </h5>
        </div>
      </div>
    </div>
  </div>
</ng-template>
