import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, from, merge } from 'rxjs';
import { map, mergeMap, catchError, switchMap, withLatestFrom, delay, takeUntil } from 'rxjs/operators';
import { AppService } from 'src/app/shared/app.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngrx/store';
import { State } from 'src/app/reducers';
import { SubscriptionService } from 'src/app/services/subscriptions.service';
import { Router } from '@angular/router';
import { TagsService } from '../services/tags.service';
import { GetTaskListService } from '../services/get-all-tasks.service';
import { NotesService } from '../services/notes.service';
import { ContactService } from '../services/contact.service';
import * as ContactStateActions from './action';
import { ContactDetail } from './models';
import { SettingsService } from '../../settings/services';
import { phoneValueFormatter } from '../../../shared/grid-renderers/valueFormatters';
import { LINKS } from '../../../shared/config';

@Injectable()
export class ContactEffects {
  massEmailMessage = {
    error: `
      <div>An error occurred and the email failed to send.</div><br />
      <div>To fix this issue, go to Settings > Integrations. Click the "Remove Email Integration"
      button. Once removed, reintegrate your email.</div><br />
      <div>If the problem persists, contact technical support: support@dailyai.io</div>
    `,
  };

  constructor(
    private actions$: Actions,
    private conservice: ContactService,
    private noteService: NotesService,
    private appService: AppService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private store$: Store<State>,
    private tagsService: TagsService,
    private getTaskService: GetTaskListService,
    private subService: SubscriptionService,
    private router: Router,
    private settingsService: SettingsService
  ) { }

  AddContact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactStateActions.AddContact),
      withLatestFrom(this.store$, (action, state) => ({ state, action })),
      switchMap((payload) => {
        this.toastr.info('Saving contact; the user gets  directed to the contact details page', '', {
          timeOut: 50000,
          progressAnimation: 'increasing',
          progressBar: true,
        });
        return from(this.conservice.createContact(payload.action.data, payload.action.task)).pipe(
          switchMap((res) => {
            const contactid = res.doc_id ? res.doc_id : '';
            this.toastr.clear();
            this.toastr.success('Contact Added Successfully');
            if (contactid) {
              this.router.navigate(['/contacts/', contactid, 'timeline']);
            }
            return merge(of(ContactStateActions.AddContactSuccess()));
          }),
          catchError((error) => {
            this.toastr.error('Error Adding Contact');
            return of(ContactStateActions.AddContactError({ msg: error.message }));
          })
        );
      })
    )
  );

  DeleteContact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactStateActions.DeleteContact),
      withLatestFrom(this.store$, (action, state) => ({ state, action })),
      switchMap((payload) =>
        from(this.conservice.deleteContact(payload.action.uId)).pipe(
          switchMap((_) => {
            return merge(
              of(
                ContactStateActions.DeleteContactSuccess(),
                ContactStateActions.FetchContactModified({
                  data: payload.state.contact.lastPayload,
                })
              )
            );
          }),
          catchError((error) => of(ContactStateActions.DeleteContactError({ msg: error.message })))
        )
      )
    )
  );

  UpdateContact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactStateActions.UpdateContact),
      withLatestFrom(this.store$, (action, state) => ({ state, action })),
      mergeMap((payload) =>
        from(this.conservice.updateContact(payload.action.data, payload.action.oldContact)).pipe(
          switchMap((_) => {
            this.toastr.clear();
            this.toastr.success('Contact Updated Successfully');
            this.modalService.dismissAll();
            return merge(
              of(
                ContactStateActions.UpdateContactSuccess(),
                ContactStateActions.FetchContactModified({
                  data: payload.state.contact.lastPayload,
                })
              )
            );
          }),
          catchError((error) => {
            this.toastr.error('Error Updating Contact');
            return of(ContactStateActions.UpdateContactError({ msg: error['message'] }));
          })
        )
      )
    )
  );

  UpdateContactStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactStateActions.UpdateContactStatus),
      mergeMap((payload) =>
        from(this.conservice.updateContactStatus(payload.data)).pipe(
          map((_) => {
            return { type: 'No Dats' };
          })
        )
      )
    )
  );

  ListContact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactStateActions.ListContact),
      switchMap((payload) => {
        return this.conservice.getContacts(payload).pipe(
          takeUntil(this.subService.unsubscribe$),
          map((contact) => {
            return ContactStateActions.ListContactSuccess({ data: contact });
          })
        );
      })
    )
  );

  GetContactWithPagination$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactStateActions.getContactWithPagination),
      withLatestFrom(this.store$, (action, state) => ({ state, action })),
      switchMap((payload) => {
        return from(this.conservice.getContactsPaginate(payload.action, payload.state.contact.last_doc)).pipe(
          switchMap((contact: any[]) => {
            return merge(
              of(ContactStateActions.contactWithPaginationSuccess({ data: contact }))
            );
          }),
          catchError((error) => of(ContactStateActions.contactWithPaginationError({ data: error.message })))
        );
      })
    )
  );

  GetContactDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactStateActions.GetContactDetail),
      switchMap((payload) =>
        from(this.conservice.getDetailOfContact(payload.id)).pipe(
          takeUntil(this.subService.unsubscribe$),
          map((detail: ContactDetail) => {
            if (detail) {
              this.appService.showLoading(false);
              return ContactStateActions.GetContactDetailSuccess({ detail });
            }
              throw { message: 'no data' };

          }),
          catchError((error) => {
            this.appService.showLoading(false);
            return of(ContactStateActions.GetContactDetailError({ msg: error.message }));
          })
        )
      )
    )
  );

  GetContactTasks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactStateActions.GetContactDetail),
      switchMap((payload) =>
        this.getTaskService.get(payload.id).pipe(
          takeUntil(this.subService.unsubscribe$),
          map((detail) => {
            if (detail) {
              return ContactStateActions.GetContactTaskSuccess({ detail });
            }
              throw { message: 'no data' };

          })
        )
      )
    )
  );

  ContactFileAdd$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactStateActions.ContactFileAdd),
      switchMap((payload) =>
        from(
          this.conservice.contactFileAdd(
            payload.contactId,
            payload.title,
            payload.comment,
            payload.imageUrl,
            payload.upload_by,
            payload.fileName
          )
        ).pipe(
          map((contact) => {
            this.modalService.dismissAll();
            this.appService.showLoading(false);
            return ContactStateActions.ContactFileAddSuccess();
          }),
          catchError((error) => of(ContactStateActions.ContactFileAddError({ msg: error.message })))
        )
      )
    )
  );

  GetContactFiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactStateActions.GetContactFiles),
      switchMap((payload) =>
        this.conservice.getContactFiles(payload.id).pipe(
          takeUntil(this.subService.unsubscribe$),
          map((file) => {
            this.appService.showLoading(false);
            return ContactStateActions.GetContactFilesSuccess({ data: file });
          }),
          catchError((error) => {
            return of(ContactStateActions.GetContactFilesError({ msg: error.message }));
          })
        )
      )
    )
  );

  UploadCsv$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactStateActions.UploadCsv),
      switchMap((payload) =>
        from(
          this.conservice.uploadCsv(
            payload.id,
            payload.file,
            payload.assigned_to,
            payload.parentId,
            payload.optout,
            payload.groupId,
            payload.selectedDripIds
          )
        )
      ),
      map((_) => {
        this.toastr.success('CSV Uploading in progress', 'File Upload', { positionClass: 'toast-top-right' });
        this.modalService.dismissAll();
        return ContactStateActions.UploadCsvSuccess();
      }),
      catchError((err) => {
        this.toastr.error('CSV Upload Failed', 'File Upload', { positionClass: 'toast-top-right' });
        return of(ContactStateActions.UpdateContactError({ msg: err.message }));
      })
    )
  );

  DeleteContactFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactStateActions.DeleteContactFile),
      switchMap((payload) => from(this.conservice.deleteContactFile(payload.doc_id, payload.image))),
      map((_) => {
        this.toastr.success('File Deleted Successfully', '', { positionClass: 'toast-top-right' });
        this.modalService.dismissAll();
        return ContactStateActions.DeleteContactFileSuccess();
      }),
      catchError((err) => of(ContactStateActions.DeleteContactFileError({ msg: err.message })))
    )
  );

  UpdateContactFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactStateActions.UpdateContactFile),
      switchMap((payload) => from(this.conservice.updateContactFile(payload.id, payload.file))),
      map((_) => ContactStateActions.UpdateContactFileSuccess()),
      catchError((err) => of(ContactStateActions.UpdateContactFileError({ msg: err.message })))
    )
  );

  DownloadFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactStateActions.DownloadFile),
      switchMap((payload) => from(this.conservice.downloadFile(payload.id, payload.url, payload.fileName))),

      map((_) => ContactStateActions.DownloadFileSuccess()),
      catchError((err) => of(ContactStateActions.DownloadFileError({ msg: err.message })))
    )
  );

  AddNote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactStateActions.AddNote),
      switchMap((payload) => from(this.noteService.addNote(payload.data))),
      map((_) => {
        this.toastr.success('Note Added');
        return ContactStateActions.AddNoteSuccess();
      }),
      catchError((err) => of(ContactStateActions.AddNoteError({ msg: err.message })))
    )
  );

  GetNotes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactStateActions.GetNotes),
      withLatestFrom(this.store$, (action, state) => ({ state, action })),
      switchMap((payload) =>
      this.noteService.getNote(payload.action.id, payload.state.login.currentUser.uid).pipe(
          takeUntil(this.subService.unsubscribe$),
          map((response) => {
            return ContactStateActions.GetNotesSuccess({ data: response })
          })
        )
      )
    )
  );

  UpdateNotes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactStateActions.UpdateNotes),
      switchMap((payload) => from(this.conservice.updateContactFile1(payload.id, payload.newNote, payload.title))),
      map((_) => ContactStateActions.UpdateNotesSuccess()),
      catchError((err) => of(ContactStateActions.UpdateNotesError({ msg: err.message })))
    )
  );

  GetMyUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactStateActions.GetMyUsers),
      switchMap((payload) =>
        this.conservice.getMyUser(payload.id).pipe(
          takeUntil(this.subService.unsubscribe$),
          map((res) => {
            return ContactStateActions.GetMyUsersSuccess({ data: res });
          })
        )
      )
    )
  );

  GetMySLO$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactStateActions.GetMySLO),
      switchMap((payload) =>
        this.conservice.getMySLO(payload.id).pipe(
          takeUntil(this.subService.unsubscribe$),
          map((res) => {
            return ContactStateActions.GetMySLOSuccess({ data: res });
          })
        )
      )
    )
  );

  AddContactToDrips$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactStateActions.AddContactToDrips),
      switchMap((payload) =>
        from(this.conservice.addContactToDrips(payload.contact_id, payload.drip_campaigns)).pipe(
          switchMap((res) => {
            this.toastr.success('Added to drip successfully');
            this.modalService.dismissAll();
            return merge(
              of(ContactStateActions.AddContactToDripsSuccess()),
              of(ContactStateActions.CloseAssignDripModal())
            );
          }),
          catchError((err) => {
            this.toastr.error('Unable to Add Contact to drip');
            return of(ContactStateActions.AddContactToDripsError({ msg: err }));
          })
        )
      )
    )
  );

  BatchAddContactToDrips$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactStateActions.BatchAddContactToDrips),
      withLatestFrom(this.store$, (action, state) => ({ state, action })),
      switchMap((payload) => {
        this.modalService.dismissAll();
        this.toastr.info('Adding to drip...', '', {
          timeOut: 50000,
          progressAnimation: 'increasing',
          progressBar: true,
        });
        return from(
          this.conservice.batchAddContactToDrips(
            payload.action.contact_Ids,
            payload.action.drip_campaigns,
            payload.state.contact.areAllSelected,
            payload.state.contact.mongoFetchParams,
            payload.state.contact.onlyMe,
            payload.state.login.currentUser.uid
          )
        ).pipe(
          switchMap((res) => {
            this.toastr.clear();
            this.toastr.success('Added to drip successfully');
            return merge(
              of(ContactStateActions.BatchAddContactToDripsSuccess())
            );
          }),
          catchError((err) => {
            this.toastr.clear();
            this.toastr.error('Unable to Add Contact to drip');
            return of(ContactStateActions.BatchAddContactToDripsError({ msg: err }));
          })
        );
      })
    )
  );

  RemoveContactFromDrips$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactStateActions.RemoveContactFromDrips),
      switchMap((payload) =>
        from(this.conservice.removeContactFromDrips(payload.contact_id, payload.drip_campaigns)).pipe(
          map((res) => {
            this.toastr.success('Removed from drip successfully');
            this.modalService.dismissAll();
            return ContactStateActions.RemoveContactFromDripsSuccess();
          })
        )
      )
    )
  );

  FetchContactCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactStateActions.FetchContactCount),
      withLatestFrom(this.store$, (action, state) => ({ state, action })),
      switchMap((payload) =>
        from(this.conservice.fetchCount(payload.state.login.currentUser.uid, payload.state.contact.onlyMe)).pipe(
          map((data) => {
            return ContactStateActions.SaveContactCount({ count: data['count'] });
          })
        )
      )
    )
  );

  FetchContact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactStateActions.FetchContact),
      withLatestFrom(this.store$, (action, state) => ({ state, action })),
      switchMap((payload) => {
        this.appService.showLoading(true);
        return from(this.conservice.fetchContactFromMongo(payload.action.data, payload.state.contact.onlyMe)).pipe(
          map((data) => {
            this.appService.showLoading(false);
            return ContactStateActions.ListContactSuccess({
              data: data['data'],
              count: data['count'],
              contact_ids: data['contactIds'],
            });
          })
        );
      })
    )
  );

  FetchContactModified$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactStateActions.FetchContactModified),
      withLatestFrom(this.store$, (action, state) => ({ state, action })),
      switchMap((payload) => {
        this.appService.showLoading(true);
        return from(
          this.conservice.fetchContactFromMongoModified(
            payload.action.data,
            payload.state.login.currentUser.uid,
            payload.state.contact.onlyMe,
            false,
            payload.action.data?.limit || 50
          )
        ).pipe(
          map((data) => {
            const newData = data['data'].map((oneData) => {
              return {
                ...oneData,
              };
            });
            this.appService.showLoading(false);
            return ContactStateActions.ListContactSuccess({
              data: newData,
              count: data['count'],
              contact_ids: data['contactIds'],
            });
          })
        );
      })
    )
  );

  FetchContactIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactStateActions.FetchContactModified),
      withLatestFrom(this.store$, (action, state) => ({ state, action })),
      delay(5000),
      switchMap((payload) => {
        this.appService.showLoading(true);
        return from(
          this.conservice.fetchContactFromMongoModified(
            payload.action.data,
            payload.state.login.currentUser.uid,
            payload.state.contact.onlyMe,
            true,
            payload.action.data?.limit || 50
          )
        ).pipe(
          map((data) => {
            this.appService.showLoading(false);
            return ContactStateActions.getExtraContactIdsSuccess({
              data: data['data'],
            });
          })
        );
      })
    )
  );

  SearchContact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactStateActions.SearchContact),
      withLatestFrom(this.store$, (action, state) => ({ state, action })),
      switchMap((payload) => {
        return from(
          this.conservice.searchContact(
            payload.action.data,
            payload.state.login.currentUser.uid,
            payload.state.contact.onlyMe
          )
        ).pipe(
          map((data) => {
            if (payload.action.data.ngb) {
              return ContactStateActions.ListSearchSuccess({
                data: data['data'],
                count: data['count'],
                search: true,
              });
            }
              return ContactStateActions.ListContactSuccess({
                data: data['data'],
                count: data['count'],
                contact_ids: data['contactIds'],
                search: true,
              });

          })
        );
      })
    )
  );

  SearchContactIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactStateActions.SearchContact),
      withLatestFrom(this.store$, (action, state) => ({ state, action })),
      switchMap((payload) => {
        return from(
          this.conservice.searchContact(
            payload.action.data,
            payload.state.login.currentUser.uid,
            payload.state.contact.onlyMe,
            true
          )
        ).pipe(
          map((data) => {
            return ContactStateActions.getExtraContactIdsSuccess({
              data: data['data'],
            });
          })
        );
      })
    )
  );

  GetMyDripData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactStateActions.GetMyDripData),
      withLatestFrom(this.store$, (action, state) => ({ state, action })),
      mergeMap((payload) => {
        const obs = this.conservice.getMyDripData(
          payload.state.login.currentUser.parentId
            ? payload.state.login.currentUser.parentId
            : payload.state.login.currentUser.uid
        );
        return from(obs).pipe(
          map((data) => {
            return ContactStateActions.GetMyDripDataSuccess({ data });
          })
        );
      })
    )
  );

  FilterContact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactStateActions.FilterContact),
      withLatestFrom(this.store$, (action, state) => ({ state, action })),
      mergeMap((payload) => {
        this.appService.showLoading(true);
        return from(
          this.conservice.filterContact(
            payload.action.data,
            payload.state.login.currentUser.uid,
            payload.state.contact.onlyMe
          )
        ).pipe(
          map((data) => {
            this.appService.showLoading(false);
            return ContactStateActions.ListContactSuccess({
              data: data['data'],
              count: data['count'],
              contact_ids: data['contactIds'],
            });
          })
        );
      })
    )
  );

  FilterContactIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactStateActions.FilterContact),
      withLatestFrom(this.store$, (action, state) => ({ state, action })),
      mergeMap((payload) => {
        this.appService.showLoading(true);
        return from(
          this.conservice.filterContact(
            payload.action.data,
            payload.state.login.currentUser.uid,
            payload.state.contact.onlyMe,
            true
          )
        ).pipe(
          map((data) => {
            this.appService.showLoading(false);
            return ContactStateActions.getExtraContactIdsSuccess({
              data: data['data'],
            });
          })
        );
      })
    )
  );

  GetTagsList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactStateActions.GetTagsList),
      switchMap((payload) =>
        from(this.settingsService.getTags(payload.user, true)).pipe(
          map((response) => {
            return ContactStateActions.GetTagsListSuccess({ data: response });
          }),
          catchError((err) => {
            return of(ContactStateActions.GetTagsListError({ msg: err.message }));
          })
        )
      )
    )
  );

  AssignTags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactStateActions.AssignTags),
      withLatestFrom(this.store$, (action, state) => ({ state, action })),
      switchMap((payload) =>
        from(this.tagsService.addTagsToContact(payload.action.data)).pipe(
          switchMap((result) => {
            this.toastr.success('Tag Added Successfully');
            return of(ContactStateActions.AssignTagsSuccess({ data: result }));
          })
        )
      )
    )
  );

  DeleteAssignedTags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactStateActions.DeleteAssignedTags),
      switchMap((payload) =>
        from(this.tagsService.deleteTagsToContact(payload)).pipe(
          map((result) => {
            this.toastr.success('Tag Removed Successfully');
            return ContactStateActions.DeleteAssignedTagsSuccess();
          }),
          catchError((error) => {
            return of(ContactStateActions.DeleteAssignedTagsError({ msg: error.message }));
          })
        )
      )
    )
  );

  SortContact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactStateActions.SortContact),
      withLatestFrom(this.store$, (action, state) => ({ state, action })),
      mergeMap((payload) => {
        this.appService.showLoading(true);
        return from(
          this.conservice.sortContact(
            payload.action.data,
            payload.state.login.currentUser.uid,
            payload.state.contact.onlyMe
          )
        ).pipe(
          map((data) => {
            this.appService.showLoading(false);
            return ContactStateActions.ListContactSuccess({
              data: data['data'],
              count: data['count'],
              contact_ids: data['contactIds'],
            });
          })
        );
      })
    )
  );

  SortContactIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactStateActions.SortContact),
      withLatestFrom(this.store$, (action, state) => ({ state, action })),
      mergeMap((payload) => {
        this.appService.showLoading(true);
        return from(
          this.conservice.sortContact(
            payload.action.data,
            payload.state.login.currentUser.uid,
            payload.state.contact.onlyMe,
            true
          )
        ).pipe(
          map((data) => {
            this.appService.showLoading(false);
            return ContactStateActions.getExtraContactIdsSuccess({
              data: data['data'],
            });
          })
        );
      })
    )
  );


  AssignToOfficer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactStateActions.AssignToOfficer),
      withLatestFrom(this.store$, (action, state) => ({ state, action })),
      switchMap((payload) =>
        from(
          this.conservice.assignToOfficer(
            payload.action.data.assignedTo,
            payload.action.data.selectedContacts,
            payload.state.contact.areAllSelected,
            payload.state.contact.mongoFetchParams,
            payload.state.contact.onlyMe,
            payload.state.login.currentUser.uid
          )
        ).pipe(
          delay(4000),
          switchMap((result) => {
            this.toastr.success('Contact(s) Assigned Successfully');
            return merge(
              of(ContactStateActions.FetchContactModified({ data: payload.state.contact.mongoFetchParams })),
              of(ContactStateActions.AssignToOfficerSuccess({ data: result }))
            );
          }),
          catchError((error) => {
            return of(ContactStateActions.AssignToOfficerError({ msg: error.message }));
          })
        )
      )
    )
  );

  DeleteContactBatch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactStateActions.DeleteContactBatch),
      switchMap((payload) =>
        from(
          this.conservice.deleteBatchContacts(
            payload.data.selectedContacts,
            payload.data.parentId,
            payload.single ? payload.single : null
          )
        ).pipe(
          map((result) => {
            this.toastr.clear();
            this.toastr.success(`Contact${payload.single ? '' : '(s)'} Removed Successfully`);
            this.store$.dispatch(
              ContactStateActions.FetchContactModified({ data: { ...payload.refreshData }, silent: true })
            );
            return ContactStateActions.DeleteContactBatchSuccess({ data: result });
          }),
          catchError((error) => {
            this.toastr.clear();
            this.toastr.error('Error Removing Contact(s)');
            return of(ContactStateActions.DeleteContactBatchError({ msg: error.message }));
          })
        )
      )
    )
  );

  TagsBatch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactStateActions.TagsBatch),
      withLatestFrom(this.store$, (action, state) => ({ state, action })),
      switchMap((payload) =>
        from(
          this.conservice.TagsBatch(
            payload.action.data.selectedContacts,
            payload.action.data.addedTags,
            payload.action.data.removedTags,
            payload.state.contact.areAllSelected,
            payload.state.contact.mongoFetchParams,
            payload.state.contact.onlyMe,
            payload.state.login.currentUser.uid
          )
        ).pipe(
          delay(4000),
          switchMap((result) => {
            this.toastr.clear();
            this.toastr.success('Tags are being updated in the Background', 'Mass Action');
            return merge(
              of(ContactStateActions.FetchContactModified({ data: payload.state.contact.mongoFetchParams })),
              of(ContactStateActions.TagsBatchSuccess({ data: result }))
            );
          }),
          catchError((error) => {
            this.toastr.error('Error Updating Tags');
            return of(ContactStateActions.TagsBatchError({ msg: error.message }));
          })
        )
      )
    )
  );

  MassEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactStateActions.massEmail),
      withLatestFrom(this.store$, (action, state) => ({ state, action })),
      switchMap((payload) => {
        this.modalService.dismissAll();
        this.toastr.info('Sending Normal email(s)', '', {
          timeOut: 50000,
          progressAnimation: 'increasing',
          progressBar: true,
        });
        return from(
          this.conservice.sendMassMail(
            payload.action.data,
            payload.state.contact.mongoFetchParams,
            payload.state.contact.onlyMe,
            payload.state.login.currentUser.uid
          )
        ).pipe(
          switchMap((result) => {
            this.toastr.clear();
            this.toastr.success('Mail Sent Successfully');
            return merge(of(ContactStateActions.massEmailSuccess({ data: '' })));
          }),
          catchError((error) => {
            this.toastr.clear();
            this.toastr.error(this.massEmailMessage.error,  '', {
              enableHtml: true,
              disableTimeOut: true,
              closeButton: true,
            });
            this.modalService.dismissAll();
            return of(ContactStateActions.massEmailError({ data: error }));
          })
        );
      })
    )
  );


  MassEmailCampagin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactStateActions.massEmailCampain),
      withLatestFrom(this.store$, (action, state) => ({ state, action })),
      switchMap((payload) => {
        this.modalService.dismissAll();
        this.toastr.info('Sending Campaign emails', '', {
          timeOut: 50000,
          progressAnimation: 'increasing',
          progressBar: true,
        });
        return from(
          this.conservice.sendMassMailCampain(
            payload.action.campainData,
            payload.state.contact.mongoFetchParams,
            payload.state.contact.onlyMe,
            payload.state.login.currentUser.uid
          )
        ).pipe(
          switchMap((result) => {
            this.toastr.clear();
            this.toastr.success('Mail Sent Successfully');
            return merge(of(ContactStateActions.massEmailCampainSuccess({ data: '' })));
          }),
          catchError((error) => {
            this.toastr.error('Unable To send Mail');
            this.modalService.dismissAll();
            return of(ContactStateActions.massEmailCampainError({ data: error }));
          })
        );
      })
    )
  );

  MassTestEmailCampagin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactStateActions.testEmailCampagin),
      switchMap((payload) => {
        this.toastr.info('Testing Campaign Email ...', '', {
          timeOut: 50000,
          progressAnimation: 'increasing',
          progressBar: true,
        });
        return from(this.conservice.sendTestMailCampain(payload.data)).pipe(
          map((res) => {
            this.toastr.clear();
            this.toastr.success('Mail Sent Successfully');
            return ContactStateActions.testEmailCampaginSuccess({ data: '' });
          }),
          catchError((err) => {
            this.toastr.error('Unable To send Mail');
            this.modalService.dismissAll();
            return of(ContactStateActions.testEmailCampaginError({ msg: err }));
          })
        );
      })
    )
  );

  MassTestEmailNormal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactStateActions.testEmailNormal),
      switchMap((payload) => {
        this.toastr.info('Testing Normal Email...', '', {
          timeOut: 50000,
          progressAnimation: 'increasing',
          progressBar: true,
        });
        return from(this.conservice.sendTestMailNormal(payload.data)).pipe(
          map((res) => {
            this.toastr.clear();
            this.toastr.success('Mail Sent Successfully');
            return ContactStateActions.testEmailNormalSuccess({ data: '' });
          }),
          catchError((err) => {
            this.toastr.error('Unable To send Mail');
            this.modalService.dismissAll();
            return of(ContactStateActions.testEmailNormalError({ msg: err }));
          })
        );
      })
    )
  );

  MassSms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactStateActions.massSms),
      switchMap((payload) => {
        this.modalService.dismissAll();
        this.toastr.info('Sending SMS...', '', {
          timeOut: 50000,
          progressAnimation: 'increasing',
          progressBar: true,
        });
        return from(this.conservice.sendMassSms(payload.data)).pipe(
          map((res) => {
            this.toastr.clear();
            this.toastr.success('SMS Send Successfully');
            return ContactStateActions.massSmsSuccess({ data: '' });
          }),
          catchError((err) => {
            this.toastr.error(err.error.msg.message);
            this.modalService.dismissAll();
            return of(ContactStateActions.massSmsError({ data: err }));
          })
        );
      })
    )
  );

  MassVoiceDrop$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactStateActions.massVoiceDrop),
      switchMap((payload) => {
        this.modalService.dismissAll();
        this.toastr.info('Sending Voice Drop...', '', {
          timeOut: 50000,
          progressAnimation: 'increasing',
          progressBar: true,
        });
        return from(this.conservice.sendMassVoiceDrop(payload.data)).pipe(
          map((res) => {
            this.toastr.clear();
            this.toastr.success('Voice Drop Send Successfully');
            return ContactStateActions.massVoiceDropSuccess();
          }),
          catchError((err) => {
            const errMsg = `The voice mail mass message did not send. If the problem persists contact support for help. <a [href]="mailto:${LINKS.SUPPORT_LINK}" target="_top"><u>${LINKS.SUPPORT_LINK}</u></a>`;

            if (err.status === 500) {
              this.toastr.error(errMsg, '', {
                enableHtml: true,
                closeButton: true,
                timeOut: 10_000,
              });
            } else {
              this.toastr.error(err.error.msg);
            }
            this.modalService.dismissAll();
            return of(ContactStateActions.massVoiceDropError({ data: err }));
          })
        );
      })
    )
  );

  MergeContact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactStateActions.setParentContact),
      switchMap((payload) =>
        from(this.conservice.mergeContact(payload.parentContact)).pipe(
          map((res) => {
            this.toastr.success('Merged Successfully');
            this.modalService.dismissAll();
            return ContactStateActions.setParentContactSuccess({ data: '' });
          }),
          catchError((err) => {
            console.warn(err);
            this.toastr.error(err.error.msg);
            this.modalService.dismissAll();
            return of(ContactStateActions.setParentContactError({ msg: err }));
          })
        )
      )
    )
  );

  DeleteNote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactStateActions.deleteNote),
      switchMap((payload) => from(this.noteService.deleteNote(payload.data))),
      map((_) => {
        this.toastr.success('Note Deleted');
        return ContactStateActions.deleteNoteSuccess();
      }),
      catchError((err) => of(ContactStateActions.deleteNoteError({ msg: err })))
    )
  );
}
